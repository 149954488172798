import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../../viewComponents/hooks/useUser';
import ReactMarkdown from 'react-markdown';
import cguEn from '../../../media/cgu/cguEn.md';
import cguFr from '../../../media/cgu/cguFr.md';
import './CguForm.scss';
import { translate } from '../../../infrastructure/translations/translate';
import { adapter } from '../../../infrastructure';
import { CguModal } from './CguModal';
import { AlertError } from '../../../viewComponents/Alerts';

import { ApiError } from '../../../domain/core/ApiError';

export const CguForm = (): JSX.Element => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { entity, error } = useUser();
	const lang = adapter.getLang();
	const [content, setContent] = useState<string>('');
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [status, setStatus] = useState<string | undefined>(undefined);

	useEffect(() => {
		const file = lang === 'fr' ? cguFr : cguEn;
		fetch(file).then((response) => response.text()).then((text) => setContent(text));
	}, [lang]);

	useEffect(() => {
		if (error && error instanceof ApiError) {
			// Dont display because we are already on the cgu page
			if (error.getKey() === 'api.error.login.mustAcceptCgu') return;
			setStatus(error.getKey());
		} else {
			setStatus(error);
		}
	}, [error]);

	return (
		<div className={'cgu'}>
			<ReactMarkdown>{content}</ReactMarkdown>
			<button
				aria-label={translate('cguForm.fullCgu') as string}
				className="btn btn-primary showCgu"
				onClick={() => setOpenModal(true)}
				type="button"
			>
				{translate('cguForm.fullCgu')}
				<i className="fa-regular fa-arrow-up-right-from-square"/>
			</button>
			{openModal && <CguModal setOpenModal={setOpenModal}/>}
			<div className={'actions'}>
				<button
					aria-label={translate('back') as string}
					className="btn btn-secondary"
					type="button"
					onClick={() => navigate('/login')}
				>
					{translate('back')}
				</button>
				<button
					aria-label={translate('refuse') as string}
					className="btn btn-secondary"
					type="button"
					onClick={() => {
						const { token } = state;
						if (token && token.length !== 0) {
							entity?.mutateAcceptCgu({ cguToken: token, cguAccepted: false })
								.catch(() => {
									navigate('/login');
								});
							return;
						}
					}}
				>
					{translate('refuse')}
				</button>
				<button
					aria-label={translate('accept') as string}
					className="btn btn-primary"
					type="button"
					onClick={() => {
						const { token } = state;
						if (token && token.length !== 0) {
							entity?.mutateAcceptCgu({ cguToken: token, cguAccepted: true }).catch(() => {
								navigate('/login');
							});
							return;
						}
					}}
				>
					{translate('accept')}
				</button>
			</div>

			<div className={'status'} role={'alert'} aria-atomic={!!status}>
				{status ? <AlertError status={translate(status) as string}/> : null}
			</div>
		</div>
	);
};
