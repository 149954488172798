import React, { useEffect } from 'react';
import Template from '../../../viewComponents/template/template/template';
import { SideBarContentItemList } from '../../../viewComponents/template/sidebar/sidebar';
import { translate } from '../../../infrastructure/translations/translate';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '../../../store';
import { DashboardComparisonData, DashboardComparisonMaturity } from '../../../domain/interfaces/DashboardComparisonData';
import { useIndicators } from '../../../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../../../viewComponents/hooks/useLifeCycleSteps';
import Spinner from '../../../viewComponents/utils/Spinner/Spinner';
import { DashboardSaveDataButton, DeleteDashboardButton } from '../DashboardSaveData';
import { Alert } from '../../../viewComponents/Alerts';
import DashboardGlobalSimulation from './Graphs/DashboardGlobalSimulation';
import DashboardDomainSimulation from './Graphs/DashboardDomainSimulation';
import DashboardIndicatorsSimulation from './Graphs/DashboardIndicatorsSimulation';
import DashboardEquipmentSimulation from './Graphs/DashboardEquipmentSimulation';
import { DashboardShareButton } from '../DashboardShare';
import { useSharedDashboardOwning } from '../SharedDashboardOwning';
import { useDashboards } from '../../../viewComponents/hooks/useDashboards';
import { changeDashboard } from '../../../store/dispatchers';
import { useModal } from '../../../viewComponents/modal/useModal';
import { createDashboardModalSimulation } from './CreateDashboardModalSimulation';
import { useStudies } from '../../../viewComponents/hooks/useStudies';
import { useTooltip } from '../../../viewComponents/tip/useTooltip';

const DashboardSimulationButton = () => {
	const navigate = useNavigate();
	const { studies } = useStudies();
	const [openSimulationModal, setOpenSimulationModal] = React.useState<boolean>(false);

	const setSimulationData = (datasetsIds: string[]) => {
		changeDashboard({ type: 'simulation', data: datasetsIds });
		navigate('/dashboard/simulation/new');
	};
	useModal(openSimulationModal, createDashboardModalSimulation(
		openSimulationModal,
		setOpenSimulationModal,
		setSimulationData,
		studies
	));

	return <button
		className={'button button_primary'}
		onClick={() => setOpenSimulationModal(true)}
		title={translate('dashboard.simulation.new') as string}
	>
		<i className={'fa-solid fa-plus'}/>
	</button>;
};

const DashboardSimulation = () => {
	const { dashboardId } = useParams<{ dashboardId: string }>();
	const { entity, dashboards } = useDashboards();
	const simulationDashboards = dashboards?.filter(dashboard => dashboard.type === 'simulation');
	const datasetsIds = useSelector((state: State) => state.dashboard.simulation);
	const [newData, setNewData] = React.useState<{ data: DashboardComparisonData, maturity: DashboardComparisonMaturity }>();
	const [isAlertOpen, setIsAlertOpen] = React.useState<boolean>(true);
	const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
	const [error, setError] = React.useState<boolean>(false);

	useTooltip(showTooltip, translate('dashboard.tooltip.saved'));

	useEffect(() => {
		if (!dashboardId) return;
		if (dashboardId == 'new') {
			if (datasetsIds.length) entity?.queryDashboardComparisonData({ datasetsIds }).then(res => {
				setNewData(res);
			}).catch(() => setError(true));
		} else {
			const dashboard = simulationDashboards?.find(dashboard => dashboard.id === dashboardId);
			if (!dashboard) return;
			if (!dashboard.data) entity?.queryDashboard(dashboardId);
		}
	}, [dashboardId, JSON.stringify(datasetsIds), JSON.stringify(simulationDashboards)]);

	const dashboard = simulationDashboards?.find(dashboard => dashboard.id == dashboardId);
	const owning = useSharedDashboardOwning(dashboard);
	const isSaved = dashboard && dashboard.data && dashboardId !== 'new';
	const data = !isSaved ? newData : JSON.parse(dashboard.data);
	const { selectedIndicators: selectedIndicators } = useIndicators(data?.data.indicators);
	const { lcs: selectedLifeCycleSteps } = useLifeCycleSteps(data?.data.lifeCycleSteps);

	const side: SideBarContentItemList = {
		content: {
			list: [
				{
					id: '',
					name: translate('dashboard.simulation.new') as string,
					current: dashboardId === 'new',
					link: '/dashboard/simulation/new',
				},
			]
		}
	};

	if (simulationDashboards?.length) {
		simulationDashboards.forEach(dashboard => {
			side.content.list.push({
				id: dashboard.id,
				name: dashboard.name,
				current: dashboard.id === dashboardId,
				link: `/dashboard/simulation/${dashboard.id}`,
			});
		});
	}

	if (dashboardId == 'new' && !datasetsIds.length) return <Template core={<section>
		<h1>{translate('dashboard.simulation')}</h1>
		<p>{translate('dashboard.noDataset')}</p>
		<DashboardSimulationButton />
	</section>} side={side} />;

	if (error) return <Template core={<></>} />;

	if (!data) return <Template core={<section>
		<h1>{translate('dashboard.simulation')}</h1>
		<Spinner />
	</section>} />;

	const core = <section>
		<h1>{translate('dashboard.simulation.title')}</h1>
		{isSaved && dashboard.date && <div className={'dashboard_date'}>
			{translate('dashboard.savedOn', { date: new Date(dashboard.date).toLocaleString() })}
			<button
				className={'button_blank'}
				type={'button'}
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
			>
				<i className="fa-regular fa-circle-exclamation"></i>
			</button>
		</div>}
		{(owning || dashboardId == 'new') && <div className="dashboard_save_button">
			<DashboardSimulationButton />
			{!isSaved && <DashboardSaveDataButton type={'simulation'} data={data} />}
			{isSaved && <DeleteDashboardButton dashboardId={dashboardId} />}
			{isSaved && <DashboardShareButton sharedUsers={dashboard.sharedUsers} dashboard={dashboard} />}
		</div>}
		{isAlertOpen && (selectedIndicators.length > 0 || selectedLifeCycleSteps.length > 0) && <div className={'dashboard_card dashboard_card_info dashboard_card_color_red'}>
			<button className={'close_button'} onClick={() => setIsAlertOpen(false)}>X</button>
			<Alert variant={'info'}>{translate('dashboard.alert.usedIndicatorsAndLifeCycleSteps')}</Alert>
			{selectedIndicators.length > 0 && <Alert variant={'info'}>
				<div>
					{translate('dashboard.indicators')}:
					<ul>
						{selectedIndicators.map(i => <li key={i.id}>
							{i.name} - {i.shortName}
						</li>)}
					</ul>
				</div>
			</Alert>}
			{selectedLifeCycleSteps.length > 0 && <Alert variant={'info'}>
				<div>
					{translate('dashboard.lifeCycleSteps')}:
					<ul>
						{selectedLifeCycleSteps.map(i => <li key={i.id}>
							{i.name}
						</li>)}
					</ul>
				</div>
			</Alert>}
		</div>}
		<DashboardGlobalSimulation data={data.data} />
		<DashboardDomainSimulation data={data.data} />
		<DashboardIndicatorsSimulation data={data.data} />
		<DashboardEquipmentSimulation data={data.data} />
		{/* TODO ADD GRAPHS */}
	</section>;
	return (<Template core={core} side={side} />);
};

export default DashboardSimulation;
