import React, { useEffect, useRef } from 'react';
import Sidebar, { SideBarContentItemList } from '../sidebar/sidebar';
import Loading from '../../Loading';
import { ErrorRenderer } from '../../error/ErrorRenderer/ErrorRenderer';
import ContentHeader from '../../head/contentHeader/contentHeader';
import './template.css';
import { useSelector } from 'react-redux';
import { State } from '../../../store';
import { hexToHsl } from '../../../utils/hexToHsl';
import { useParams } from 'react-router-dom';
import { Arrow } from '../arrow/arrow';
import { GlobalModal } from '../../modal/GlobalModal';
import { ToolTipLight } from '../../tip/ToolTipLight/ToolTipLight';
import UserNotification from '../userNotification/userNotification';
import { useUser } from '../../hooks/useUser';
import Notifications from '.././notification/Notifications';
import { ApiError } from '../../../domain/core/ApiError';

export interface ContentElementsInterface {
	content: React.ReactElement
}

export type ErrorType = Error | ApiError | null

export interface TemplateElementsInterface {
	error?: ErrorType,
	loading?: boolean,
	core: React.ReactElement,
	side: SideBarContentItemList
}

const CoreBlock = (props: ContentElementsInterface): JSX.Element => {
	return (
		<div className="core_block" id={'core_block'}>
			{props.content}
		</div>
	);
};

const Template = ({ core, side, loading, error }: Partial<TemplateElementsInterface>): JSX.Element => {
	const params = useParams();
	core = core ?? <div/>;
	side = side ?? { content: { list: [] } };

	// Error get from store (for example when user is not logged)
	const storeError = useSelector((state: State) => state.error);
	const tooltip = useSelector((state: State) => state.tooltipState);
	const { data: user } = useUser();
	const color = user?.myCompany?.style ? user?.myCompany?.color : '#2f9d68';
	const hsl = hexToHsl(color);
	document.documentElement.style.setProperty('--user-styling-color', `hsl(${hsl[0]},${hsl[1]}%,${hsl[2]}%)`);
	document.documentElement.style.setProperty('--user-styling-color-light', `hsl(${hsl[0]},${hsl[1]}%,${hsl[2] + 20}%)`);
	document.documentElement.style.setProperty('--user-styling-color-table-head', `hsl(${hsl[0]},${hsl[1]}%,95%)`);
	document.documentElement.style.setProperty('--user-styling-color-darken', `hsl(${hsl[0]},${hsl[1]}%,${hsl[2] - 20}%)`);

	const initialFocusRoot = useRef<HTMLDivElement>(null);
	useEffect(() => {
		initialFocusRoot.current?.focus();
	}, [params]);

	if (storeError) return <ErrorRenderer error={storeError}/>;
	if (loading) return <Loading/>;
	if (error) return <ErrorRenderer error={error}/>;

	return (
		<div className="template_content">
			<div className="template_side">
				<Sidebar content={side.content}/>
			</div>
			<main className="template_core" id="main" tabIndex={-1} ref={initialFocusRoot}>
				<GlobalModal/>
				{tooltip && <ToolTipLight>{tooltip}</ToolTipLight>}
				<UserNotification/>
				<Notifications/>
				<Arrow color={color}/>
				<ContentHeader content={side.content}/>
				<CoreBlock content={core}/>
			</main>
		</div>
	);
};

export default Template;
