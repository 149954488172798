import { gql } from '@apollo/client';


export const QUERY_CMDB_TEMPLATE = gql`
    query QueryCmdbTemplate($cmdbType: String!) {
        cmdbTemplate(cmdbType: $cmdbType) {
            cmdbTemplate
            filename
        }
    }
`;
