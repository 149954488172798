import { useSelector } from 'react-redux';
import { State } from '../../store';
import { IUserDTO } from '../../domain/interfaces/DTO/IUserDTO';
import { EConnectionStatus } from '../../domain/interfaces/EConnectionStatus';

type UserHookType = IUserDTO & { status: EConnectionStatus };

export const useUser = (): UserHookType => {
	const { error, entity } = useSelector((state: State) => state.logged);
	const self = entity?.get();
	const status = entity?.status ?? EConnectionStatus.DISCONNECTED;

	return { error, entity, data: self, status };
};
