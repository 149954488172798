import { gql } from '@apollo/client';

export const MUTATE_SHARE_USER_DASHBOARD = gql`
    mutation MutateShareUserDashboard(
        $id: String!
        $userIds: [String!]!
    ){
        mutateShareUserDashboard(
            id: $id
            userIds: $userIds
        ) {
            userDashboard {
                id
                name
                description
                userId
                cards {
                    id
                    name
                    type
                    dashboard {
                        id
                    }
                    data
                    order
                }
                sharedUsers {
                    id
                    email
                }
            }
        }
    }
`;
