import { Reducer } from '@reduxjs/toolkit';
import { GetResponseType, ResponseData } from '../../domain/data/ResponseDatas';
import { IUserDTO } from '../../domain/interfaces/DTO/IUserDTO';
import { ChangeCallBackFunctionArgumentsType } from '../dispatchers';
import { ICompanyDTO } from '../../domain/interfaces/DTO/ICompanyDTO';
import { UserAssignmentsEntity } from '../../domain/entities/UserAssignmentsEntity';
import { INotification } from '../../domain/interfaces/INotification';
import React from 'react';
import { IUserNotification } from '../../domain/interfaces/IUserNotification';
import { ApiErrorJson } from '../../domain/interfaces/ApiErrorJson';

export type LoadReducerAction = { type: 'setLoad', payload: boolean }
export const loadReducer: Reducer<boolean, LoadReducerAction> = (state = false, action: LoadReducerAction): boolean => {
	switch (action.type) {
		case 'setLoad':
			return action.payload;
		default:
			return state;
	}
};

export const versionReducer: Reducer<string, { type: 'setAppVersion', payload: string }> = (state = '', action): string => {
	switch (action.type) {
		case 'setAppVersion':
			return action.payload;
		default:
			return state;
	}
};

export type UserReducerAction = { type: 'setLogged', payload: IUserDTO }
export const loggedReducer: Reducer<IUserDTO, UserReducerAction> = (state = {
	entity: undefined,
	data: undefined,
	error: undefined
}, action: UserReducerAction): IUserDTO => {
	switch (action.type) {
		case 'setLogged':
			return action.payload;
		default:
			return state;
	}
};

export type CompanyReducerAction = { type: 'setCompany', payload: ICompanyDTO }
export const companyReducer: Reducer<ICompanyDTO, CompanyReducerAction> = (state = {
	data: undefined,
	loading: false,
	error: null
}, action: CompanyReducerAction): ICompanyDTO => {
	switch (action.type) {
		case 'setCompany':
			return action.payload;
		default:
			return state;
	}
};

export type UserAssignmentsAction = { type: 'setUserAssignments', payload: UserAssignmentsEntity | null }
export const userAssignmentsReducer: Reducer<UserAssignmentsEntity | null, UserAssignmentsAction> = (state = null, action: UserAssignmentsAction): UserAssignmentsEntity | null => {
	switch (action.type) {
		case 'setUserAssignments':
			return action.payload;
		default:
			return state;
	}
};

export type studyIDReducerAction = { type: 'setStudyID', payload?: string | null }
export const studyIDReducer: Reducer<string | null, studyIDReducerAction> = (state: string | null = null, action: studyIDReducerAction): string | null => {
	switch (action.type) {
		case 'setStudyID':
			return action.payload ?? null;
		default:
			return state;
	}
};

export type datasetIDReducerAction = { type: 'setDatasetID', payload?: string | null }
export const datasetIDReducer: Reducer<string | null, datasetIDReducerAction> = (state: string | null = null, action: datasetIDReducerAction): string | null => {
	switch (action.type) {
		case 'setDatasetID':
			return action.payload ?? null;
		default:
			return state;
	}
};
export type DataStatusReducerAction<T extends ResponseData> = { type: GetResponseType<T>, payload?: ChangeCallBackFunctionArgumentsType<T> }
export const getDataStatusReducer = <T extends ResponseData>(type: GetResponseType<T>) => {
	return (state: ChangeCallBackFunctionArgumentsType<T> = {
		loading: false,
		error: null,
		data: undefined
	}, action: DataStatusReducerAction<T>): ChangeCallBackFunctionArgumentsType<T> => {
		if (action.type === type) return action.payload ?? state;
		return state;
	};
};

export const setTemporaryAssignedUsersEmailReducer = (state: string[] = [], action: { type: 'setTemporaryAssignedUsersEmail', payload: string[] }): string[] => {
	if (action.type === 'setTemporaryAssignedUsersEmail') return action.payload;
	return state;
};

export type NotificationReducerAction = { type: 'setNotification', payload: (ApiErrorJson | INotification)[] };
export const notificationReducer: Reducer<NotificationReducerAction['payload'] | null, NotificationReducerAction> = (state = null, action: NotificationReducerAction): NotificationReducerAction['payload'] | null => {
	switch (action.type) {
		case 'setNotification':
			return action.payload;
		default:
			return state;
	}
};

export type UserNotificationReducerAction = { type: 'setUserNotification', payload: IUserNotification[] };
export const userNotificationReducer: Reducer<UserNotificationReducerAction['payload'] | null, UserNotificationReducerAction> = (state = null, action: UserNotificationReducerAction): UserNotificationReducerAction['payload'] | null => {
	switch (action.type) {
		case 'setUserNotification':
			return action.payload;
		default:
			return state;
	}
};

export type ErrorReducerAction = { type: 'setError', payload: Error | null };
export const errorReducer: Reducer<ErrorReducerAction['payload'] | null, ErrorReducerAction> = (state = null, action: ErrorReducerAction): ErrorReducerAction['payload'] | null => {
	switch (action.type) {
		case 'setError':
			return action.payload;
		default:
			return state;
	}
};

export interface DashboardStore {
	evolution: string[];
	comparison: string[];
	simulation: string[];
}
export interface DashboardPayload {
	type: keyof DashboardStore;
	data: string[];
}
export const dashboardReducer: Reducer<DashboardStore, { type: 'setDashboard', payload: DashboardPayload }> = (state = {
	evolution: [],
	comparison: [],
	simulation: [],
}, action: { type: 'setDashboard', payload: DashboardPayload }): DashboardStore => {
	switch (action.type) {
		case 'setDashboard': {
			const newState = { ...state };
			newState[action.payload.type] = action.payload.data;
			return newState;
		}
		default:
			return state;
	}
};

export type TooltipStateReact = React.ReactElement | string | null
export type TooltipStateReducerAction = { type: 'setTooltipState', payload: TooltipStateReact }
export const tooltipStateReducer: Reducer<TooltipStateReact, TooltipStateReducerAction> = (state = null, action: { type: 'setTooltipState', payload: TooltipStateReact }): TooltipStateReact => {
	switch (action.type) {
		case 'setTooltipState':
			return action.payload;
		default:
			return state;
	}
};

export const selectedIndicatorsForAllReducer: Reducer<string[], { type: 'setSelectedIndicatorsForAll', payload: string[] }> = (state = [], action): string[] => {
	if (action.type === 'setSelectedIndicatorsForAll') return action.payload;
	return state;
};