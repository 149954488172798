import { gql } from '@apollo/client';

export const MUTATE_SET_MATURITY_GOOD_PRACTICES = gql`
    mutation SetMaturityGoodPractices(
      $datasetId: String!,
    ) {
        mutateSetMaturityGoodPractices (
            datasetId: $datasetId,
        ) {
            dataset {
            	id
            	status {
            		maturity
				}
            }
        }
    }
`;
