import { gql } from '@apollo/client';

export const MUTATE_EXPORT = gql`
    mutation MutateExport(
        $launcherId: String!
        $exportDatasetsLcaEquipment: Boolean!,
        $exportDatasetsLcaSettings: Boolean!,
        $exportDatasetsLcaMaturity: Boolean!,
        $exportImpacts: Boolean!,
        $exportResults: Boolean!,
        $exportResultAsLatex: Boolean!,
        $exportEnvironmentalAssessment: Boolean!,
        $lang: String!
    ){
        mutateExport(
            launcherId: $launcherId,
            exportDatasetsLcaEquipment: $exportDatasetsLcaEquipment,
            exportDatasetsLcaSettings: $exportDatasetsLcaSettings,
            exportDatasetsLcaMaturity: $exportDatasetsLcaMaturity,
            exportImpacts: $exportImpacts,
            exportResults: $exportResults,
            exportResultAsLatex: $exportResultAsLatex,
            exportEnvironmentalAssessment: $exportEnvironmentalAssessment,
            lang: $lang
        ) {
            ok
            base64
        }
    }
`;
