import { ResponseDataResultsInput } from '../data/ResponseDataResults';
import { Entity } from '../core/Entity';
import {
	ApolloClientMutateExport,
	ApolloClientMutateResultList,
	ApolloClientMutateResultsComparison,
	ApolloClientQueryDataResults,
	ApolloClientQueryEquipmentGroupBy,
	ApolloClientQueryResultLists,
	ApolloClientQueryResultTypes
} from '../../infrastructure/ApolloClass/ResultsClass';
import { MUTATE_RESULT_LIST_MANAGEMENT } from '../../infrastructure/ApolloClient/requests/MUTATE_RESULT_LIST_MANAGEMENT';
import { MUTATE_RESULTS_COMPARISON } from '../../infrastructure/ApolloClient/requests/MUTATE_RESULTS_COMPARISON';
import { QUERY_DATA_RESULTS } from '../../infrastructure/ApolloClient/requests/QUERY_DATA_RESULTS';
import { QUERY_RESULT_LISTS } from '../../infrastructure/ApolloClient/requests/QUERY_RESULT_LISTS';
import { MUTATE_EXPORT } from '../../infrastructure/ApolloClient/requests/MUTATE_EXPORT';
import { QUERY_EQUIPMENT_GROUP_BY } from '../../infrastructure/ApolloClient/requests/QUERY_EQUIPMENT_GROUP_BY';
import { QUERY_RESULT_TYPES } from '../../infrastructure/ApolloClient/requests/QUERY_RESULT_TYPES';
import { IResultType } from '../interfaces/IResultType';
import {
	IResultList,
	IResultListManagementInput,
} from '../interfaces/IResultList';
import { IMutateResultsComparisonInput, IMutateResultsComparisonOutput } from '../interfaces/IMutateResultsComparison';
import { IQueryEquipmentGroupByInput, IQueryEquipmentGroupByOutput } from '../interfaces/IQueryEquipmentGroupBy';
import { IMutateExportInput } from '../interfaces/IMutateExport';
import { b64toBlob } from '../core/utils';
import { Result } from '../data/entries/Result';

export class ResultsEntity extends Entity {
	public data: undefined | Result[];
	private _isQuerying = false;
	private _rl_id: string | undefined;

	initialization() {
		this.app.adapter.mutateResultListManagement ??= this.app.installer(ApolloClientMutateResultList, MUTATE_RESULT_LIST_MANAGEMENT);
		this.app.adapter.mutateResultsComparison ??= this.app.installer(ApolloClientMutateResultsComparison, MUTATE_RESULTS_COMPARISON);
		this.app.adapter.mutateExport ??= this.app.installer(ApolloClientMutateExport, MUTATE_EXPORT);
		this.app.adapter.queryDataResults ??= this.app.installer(ApolloClientQueryDataResults, QUERY_DATA_RESULTS);
		this.app.adapter.queryResultLists ??= this.app.installer(ApolloClientQueryResultLists, QUERY_RESULT_LISTS);
		this.app.adapter.queryResultTypes ??= this.app.installer(ApolloClientQueryResultTypes, QUERY_RESULT_TYPES);
		this.app.adapter.queryEquipmentGroupBy ??= this.app.installer(ApolloClientQueryEquipmentGroupBy, QUERY_EQUIPMENT_GROUP_BY);
		this.app.adapter.storeDataResults?.({ loading: false, error: null, data: this });
	}

	get(rl_id?: string, resultsIds?: string[]) {
		if (!this.data || this._rl_id !== rl_id) {
			if (this._isQuerying || (!rl_id && !resultsIds)) return this.data;
			this.queryDataResults({ rl_id, resultsIds });
		}
		return this.data;
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeDataResults?.({ loading: this._isQuerying, error: null, data: this }).then();
	}

	set(obj: Result[] | undefined, rl_id?: string): void {
		let found = false;
		obj?.forEach(result => {
			const idx = this.data?.findIndex(r => r.id === result.id);
			if (idx !== undefined && idx !== -1) {
				found = true;
				this.data?.splice(idx, 1, result);
			}
		});
		if (!found) {
			this.data = obj;
		}
		if (rl_id !== this._rl_id)
			this._rl_id = rl_id;
		this.change();
	}

	update(): void {
		return;
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryDataResults(input: ResponseDataResultsInput): Promise<void> {
		this._isQuerying = true;
		this.change();
		const data = await this.callApi(this.app.adapter.queryDataResults, input).catch(err => {
			this.app.adapter.storeDataResults?.({
				loading: false,
				error: err ?? new Error('Something went wrong'),
				data: undefined
			});
			this._isQuerying = false;
			return undefined;
		});
		this._isQuerying = false;
		if (!data) return;
		const filteredData = data.results.filter((result: Result) => result.data && result.data.length > 0);
		this.set(filteredData, input.rl_id);
	}

	async queryResultTypes(datasetId?: string): Promise<IResultType[]> {
		const data = await this.callApi(this.app.adapter.queryResultTypes, { datasetId }).catch((err) => {
			if (!err) return;
		});
		return data ? data.resultTypes : [];
	}

	async queryResultLists(): Promise<IResultList[]> {
		const data = await this.callApi(this.app.adapter.queryResultLists).catch((err) => {
			if (!err) return;
		});
		return data ? data.resultLists : [];
	}

	async queryEquipmentGroupBy(input: IQueryEquipmentGroupByInput): Promise<IQueryEquipmentGroupByOutput> {
		return this.callApi(this.app.adapter.queryEquipmentGroupBy, input);
	}

	async mutateExport(input: IMutateExportInput): Promise<Blob | undefined> {
		const data = await this.callApi(this.app.adapter.mutateExport, input).catch((err) => {
			if (!err) return;
			throw err;
		});
		if (!data) return;
		if (!data.mutateExport.ok) {
			throw new Error('Something went wrong');
		}
		return b64toBlob(data.mutateExport.base64, 'application/zip');
	}

	async mutateResultList(input: IResultListManagementInput): Promise<IResultList[]> {
		const data = await this.callApi(this.app.adapter.mutateResultListManagement, input);
		return data.mutateResultListManagement.resultLists;
	}

	async mutateResultsComparison(input: IMutateResultsComparisonInput): Promise<IMutateResultsComparisonOutput> {
		const data = await this.callApi(this.app.adapter.mutateResultsComparison, input);
		this.set(data.mutateResultsComparison.results);
		return data;
	}
}
