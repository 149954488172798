import { gql } from '@apollo/client';

export const MUTATE_NOTIFY_ASSIGNEES = gql`
	mutation SendNotificationToTemporaryAssignees(
		$send: Boolean!
	){
		mutateSendNotificationToTemporaryAssignees(send: $send) {
			tempsMailList
		}
	}
`;