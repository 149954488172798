import { gql } from '@apollo/client';

export const MUTATE_COMPANY_MANAGE = gql`
	mutation MUTATE_COMPANY_MANAGE(
		$id: String!,
		$name: String!,
		$emailDomains: [String!]!,
		$start: String,
		$end: String,
		$ssoId: String,
		$ssoSecret: String,
		$ssoAuthorizationUrl: String,
		$ssoProvider: String,
		$ssoScope: String,
		$remove: Boolean!
    ){
		mutateCompanyManage(
			id: $id,
			name: $name,
			emailDomains: $emailDomains,
			start: $start,
			end: $end,
			ssoId: $ssoId,
			ssoSecret: $ssoSecret,
			ssoAuthorizationUrl: $ssoAuthorizationUrl,
			ssoProvider: $ssoProvider,
			ssoScope: $ssoScope,
			remove: $remove
		){
      companies {
				id,
        name,
        emailDomains,
				start,
				end,
        ssoId,
        ssoSecret,
        ssoAuthorizationUrl,
        ssoProvider,
        ssoScope,
				isCguAccepted
			}
    }
  }
`;

