import { gql } from '@apollo/client';

export const MUTATE_EDIT_LCA_TEMPLATE = gql`
    mutation MutateEditLcaTemplate(
        $id: String!,
        $nameEn: String!,
        $nameFr: String!,
        $descriptionEn: String!,
        $descriptionFr: String!,
        $functionalUnit: String!,
        $inventory: String!,
        $maturity: String!,
        $resultList: String!,
        $indicators: [String]!,
        $lifeCycleSteps: [String]!,
        $delete: Boolean
    ){
        mutateEditLcaTemplate(
            id: $id,
            nameEn: $nameEn,
            nameFr: $nameFr,
            descriptionEn: $descriptionEn,
            descriptionFr: $descriptionFr,
            functionalUnit: $functionalUnit,
            inventory: $inventory,
            maturity: $maturity,
            resultList: $resultList,
            indicators: $indicators,
            lifeCycleSteps: $lifeCycleSteps,
            delete: $delete
        ){
            template {
                id
                name
                nameEn
                nameFr
                description
                descriptionEn
                descriptionFr
                functionalUnit
                inventory
                maturity
                resultList
                indicators
                lifeCycleSteps
            }
        }
    }
`;
