import { gql } from '@apollo/client';

export const MUTATE_INVENTORY_LINE = gql`
    mutation SaveEquipmentLine(
        $id: String!,
        $values: EquipmentInput!
    ) {
        mutateSaveEquipmentLine(
            id: $id,
            values: $values
        ) {
            equipment {
                id,
                name,
                help,
                unit,
                unitShortName,
                priority,
                specification,
                allocationFactor,
                quantity,
                weightedQuantity,
                unknown,
                quality,
                lifetime,
                internalLifetime,
                reusePart,
                reuseLifetime,
                customElectricityConsumption,
                comment,
                assigned,
                assignedCount,
                forbidden,
                equipmentTypeId,
                flag,
                category,
                cmdb,
                defaultLifetime,
                order,
                isRelatedToResults,
                matchingScore,
				deprecated,
            },
            blocks {
                id,
                cmdb {
                	id,
            		name,
            		category,
                    categoryId,
            		comment,
            		equipmentsInfo {
                        category,
                        categoryIdentifier,
                        quantity,
                		weightedQuantity,
                        totalLifetime, 
                        equipmentLineCount,
                        lifetime,
                        allocationFactor,
                        quality,
                        internalLifetime,
                        reusePart,
                        reuseLifetime,
					}
            	},
                metadata {
                    equipmentRate
                }
            }
        }
    }
`;
