import React, { useEffect, useState } from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import { State } from '../../../store';
import { useSelector } from 'react-redux';
import './ExportModal.scss';
import { Alert, AlertError } from '../../Alerts';
import { useResults } from '../../hooks/useResults';
import { useStudies } from '../../hooks/useStudies';
import { useUser } from '../../hooks/useUser';
import { IResultLauncher } from '../../../domain/data/entries/IResultLauncher';
import { useResultLaunchers } from '../../hooks/useResultLaunchers';
import ButtonLoading from '../../button/ButtonLoading/ButtonLoading';
import { closeGlobalModal } from '../GlobalModal';
import { MultiChoiceDropdown, MultiChoiceDropdownProps } from '../../selector/MultiChoiceDropdown/MultiChoiceDropdown';
import { ApiError } from '../../../domain/core/ApiError';
import { translateFromJSON } from '../../template/notification/Notifications';

export const createExportResultModal = (
	openModal: boolean,
	setOpenModal: (bool: boolean) => void,
	launcher: IResultLauncher | undefined,
	definitiveResultExist: boolean,
	accessExportPrivate: boolean,
	isBenchmarkResult: boolean,
	downloadGraphs: () => void,
	sampleId?: string
) => {
	// State
	const [valuesResults, setValuesResults] = useState<string[]>([]);
	const [valuesLCA, setValuesLCA] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | React.ReactNode>(undefined);
	const [warnForDefinitiveState, setWarnForDefinitiveState] = useState<boolean>(!definitiveResultExist && !isBenchmarkResult);

	// Variable to catch datas from study and dataset
	const { entity: userEntity } = useUser();
	const { entity } = useResults();
	const studyId = useSelector((state: State) => state.studyID);
	const { studies } = useStudies();
	const study = studies?.find(s => s.id === studyId);
	const { entity: rlEntity } = useResultLaunchers(study?.datasetsId, sampleId);

	const [exportLanguage, setExportLanguage] = useState<string>(userEntity?.getLanguage() ?? 'en');

	useEffect(() => {
		setWarnForDefinitiveState(!definitiveResultExist && !isBenchmarkResult);
	}, [definitiveResultExist, isBenchmarkResult]);

	if (!openModal || !launcher) return;

	const close = () => {
		setValuesLCA([]);
		setValuesResults([]);
		setError('');
		setLoading(false);
		setWarnForDefinitiveState(!definitiveResultExist);
		setOpenModal(false);
		closeGlobalModal();
	};

	const resultOptions: {
		id: string;
		value: string;
		label: string;
		tooltip: string;
		disabled?: boolean;
	}[] = [
		{
			id: 'exportResults',
			value: translate('export.results.rawResults') as string,
			label: translate('export.results.rawResults') as string,
			tooltip: translate('export.rawResultsTooltip') as string
		},
		{
			id: 'exportGraphs',
			value: translate('export.results.graphicalResults') as string,
			label: translate('export.results.graphicalResults') as string,
			tooltip: translate('export.graphicalResultsTooltip') as string
		}
	];

	if (accessExportPrivate) {
		resultOptions.push({ // Not working yet
			id: 'exportResultAsLatex',
			value: translate('export.results.executiveSummary') as string,
			label: translate('export.results.executiveSummary') as string,
			tooltip: translate('export.executiveSummaryTooltip') as string,
			disabled: true
		});
		resultOptions.push({
			id: 'exportImpacts',
			value: translate('export.results.exportImpacts') as string,
			label: translate('export.results.exportImpacts') as string,
			tooltip: translate('export.exportImpactsTooltip') as string
		});
		resultOptions.push({
			id: 'exportEnvironmentalAssessment',
			value: translate('export.results.exportEnvironmentalAssessment') as string,
			label: translate('export.results.exportEnvironmentalAssessment') as string,
			tooltip: translate('export.exportEnvironmentalAssessmentTooltip') as string
		});
	}

	const lcaOptions = [
		{
			id: 'exportDatasetsLcaEquipment',
			value: translate('export.dataCollected.inventory') as string,
			label: translate('export.dataCollected.inventory') as string,
			tooltip: translate('export.inventoryTooltip') as string
		},
		{
			id: 'exportDatasetsLcaMaturity',
			value: translate('export.dataCollected.maturity') as string,
			label: translate('export.dataCollected.maturity') as string,
			tooltip: translate('export.maturityTooltip') as string
		},
		{
			id: 'exportDatasetsLcaSettings',
			value: translate('export.dataCollected.settings') as string,
			label: translate('export.dataCollected.settings') as string,
			tooltip: translate('export.settingsTooltip') as string
		},
	];

	const resultSelectorList: MultiChoiceDropdownProps['list'] = [];
	resultOptions.forEach(option => {
		resultSelectorList.push({
			id: option.id,
			dropChildren: [],
			selectable: true,
			element: option.value,
		});
	});

	const lcaSelectorList: MultiChoiceDropdownProps['list'] = [];
	lcaOptions.forEach(option => {
		lcaSelectorList.push({
			id: option.id,
			dropChildren: [],
			selectable: true,
			element: option.value,
		});
	});

	// Array of languages for export the default is the current language
	const languageLst = [userEntity?.getLanguage(), userEntity?.getOtherLanguage()];

	const exportValues = [...valuesResults, ...valuesLCA];

	const downloadExport = () => {
		setLoading(true);
		if (exportValues.includes('exportGraphs')){
			downloadGraphs();
			// remove exportGraphs from the array because at the moment we don't want to download the graphs from backend
			const index = exportValues.indexOf('exportGraphs');
			if (index > -1) {
				exportValues.splice(index, 1);
			}
		}
		if (exportValues.length === 0) {
			close();
			return;
		}
		const exportRequirements = {
			launcherId: launcher.id,
			exportImpacts: exportValues.includes('exportImpacts'),
			exportResults: exportValues.includes('exportResults'),
			exportGraphs: exportValues.includes('exportGraphs'),
			exportEnvironmentalAssessment: exportValues.includes('exportEnvironmentalAssessment'),
			exportResultAsLatex: exportValues.includes('exportResultAsLatex'),
			exportDatasetsLcaEquipment: exportValues.includes('exportDatasetsLcaEquipment'),
			exportDatasetsLcaMaturity: exportValues.includes('exportDatasetsLcaMaturity'),
			exportDatasetsLcaSettings: exportValues.includes('exportDatasetsLcaSettings'),
			lang: exportLanguage,
		};

		entity?.mutateExport(exportRequirements)
			.then(blob => {
				if (!blob) {
					setError(translate('results.error.exportFailed') as string);
					return;
				}
				const tempLink = document.createElement('a');
				tempLink.style.display = 'none';
				tempLink.href = URL.createObjectURL(blob);
				tempLink.setAttribute('download', 'export.zip');

				if (typeof tempLink.download === 'undefined') {
					tempLink.setAttribute('target', '_blank');
				}

				document.body.appendChild(tempLink);
				tempLink.click();

				setTimeout(function () {
					document.body.removeChild(tempLink);
					close();
				}, 200);
				setError(undefined);
			})
			.catch((err) => {
				if (err instanceof ApiError) setError(translateFromJSON(err.json));
				else setError(translate('results.error.exportFailed') as string);
			})
			.finally(() => {
				setLoading(false);
				close();
			});
	};

	const handleSetDefinitiveState = () => {
		rlEntity?.mutateManageResultLauncher({
			id: launcher.id,
			definitive: true
		}).then(() => {
			setWarnForDefinitiveState(false);
		});
	};

	const renderWarnDefinitiveState = () => {
		return (<>
			<Alert variant={'danger'}>
				<i className={'m2 fas fa-exclamation-triangle'}/>
				{translate('export.results.warnDefinitiveResult')}
			</Alert>
		</>);
	};

	const renderExportSelectors = () => {
		return (<>
			{(exportValues.includes('exportDatasetsLcaEquipment') ||
					exportValues.includes('exportDatasetsLcaMaturity') ||
					exportValues.includes('exportDatasetsLcaSettings')) &&
				<Alert variant={'danger'}>
					<i className={'m2 fas fa-exclamation-triangle'}/>
					{translate('export.dataCollected.warning')}
				</Alert>
			}

			<div className={'selector_wrappers'}>
				<div className={'selector_wrapper result_selector'}>
					<h4>{translate('export.results')}</h4>
					<MultiChoiceDropdown list={resultSelectorList} values={valuesResults} onChange={(ids: string[]) => setValuesResults([...ids])} />
				</div>
				<div className={'selector_wrapper lca_selector'}>
					<h4>{translate('export.dataCollected')}</h4>
					<MultiChoiceDropdown list={lcaSelectorList} values={valuesLCA} onChange={(ids: string[]) => setValuesLCA([...ids])} />
				</div>
				<select className={'select_language'} onChange={e => setExportLanguage(e.target.value)}>
					<option value={languageLst[0]}>{translate(`result.${languageLst[0]}`)}</option>
					<option value={languageLst[1]}>{translate(`result.${languageLst[1]}`)}</option>
				</select>
			</div>
		</>);
	};

	const body = (<div className={'export_result_modal'}>
		{warnForDefinitiveState && renderWarnDefinitiveState()}
		{!warnForDefinitiveState && renderExportSelectors()}
	</div>);

	const warnForDefinitiveFooter = (<div className={'modal_footer'}>
		{error && <AlertError status={error} onClick={setError} />}
		<button className={'modal_button'} onClick={close}>
			{translate('cancel')}
		</button>
		<ButtonLoading
			classname={'modal_button validate_modal_button'}
			title={'results.setToDefinitive'}
			onClick={handleSetDefinitiveState}
			loading={loading}
		/>
		<ButtonLoading
			classname={'modal_button validate_modal_button'}
			title={'results.proceedExport'}
			onClick={() => setWarnForDefinitiveState(false)}
			loading={loading}
		/>
	</div>);

	const exportFooter = (<div className={'modal_footer'}>
		{error && <AlertError status={error} onClick={setError} />}
		<button className={'modal_button'} onClick={close}>
			{translate('cancel')}
		</button>
		<ButtonLoading
			classname={'modal_button validate_modal_button'}
			title={'download'}
			onClick={downloadExport}
			loading={loading}
		/>
	</div>);

	return {
		visible: true,
		body: body,
		footer: warnForDefinitiveState ? warnForDefinitiveFooter : exportFooter,
		error: !!error,
		errorMessage: error,
		setError: setError,
		load: loading,
		header: translate('result.ExportModalTitle') as string,
		onClose: close
	};
};
