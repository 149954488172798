import { Language } from '../translate';

export const fr: Language = {
	'noMobile.title': 'Pas de mobile',
	'yes': 'Oui',
	'yes!': 'Oui !',
	'no': 'Non',
	'close': 'Fermer',
	'cancel': 'Annuler',
	'validate': 'Valider',
	'ignore': 'Ignorer',
	'download': 'Télécharger',
	'downloadAll': 'Tout télécharger',
	'toDownload': 'à télécharger',
	'downloadBy': 'Télécharger par',
	'downloadByType': 'Télécharger par type de',
	'choose.by': 'Choisir les',
	'choose.byTypes': 'Choisir les types de',
	'delete': 'Supprimer',
	'error.noData': 'Pas de données',
	'priority': 'Priorité',
	'save': 'Enregistrer',
	'none': 'Aucun(e)',
	'dismiss': 'Abandonner',
	'back': 'Retour',
	'next': 'Suivant',
	'previous': 'Précédent',
	'or': 'Ou',
	'select': 'Sélectionner',
	'create': 'Créer',
	'edit': 'Modifier',
	'search': 'Rechercher',
	'all': 'Tout',
	'all.study': 'Toute l\'étude',
	'selectAll': 'Tout sélectionner',
	'add': 'Ajouter',
	'see.more': 'Voir plus',
	'see.less': 'Voir moins',
	'name': 'Nom',
	'description': 'Description',
	'cgu': 'Conditions générales d\'utilisation',
	'cguNotAccepted': 'Aucun utilisateur de votre organisation n\'a accepté les conditions générales d\'utilisation pour le moment. Un utilisateur accrédité doit accepter les conditions générales d\'utilisation pour pouvoir accéder à l\'application.',
	'...': '...',
	'archived': 'Archivé',
	'clear': 'Effacer',
	'reload.page': 'Recharger la page',
	'send': 'Envoyer',
	'required': 'requis',
	'study': 'Étude',
	'dataset': 'Périmètre',
	'company': 'Organisation',
	'other': 'Autre',
	'value': 'Valeur',
	'unit': 'Unité',
	'selectABlock': 'Sélectionner un domaine',
	'filter': 'Filtrer',
	'maybeLater': 'Peut-être plus tard',
	'whatsNew': 'Nouveautés',
	'share': 'Partager',
	'to.be.done.abrev': 'Obj',
	'accept': 'Accepter',
	'refuse': 'Refuser',
	'continue': 'Continuer',
	'skip': 'Passer',
	'average': 'Moyenne',
	'comingSoon': 'À venir',
	'perUser': 'par utilisateur',
	'database': 'Base de données',
	'help': 'Aide',
	'total': 'Total',
	'noBlockSelected': 'Aucun domaine sélectionné',
	'lcaParameters': 'Paramètres d\'ACV',
	'domain': 'Domaine',
	'energyMix': 'Mix électrique',
	'showMoreDetails': 'Voir plus de détails',
	'DEPRECATED': 'OBSOLÈTE',
	'goTo': 'Aller',
	'comment': 'Commentaire',

	'input.number.placeholder': 'Entrez un nombre',

	'geography.AFR': 'Afrique',
	'geography.ASC': 'Continent Asiatique',
	'geography.AUS': 'Australie',
	'geography.AUT': 'Autriche',
	'geography.BA': 'Bosnie-Herzégovine',
	'geography.BEL': 'Belgique',
	'geography.BG': 'Bulgarie',
	'geography.BRA': 'Brésil',
	'geography.CAN': 'Canada',
	'geography.CA_QC': 'Canada - Québec',
	'geography.CHE': 'Suisse',
	'geography.CHL': 'Chili',
	'geography.CHN': 'Chine',
	'geography.CO': 'Colombie',
	'geography.CZ': 'Tchéquie',
	'geography.DEU': 'Allemagne',
	'geography.DNK': 'Danemark',
	'geography.DOM': 'République Dominicaine',
	'geography.EE': 'Estonie',
	'geography.ESP': 'Espagne',
	'geography.EUC': 'Continent Européen',
	'geography.FIN': 'Finlande',
	'geography.FRA': 'France',
	'geography.GLO': 'Mondial',
	'geography.GR': 'Grèce',
	'geography.HKG': 'Hong Kong',
	'geography.HR': 'Croatie',
	'geography.HU': 'Hongrie',
	'geography.ID': 'Indonésie',
	'geography.IL': 'Israël',
	'geography.IN': 'Inde',
	'geography.IR': 'Irlande',
	'geography.ITA': 'Italie',
	'geography.JPN': 'Japon',
	'geography.KE': 'Kenya',
	'geography.KHM': 'Cambodge',
	'geography.KOR': 'Corée du Sud',
	'geography.KZ': 'Kazakhstan',
	'geography.LB': 'Liban',
	'geography.LT': 'Lituanie',
	'geography.LUX': 'Luxembourg',
	'geography.LV': 'Lettonie',
	'geography.MAR': 'Maroc',
	'geography.MEX': 'Mexique',
	'geography.MUS': 'Maurice',
	'geography.MY': 'Malaisie',
	'geography.NAM': 'Amérique du Nord',
	'geography.NLD': 'Pays-Bas',
	'geography.NOR': 'Norvège',
	'geography.NZL': 'Nouvelle-Zélande',
	'geography.PA': 'Panama',
	'geography.PE': 'Pérou',
	'geography.PH': 'Philippines',
	'geography.PL': 'Pologne',
	'geography.PRT': 'Portugal',
	'geography.RO': 'Roumanie',
	'geography.RS': 'Serbie',
	'geography.RUS': 'Russie',
	'geography.SGP': 'Singapour',
	'geography.SK': 'Slovaquie',
	'geography.SWE': 'Suède',
	'geography.TH': 'Thaïlande',
	'geography.TW': 'Taïwan',
	'geography.TR': 'Turquie',
	'geography.UA': 'Ukraine',
	'geography.UK': 'Royaume-Uni',
	'geography.USA': 'États-Unis',
	'geography.UY': 'Uruguay',
	'geography.VE': 'Venezuela',
	'geography.VN': 'Vietnam',
	'geography.ZAF': 'Afrique du Sud',
	'geography.AR': 'Argentine',
	'geography.AE': 'Émirats Arabes Unis',
	'geography.PK': 'Pakistan',
	'geography.EG': 'Égypte',

	'login.login': 'Login',
	'login.email': 'Email',
	'login.password': 'Mot de passe',
	'login.connect': 'Se connecter',
	'login.required': '(requis)',
	'login.mailPlaceHolder': 'monmail@monorg.com',
	'login.passwordPlaceHolder': 'mon mot de passe',
	'login.forgotPassword': '&link(Mot de passe oublié ?)',
	'login.noAccount': 'Pas de compte ? &linked(Contactez-nous !)',
	'login.noSSOAccount': 'Pas de SSO ? &linked(Contactez-nous) pour ajouter votre SSO !',
	'login.authFail': 'Authentification échouée. Veuillez vérifier vos identifiants.',
	'login.networkInterrupted': 'Connexion interrompue. Essayez de vous reconnecter.',
	'login.errorOccurs': 'Une erreur est apparue côté serveur. Réessayez plus tard. Si le problème persiste, contactez un administrateur.',
	'login.with': 'Se connecter avec',
	'login.switch': 'SSO',

	'cguForm.fullCgu': 'Cliquez ici pour lire les conditions générales d\'utilisation complètes',
	'cguForm.skip': 'Passer pour le moment',

	'login.ssoMail': 'Email SSO',
	'login.ssoMail.placeholder': 'monmail@sso_domaine.com',
	'login.sso.redirect': 'Vous allez être redirigé dans notre application. Cette fenêtre va se fermer automatiquement.',
	'login.sso.errorDomain': 'Le domaine de votre email n\'est pas autorisé à se connecter à cette application.',
	'login.sso.errorState': 'Le paramètre state est invalide.',
	'login.sso.errorCode': 'Désolé, la connection SSO a échoué. Veuillez réessayer.',
	'login.sso.switch': 'Connexion classique',

	'login.maintenance': 'Maintenance',
	'login.maintenance.description': 'Le site est en maintenance. Veuillez réessayer plus tard.',
	'login.maintenance.backTimeEstimate': 'Nous serons de retour dans environ 30 minutes au plus tard.',
	'login.maintenance.contactUs': 'Si vous souhaitez être contacté lorsque l\'application sera de nouveau disponible, veuillez remplir le formulaire ci-dessous.',
	'login.maintenance.success': 'Merci ! Nous vous contacterons lorsque l\'application sera de nouveau disponible.',
	'login.maintenance.error': 'Une erreur est apparue. Veuillez réessayer plus tard.',

	'passwordReset.resetPassword': 'Réinitialisation de mot de passe',
	'passwordReset.newPassword': 'Nouveau mot de passe',
	'passwordReset.confirmPassword': 'Confirmation de mot de passe',
	'passwordReset.save': 'Enregistrer',

	'passwordSendReset.resetPassword': 'Réinitialisation de mot de passe',
	'passwordSendReset.description': 'Insérez votre email pour recevoir le lien de changement de mot de passe.',
	'passwordSendReset.email': 'Email',
	'passwordSendReset.send': 'Envoyer',
	'password': 'Mot de passe',

	'register.register': 'S\'enregistrer',
	'register.accusedReceive': 'Texte d\'information renvoyé par le back',
	'register.email': 'Email',
	'register.username': 'Login',
	'register.password': 'Mot de passe',
	'register.confirmPassword': 'Confirmation de mot de passe',
	'register.already': 'Vous avez déjà un compte ? &login(Connexion) !',

	'ariaNavigation.menu': 'Menu',
	'ariaNavigation.mainContent': 'Contenu principal',
	'ariaNavigation.accountCapsule': 'Capsule de compte',


	'home.status.resultReady': 'Résultats prêts',
	'home.status.resultProgress': 'Résultats en cours',
	'home.status.validationProgress': 'Validation en cours',
	'home.status.start': 'Commencer',
	'home.status.collecting': 'Collecte',
	'home.noStudyYet': 'Aucune étude n\'est disponible pour le moment... Merci de contacter votre consultant. L\'équipe de Resilio a été notifiée de l\'incident.',
	'home.studies': 'Etudes',
	'home.studiesShown': 'études affichées',
	'home.filterStudyYearOrCompany': 'Filtrer les études, années ou entreprises',
	'home.yourStudies': 'Vos études',
	'home.noStudies': 'Aucune études maintenant... Veuillez s\'il vous plait contacter votre consultant. La situation a été rapporté aux équipes de Resilio',
	'home.error.accessUnauthorized': 'Désolé, il semble que vous n\'ayez les droits d\'accès à aucune étude.',
	'home.study': 'Étude',
	'home.year': 'Année',
	'home.perimeters': 'Périmètres',
	'home.perimeter': 'Périmètre',
	'home.company': 'Organisation',
	'home.showMoreStudies': 'Afficher &return() études de plus',
	'home.open': 'Ouvertes',
	'home.archived': 'Archivées',
	'home.manage': 'Gérer',
	'home.inventory': 'Inventaire',
	'home.maturity': 'Maturité',
	'home.result': 'Résultat',
	'home.study.showDatasets': 'Afficher les datasets',
	'home.study.checklistModal.title': 'Checklist de l\'étude',
	'home.study.checklistModal.helper': `
Cette zone de texte peut etre utilisée pour écrire les différentes tâches à effectuer dans la cadre de cette étude. Par exemple :

Une simple liste peut ressembler à ceci :
- [ ] Collecte de données
- [ ] Validation des données
- [ ] Calcul des résultats

---

L'écriture du texte se fait en utilisant le langage Markdown. Voici quelques exemples de syntaxes fréquemment utilisés (passer en mode édition pour voir cet exemple) :
# Titre
## Sous-titre
### Sous-sous-titre
*texte en italique*  
**texte en gras**  
[lien](https://www.resilio-solutions.com)  
- liste à puces
	- [ ] case à cocher  
	- [x] case à cocher cochée  
	
Si vous cherchez plus de détails sur la syntaxe Markdown, vous pouvez consulter [le guide du Markdown](https://www.markdownguide.org/basic-syntax/).
`,
	'home.notification.newUpdate.title': '🎉🎉🎉 &return() Nouvelles fonctionnalités disponibles ! &return() Cliquez sur Oui pour en savoir plus',
	'home.notification.newUpdate.message': 'Vous pouvez accéder à tout moment à ce récapitulatif des nouvelles fonctionnalités en cliquant sur le bouton "Nouveauté" en haut à droite de la page d\'accueil.',

	'accountHeader.info': 'Info',
	'accountHeader.manage': 'Gestion',
	'accountHeader.logout': 'Déconnexion',
	'accountHeader.settings': 'Paramètres',
	'accountHeader.accountBadge': 'Accéder à vos paramètres',

	'staticPage.unknown': 'Page inconnue',


	'userSettings.userSettings': 'Paramètres d\'utilisateur',
	'userSettings.password': 'Mot de passe',
	'userSettings.save': 'Enregistrer',
	'userSettings.actualPassword': 'Mot de passe actuel',
	'userSettings.newPassword': 'Nouveau mot de passe',
	'userSettings.confirmPassword': 'Confirmation de mot de passe',

	'userManage.manage': 'Gestion',
	'userManage.users': 'Utilisateurs',
	'userManage.users.selectGroup': 'Sélectionnez un groupe',
	'userManage.companies': 'Organisations',
	'userManage.companies.monthLicenseFilter': 'Filter les organisations par date de fin de licence',
	'userManage.companies.monthLicense': 'Organisations ayant moins de x mois restants jusqu\'à l\'expiration de la licence',
	'userManage.companies.monthLicense.moreThan6Month': 'Organisations ayant une licence qui expire dans plus de 6 mois',
	'userManage.users.selectCompany': 'Sélectionnez une compagnie',
	'userManage.permissions': 'Accès',
	'userManage.permissions.companyToManageAssign': 'Assigner une organisation à gérer',
	'userManage.result_lists': 'Liste des résultats',
	'userManage.no_result_lists': 'Aucune liste de résultat pour le moment...',
	'userManage.user.create': 'Créer un utilisateur',
	'userManage.user.remove': 'Supprimer le compte ?',
	'userManage.all': 'Tous',
	'userManage.create': 'Créer',
	'userManage.sendMail': 'Envoi mail',
	'userManage.userCreate.error': 'Une erreur s\'est produite lors de la création de cet utilisateur, assurez-vous de remplir les informations correctement',
	'userManage.userDelete.error': 'Une erreur s\'est produite lors de la création de cet utilisateur. Essayer de rafraîchir la page ou contacter votre consultant si l\'erreur persiste.',
	'userManage.matchDomainError': 'L\'email renseigné ne correspond pas au domaine enregistré pour cette organisation (&domain()).',
	'userManage.remove': 'Retirer',
	'userManage.save': 'Enregistrer',
	'userManage.login': 'Login',
	'userManage.user.name': 'Login',
	'userManage.email': 'Email',
	'userManage.user.firstName': 'Prénom (facultatif)',
	'userManage.user.lastName': 'Nom (facultatif)',
	'userManage.user.phoneNumber': 'Numéro de téléphone (facultatif)',
	'userManage.user.email': 'Email',
	'userManage.user.ssoEmail': 'Email SSO (facultatif)',
	'userManage.user.company': 'Organisation',
	'userManage.user.selectACompany': 'Sélectionnez une organisation',
	'userManage.users.groups': 'Groupes',
	'userManage.user.active': 'Compte actif',
	'userManage.user.status.active': 'Actif',
	'userManage.user.status.inactive': 'Inactif',
	'userManage.user.status.pending': 'Cet utilisateur n\'a accès à aucune étude. Vous pouvez lui donner accès à une étude en allant dans la page Accès.',
	'userManage.user.created': 'Félicitations, l\'utilisateur &email() a été créé avec succès ! Vous pouvez maintenant lui donner accès à une étude en allant dans la page &page(Accès).',
	'userManage.user.createdActive': 'Un e-mail automatique a été envoyé à l\'utilisateur pour lui expliquer comment accéder à Resilio Tech.',
	'userManage.user.cguLabel': 'Doit accepter les CGU',
	'userManage.user.setMustAcceptCGU': 'Donner le droit d\'accepter les conditions générales d\'utilisation à cet utilisateur. Au moins un utilisateur par organisation doit accepter les conditions générales d\'utilisation. Tant qu\'aucun utilisateur n\'a accepté les conditions générales d\'utilisation, aucun utilisateur de cette organisation ne pourra accéder à l\'application.',
	'userManage.user.groupByAccess': 'Cet utilisateur a t\'il accès aux impacts détaillés par catégorie d\'équipement ?',
	'userManage.canManage': 'Peut gérer des utilisateurs ?',
	'userManage.deactivationInfo': 'Pour désactiver un compte, contacter support@resilio-solutions.com',
	'userManage.datasetAccess': 'Accès aux datasets',
	'userManage.company.name': 'Nom de l\'organisation',
	'userManage.company.domain': 'Domaine de l\'organisation',
	'userManage.company.start': 'Début de la licence',
	'userManage.company.end': 'Fin de la licence',
	'userManage.company.create': 'Créer une organisation',
	'userManage.company.createError': 'Une erreur s\'est produite lors de la création de cette organisation',
	'userManage.company.domainError': 'Le domaine de l\'organisation est déjà utilisé',
	'userManage.company.nameError': 'Le nom de l\'organisation est déjà utilisé',
	'userManage.company.placeholder.name': 'Nom de l\'organisation',
	'userManage.company.placeholder.domain': 'ex: org.com',
	'userManage.company.remove': 'Supprimer l\'organisation ?',
	'userManage.company.filter': 'Filter les organisations',
	'userManage.company.cguNotAccepted': 'Aucun utilisateur de cette organisation n\'a accepté les conditions générales d\'utilisation pour le moment',
	'userManage.studies': 'Etudes',
	'userManage.studies.create': 'Créer une étude',
	'userManage.studies.create.error': 'Une erreur s\'est produite lors de la création de cette étude',
	'userManage.studies.delete.error': 'Une erreur s\'est produite lors de la délétion de cette étude',
	'userManage.studies.create.duplicateError': 'Les données de l\'étude sont déjà utilisées par une autre étude de cette organisation',
	'userManage.studies.edit': 'Modifier l\'étude',
	'userManage.studies.edit.error': 'Une erreur s\'est produite lors de l\'édition de cette étude',
	'userManage.studies.edit.duplicateError': 'Les données de l\'étude sont déjà utilisées par un autre périmètre de cette étude',
	'userManage.studies.archive': 'Archiver l\'étude',
	'userManage.studies.unarchive': 'Désarchiver l\'étude',
	'userManage.studies.delete': 'Supprimer l\'étude',
	'userManage.studies.colorSettings': 'Couleurs',
	'userManage.studies.colorSettings.title': 'Couleurs de l\'étude',
	'userManage.studies.colorSettings.lcsMode': 'Étape du cycle de vie',
	'userManage.studies.colorSettings.blockMode': 'Domaines d\'inventaire',
	'userManage.studies.colorSettings.indicatorMode': 'Indicateurs environnementaux',
	'userManage.studies.colorSettings.error': 'Une erreur s\'est produite lors de la sauvegarde des paramètres de cette étude',
	'userManage.studies.colorSettings.query.error': 'Une erreur s\'est produite lors de la récupération des paramètres de cette étude',
	'userManage.studies.colorSettings.chooseColor': 'Choisir une autre couleur',
	'userManage.studies.colorSettings.warningDefaultColor': 'Cette fonctionnalité vous permet de personnaliser la couleur de vos résultats et d\'unifier les couleurs des domaines, des indicateurs environnementaux et des étapes du cycle de vie de tous les périmètres d\'une étude. En sauvegardant cette proposition de couleurs, elles seront appliquées à l\'ensemble de l\'étude.',
	'userManage.studies.colorSettings.warningSavedColor': 'En sauvegardant ces couleurs, elles seront appliquées à l\'ensemble de l\'étude',
	'userManage.studies.delete.title': 'Supprimer l\'étude ?',
	'userManage.studies.delete.name': 'Supprimer l\'étude &study() ?',
	'userManage.studies.edit.title': 'Modifier l\'étude',
	'userManage.studies.archive.title': 'Archiver',
	'userManage.studies.unarchive.title': 'Désarchiver',
	'userManage.studies.dataset.archive.title': 'Archiver un périmètre',
	'userManage.studies.dataset.unarchive.title': 'Désarchiver un périmètre',
	'userManage.studies.dataset.archive': 'Archiver un périmètre',
	'userManage.studies.dataset.unarchive': 'Désarchiver un périmètre',
	'userManage.studies.dataset.edit': 'Modifier le périmètre',
	'userManage.studies.dataset.delete': 'Supprimer le périmètre',
	'userManage.studies.dataset.addGp': 'Ajouter les bonnes pratiques',
	'userManage.studies.add.dataset': 'Ajouter un périmètre',
	'userManage.studies.dataset.add.title': 'Ajouter un périmètre',
	'userManage.studies.create.name': 'Nom de l\'étude',
	'userManage.studies.create.consultants': 'Consultants',
	'userManage.studies.create.functionalUnit': 'Unité fonctionnelle',
	'userManage.studies.create.year': 'Année de l\'étude',
	'userManage.studies.create.company': 'Organisation de l\'étude',
	'userManage.studies.create.selectFunctionalUnit': 'Sélectionnez une unité fonctionnelle',
	'userManage.studies.dataset.create.name': 'Nom du périmètre',
	'userManage.studies.dataset.study': 'Étude du périmètre',
	'userManage.studies.dataset.delete.title': 'Supprimer le périmètre ?',
	'userManage.studies.dataset.delete.warning': 'Attention, &red(la suppression de ce périmètre est définitive) &dataset(). Les résultats, l\'inventaire et toutes les données associées à cet inventaire seront également supprimés.',
	'userManage.studies.dataset.delete.delayWarning': 'Attention, la suppression de ce périmètre peut prendre quelques minutes. Veuillez patienter et ne pas quitter la page.',
	'userManage.studies.dataset.description': 'Décrivez le périmètre de l\'analyse',
	'userManage.studies.dataset.addGp.success': 'Vos bonnes pratiques ont bien été ajoutées au périmètre',
	'userManage.studies.deleteDataset.error': 'Ce périmètre ne peut pas être supprimé car il contient des résultats',
	'userManage.studies.deleteStudy.error': 'Cette étude ne peut pas être supprimée car elle contient des datasets',
	'userManage.studies.filter.consultants': 'Filter les consultants',
	'userManage.permissions.selectUser': 'Sélectionnez un utilisateur',
	'userManage.permissions.noStudies': 'Aucune étude pour le moment...',
	'userManage.permissions.save': 'Enregistrer',
	'userManage.permissions.list.error': 'Aucune liste d\'utilisateurs n\'a été trouvée. Il semble qu\'il n\'y ait pas d\'utilisateurs dans votre organisation. Veuillez contacter votre consultant pour plus d\'informations.',
	'userManage.permissions.permissions.filter': 'Filtrer les organisations',
	'userManage.resultList.name': 'Nom de la liste de résultats',
	'userManage.resultList.placeholder.name': 'Nom',
	'userManage.resultList.description': 'Description de la liste de résultats',
	'userManage.resultList.placeholder.description': 'Description',
	'userManage.resultList.types': 'Types de résultats',
	'userManage.resultList.create': 'Créer une liste de résultats',
	'userManage.resultList.create.error': 'Une erreur s\'est produite lors de la création de cette liste de résultats',
	'userManage.resultList.remove': 'Supprimer la liste de résultats ?',
	'userManage.resultList.remove.error': 'Une erreur s\'est produite lors de la suppression de cette liste de résultats',
	'userManage.resultList.save.error': 'Une erreur s\'est produite lors de la sauvegarde de cette liste de résultats',
	'userManage.resultList.usedInComparison': 'Ce résultat sera comparé au benchmark si ce dernier a été sélectionné',
	'userManage.resultList.needSample': 'Ce résultat nécessitera un benchmark pour être calculé',
	'userManage.dataBase.newImpactFactors': 'Créer des nouveaux facteurs d\'impacts',
	'userManage.dataBase.importFile': 'Importer un fichier',
	'userManage.dataBase.equipmentName': 'Nom de l\'équipement',
	'userManage.dataBase.equipmentName.desc': 'Nom principal de l\'équipement, affiché en gras dans l\'inventaire (ex : Laptop, dell latitude).',
	'userManage.dataBase.equipmentSpecification': 'Spécification de l\'équipement',
	'userManage.dataBase.equipmentSpecification.desc': 'Attribut différenciant de l\'équipement, affiché sous le nom de l\'équipement dans l\'inventaire (ex : haut de gamme, 2CPU - écran 13 pouces...).',
	'userManage.dataBase.equipmentDescription': 'Description de l\'équipement',
	'userManage.dataBase.equipmentDescription.desc': 'Détails sur l\'équipement : il peut s\'agir d\'une description détaillée, d\'une indication sur l\'endroit où trouver ce type de données... Elle est affichée dans la dernière colonne de l\'inventaire lors du survol de l\'infobulle ’?’.',
	'userManage.dataBase.equipmentCategory': 'Catégorie de l\'équipement',
	'userManage.dataBase.equipmentCategory.desc': 'Catégorie de l’équipement (ex : Ordinateurs portables, Achat de services informatiques...). Tous les équipements d\'une même catégorie doivent avoir la même unité.',
	'userManage.dataBase.equipmentIsAService': 'Est-ce un service ?',
	'userManage.dataBase.equipmentIsAService.desc': 'La ligne ajoutée est un service si l\'élément n\'est pas un équipement informatique à proprement parlé (ex : distance parcourue par le personnel informatique, achat de services informatiques). Aucune durée de vie n\'est associée à cet élément.',
	'userManage.dataBase.equipmentDefaultLifeTime': 'Durée de vie par défaut (années)',
	'userManage.dataBase.equipmentDefaultLifeTime.desc': 'Pour les services, la durée de vie par défaut correspond à la durée d\'utilisation considérée dans l\'étude. Dans la plupart des cas, nous considérons 1 an d\'utilisation (à l\'exception de données concernant les bâtiments).',
	'userManage.dataBase.equipmentCountryOfUse': 'Pays d\'utilisation',
	'userManage.dataBase.equipmentCountryOfUse.desc': 'Entrez le pays d\'utilisation si vous avez utilisé un mix électrique pour calculer les facteurs d\'émission dans la phase d\'utilisation. ',
	'userManage.dataBase.equipmentAdditionalInfo': 'Ajoutez toute information complémentaire souhaitée',
	'userManage.dataBase.equipmentAdditionalInfo.desc': 'Informations complémentaires',
	'userManage.dataBase.equipmentMass': 'Masse (kg)',
	'userManage.dataBase.equipmentMass.desc': 'Indiquez la masse de l\'appareil.',
	'userManage.dataBase.electricityConsumption': 'Consomation électrique (KWh)',
	'userManage.dataBase.electricityConsumption.desc': 'Consommation électrique annuelle des appareils (en kWh).',
	'userManage.dataBase.equipmentImpactFactors': 'Facteurs d\'impacts',
	'userManage.dataBase.companiesManaged': 'Vos organisations',
	'userManage.dataBase.companiesManaged.desc': 'Cette équipement sera privé et ne pourra être utilisé que par l\'organisation que vous sélectionner',
	'userManage.dataBase.impactFactorsFormat': 'Format des facteurs d\'impacts',
	'userManage.dataBase.impactFactorsFormat.desc': 'Sélectionnez un format de donnée pour les facteurs d\'impacts que vous souhaitez créer (À venir)',
	'userManage.dataBase.createEquipmentType': 'Créer un type d\'équipement',
	'userManage.dataBase.error.removeEquipmentType': 'Une erreur s\'est produite lors de la suppression de ce type d\'équipement',
	'userManage.dataBase.error.updateEquipmentType': 'Une erreur s\'est produite lors de la mise à jour de ce type d\'équipement',
	'userManage.dataBase.error.createEquipmentType': 'Une erreur s\'est produite lors de la création de ce type d\'équipement',
	'userManage.dataBase.error.mandatoryNotFilled': 'Veuillez remplir tout les champs obligatoires',
	'userManage.dataBase.error.noDefaultLifetime': 'Un équipement considéré comme service doit avoir un temps de vie par défaut',
	'userManage.dataBase.error.nonNumericalValue': 'Veuillez entrez seulement des valeurs numériques pour ce champ',
	
	'userManage.blocks': 'Domaines d\'inventaire',
	'userManage.samples': 'Benchmarks',
	'userManage.maturityDomains': 'Domaines de maturité',
	'userManage.database': 'Base de donnée',
	'userManage.equipmentCategoriesOrder': 'Ordre des catégories d\'équipement',
	'userManage.equipmentTypeOrder': 'Ordre des types d\'équipement',
	'userManage.indicatorOrder': 'Ordre des indicateurs',
	'userManage.ErrorTestView': 'Vue test d\'erreur',
	'userManage.sample.create': 'Créer un benchmark',
	'userManage.sample.create.error': 'Une erreur s\'est produite lors de la création de ce benchmark',
	'userManage.sample.update.error': 'Une erreur s\'est produite lors de la mise à jour de ce benchmark',
	'userManage.sample.functionalUnitError': 'Les périmètres doivent être de la même unité fonctionnelle',
	'userManage.sample.remove': 'Supprimer ce benchmark ?',
	'userManage.sample.datasets': 'Datasets: (&count(count) selectionnés pour ce benchmark)',
	'userManage.sample.desc': 'Description',
	'userManage.sample.placeholder.name': 'Nom',
	'userManage.sample.placeholder.desc': 'Description',
	'userManage.sample.name': 'Nom du benchmark',
	'userManage.sample.useForValidation': 'Utiliser pour la validation',
	'userManage.sample.resultsView': 'Vue des résultats',
	'userManage.sample': 'Échantillon',
	'userManage.privateSample': 'Échantillons Privé',
	'userManage.publicSample': 'Échantillons Public',
	'userManage.sample.datasets.unification': 'Unification des périmètres',
	'userManage.sample.datasets.unification.desc': 'Les paramètres d\'ACV pour les périmètres sélectionnés seront unifiés pour ce benchmark.',
	'userManage.sample.datasets.unification.check': 'Vérifier les périmètres',
	'userManage.sample.datasets.unification.checked': 'Différences trouvées que l\'unification peut résoudre',
	'userManage.sample.datasets.unification.proceed': 'Procéder à l\'unification',
	'userManage.sample.datasets.unification.cancel': 'Annuler',
	'userManage.sample.datasets.unification.complete': 'Unification terminée',
	'userManage.sample.datasets.unification.good': 'Les périmètres sélectionnés sont identiques',
	'userManage.sample.datasets.unification.noChanges': 'Les périmètres suivants ont des différences mais l\'unification ne peut pas les résoudre. Veuillez vérifier les différences et essayer de les unifier manuellement.',
	'userManage.sample.datasets.unification.close': 'Fermer',
	'userManageUsersView.userPrivilegesConfirmationModal.title': 'Confirmation',
	'userManageUsersView.userPrivilegesConfirmationModal.description': 'Vous êtes sur le point de donner un status privilégié à l\'utilisateur &email(name). Une confirmation par un administrateur sera nécessaire pour valider ce privilège. Êtes vous sur de vouloir continuer et faire la demande ?',
	'userManageUsersView.userPrivilegesConfirmationModal.confirm': 'Confirmer la demande de privilège',
	'userManage.sample.xCountHiddenDataset': 'Ce benchmark contient &count(x) périmètre·s dont vous n\'avez pas les droits d\'accès.',
	'userManage.sample.private': 'Marquer ce benchmark comme privé (invisible pour les personnes hors de Resilio)',
	'userManage.sample.privateDesc': 'Si cet benchmark est rendu public, un.e consultant.e externe (ne faisant pas partie de Resilio) pourra voir le nom de ce benchmark et aura accès à ses résultats agrégés (il/elle ne verra cependant pas le nom des périmètres composant ce benchmark s\'il/elle n\'y a pas accès)',
	'userManage.functional_unit': 'Unité fonctionnelle',
	'userManage.lca_inventory': 'Inventaire Cycle de Vie',
	'userManage.lca_maturity_model': 'Modèle de maturité',
	'userManage.lca_template': 'Modèle LCA',
	'userManage.functionalUnit.create': 'Créer une unité fonctionnelle',
	'userManage.functionalUnit.name': 'Nom de l\'unité fonctionnelle',
	'userManage.functionalUnit.private': 'Privé',
	'userManage.functionalUnit.placeholder.name_fr': 'Nom en français',
	'userManage.functionalUnit.placeholder.name_en': 'Nom en anglais',
	'userManage.functionalUnit.delete': 'Supprimer l\'unité fonctionnelle ?',
	'userManage.functionalUnit.defaultLcaTemplate': 'Modèle ACV par défaut',
	'userManage.functionalUnit.defaultLcaTemplate.none': 'Pas de modèle par défaut',
	'userManage.lcaInventory.createLCAInventory': 'Créer un inventaire LCA',
	'userManage.lcaInventory.name': 'Nom de l\'ICV',
	'userManage.lcaInventory.description': 'Description de l\'ICV',
	'userManage.lcaInventory.error.noName': 'Veuillez entrer un nom pour cet ICV',
	'userManage.lcaInventory.error.noDescription': 'Veuillez entrer une description pour cet ICV',
	'userManage.lcaInventory.error.noEquipments': 'Veuillez sélectionner au moins un équipement pour cet ICV',
	'userManage.lcaInventory.error.noSettings': 'Veuillez sélectionner au moins un paramètre pour cet ICV',
	'userManage.lcaInventory.error.blockNotSet': 'Veuillez sélectionner un domaine pour tous les équipements de cet ICV',
	'userManage.lcaInventory.error.network': 'Une erreur réseau s\'est produite lors de la modification de cet ICV',
	'userManage.lcaInventory.equipments': '&count(Compte) Equipement(s)',
	'userManage.lcaInventory.settings': '&count(Compte) Paramètres(s)',
	'userManage.lcaInventory.categorySelection': 'Sélectionnez une catégorie d\'équipement',
	'userManage.lcaInventory.selectADomain': 'Sélectionnez un domaine',
	'userManage.lcaInventory.quitNotSaved': 'Vous avez des modifications non-sauvegardées',
	'userManage.lcaInventory.quitWithoutSaving': 'Quitter sans sauvegarder',
	'userManage.lcaInventory.saveAndQuit': 'Sauvegarder et quitter',
	'userManage.lcaInventory.noMoreDomain': 'Vous avez sélectionné tous les domaines disponibles',
	'userManage.lcaInventory.errorDependency': 'Vous ne pouvez pas supprimer cet ICV car il est utilisé dans un modèle de maturité',
	'userManage.lcaInventory.editEquipments': 'Modifier les équipements',
	'userManage.lcaInventory.editSettings': 'Modifier les paramètres',
	'userManage.lcaMaturity.createLCAMaturity': 'Créer un modèle de maturité',
	'userManage.lcaMaturity.name': 'Nom du modèle de maturité',
	'userManage.lcaMaturity.description': 'Description du modèle de maturité',
	'userManage.lcaMaturity.error.noName': 'Veuillez entrer un nom pour ce modèle de maturité',
	'userManage.lcaMaturity.error.noDescription': 'Veuillez entrer une description pour ce modèle de maturité',
	'userManage.lcaMaturity.error.noEquipments': 'Veuillez sélectionner au moins une bonne pratique pour ce modèle de maturité',
	'userManage.lcaMaturity.error.network': 'Une erreur réseau s\'est produite lors de la modification de ce modèle de maturité',
	'userManage.lcaMaturity.goodPractices': '&count(Compte) Bonne Pratique(s)',
	'userManage.lcaMaturity.error.noGoodPractice': 'Veuillez sélectionner au moins une bonne pratique pour ce modèle de maturité',
	'userManage.lcaTemplate.createLcaTemplate': 'Créer un modèle LCA',
	'userManage.lcaTemplate.nameEn': 'Nom du modèle LCA (EN)',
	'userManage.lcaTemplate.nameFr': 'Nom du modèle LCA (FR)',
	'userManage.lcaTemplate.descriptionEn': 'Description du modèle LCA (EN)',
	'userManage.lcaTemplate.descriptionFr': 'Description du modèle LCA (FR)',
	'userManage.lcaTemplate.inventory': 'Inventaire',
	'userManage.lcaTemplate.selectInventory': 'Pas d\'inventaire',
	'userManage.lcaTemplate.maturity': 'Modèle de maturité',
	'userManage.lcaTemplate.selectMaturity': 'Pas de modèle de maturité',
	'userManage.lcaTemplate.functionalUnit': 'Unité fonctionnelle',
	'userManage.lcaTemplate.selectFunctionalUnit': 'Sélectionner une unité fonctionnelle',
	'userManage.lcaTemplate.resultList': 'Liste des résultats',
	'userManage.lcaTemplate.selectResultList': 'Pas de liste de résultats',
	'userManage.lcaTemplate.indicators': 'Indicateurs',
	'userManage.lcaTemplate.lifeCycleSteps': 'Étapes du cycle de vie',
	'userManage.lcaTemplate.deleteLcaTemplate': 'Supprimer le modèle LCA ?',
	'userManage.lcaTemplate.deleteLcaTemplateConfirm': 'Êtes-vous sûr de vouloir supprimer ce modèle LCA ?',

	'userManageSettings.sso': 'SSO',
	'userManageSettings.sso.title': 'Configurer votre SSO',
	'userManageSettings.sso.clientId': 'Client ID',
	'userManageSettings.sso.clientSecret': 'Client Secret',
	'userManageSettings.sso.authorityUrl': 'Autorisation URL',
	'userManageSettings.sso.provider': 'Provider',
	'userManageSettings.sso.scope': 'Scope',
	'userManageSettings.error.noCompany': 'Il n\'y a pas d\'organisation associée à votre compte. Veuillez contacter votre administrateur.',
	'userManageSettings.password': 'Mot de passe',
	'userManageSettings.infos.redirectUriMessage': 'L\'URI de redirection doit être configuré dans votre application SSO.',
	'userManageSettings.infos.redirectUri': 'Merci de renseigner l\'URI suivante : &bold(https://app.resilio.tech/sso/redirect)',

	'choiceSelector.searchInList': 'Chercher dans la liste',
	'choiceSelector.option': 'Option',
	'choiceSelector.selector': 'Sélecteur',
	'choiceSelector.disabled': 'Désactivé',

	'datasetSelector.company': 'Organisation',
	'datasetSelector.study': 'Étude',
	'datasetSelector.perimeter': 'Périmètre',
	'datasetSelector.all': 'Aggrégé',

	'downSidebar.method': 'Méthode',
	'downSidebar.glossary': 'Glossaire',
	'downSidebar.help': 'Aide',
	'downSidebar.accessibility': 'Accessibilité',
	'downSidebar.ecoDesign': 'Eco-conception',

	'sidebar.kanbanTable': 'Aperçu des tâches',
	'sidebar.home': 'Accueil',
	'sidebar.yourStudies': 'Vos Études',
	'sidebar.manage': 'Gestion',
	'sidebar.inventory': 'Inventaire',
	'sidebar.overview': 'Vue détaillée',
	'sidebar.maturity': 'Maturité',
	'sidebar.validation': 'Validation',
	'sidebar.results': 'Résultats',
	'sidebar.groupby': 'Impacts groupés par équipement',
	'sidebar.settings': 'Paramètres',
	'sidebar.dashboards': 'Tableaux de bord',
	'sidebar.dashboard.evolution': 'Evolution',
	'sidebar.dashboard.comparison': 'Comparaison',
	'sidebar.dashboard.simulation': 'Simulation',
	'sidebar.contactConsultants' : 'Contactez vos consultants',
	'sidebar.explore': 'Explorer',

	'contact.title': 'Contactez vos consultants',
	'contact.consultantName': 'Nom du consultant',
	'contact.email': 'Email du consultant',
	'contact.phoneNo': 'Numéro de téléphone du consultant',

	'alerts.valueSaved': 'Valeurs enregistrées.',

	'error.manage.notAllowed': 'Vous n\'êtes pas autorisé à faire cette action.',
	'error.manage.cantDeleteDatasetWithDefinitive': 'Vous ne pouvez pas supprimer un périmètre qui contient une version définitive de résultat.',
	'error.manage.cantDeleteDatasetWithSample': 'Vous ne pouvez pas supprimer un périmètre qui est utilisé dans un benchmark.',
	'error.manage.studyNotFound': 'L\'étude n\'a pas été trouvée.',
	'error.manage.datasetAlreadyExist': 'Ce nom de périmètre est déjà utilisé dans l\'étude.',
	'error.manage.cannotChangeCompany': 'Vous ne pouvez pas changer l\'organisation de l\'étude.',

	'errorBoundary.errorOccurs': 'Une erreur s\'est produite.',
	'errorBoundary.reloadPage': 'Veuillez recharger la page.',

	'errorModal.title': 'Erreur',
	'errorModal.refresh': 'Veuillez réessayer en rechargeant la page.',
	'errorModal.contact': 'Si le problème persiste, veuillez contacter un administrateur.',
	'errorModal.logout': 'Déconnexion',
	'errorModal.backHome': 'Retour à l\'accueil',

	'errorRenderer.auth': 'Echec d\'authentification',
	'errorRenderer.fetch': 'Echec de récupération des données',
	'errorRenderer.process': 'Le processus s\'est interrompu en raison d\'une erreur. Veuillez contacter un administrateur.',
	'errorRenderer.accessDenied': 'Accès interdit',
	'errorRenderer.accessReconnect': 'Vous ne pouvez pas accéder à ces données. Veuillez vous reconnecter.',
	'errorRenderer.networkIssue': 'Problème de connexion',
	'errorRenderer.networkReconnect': 'Connexion interrompue. Veuillez vous reconnecter.',
	'errorRenderer.userReconnect': 'Vous n\'êtes plus connecté. Veuillez vous reconnecter.',

	'noMobile.warning': 'Attention',
	'noMobile.support': 'Support du format Mobile',
	'noMobile.description': 'Le format Mobile ou de taille de fenêtre réduite n\'est actuellement pas adéquat pour la collecte de données. Cependant, nous étudions les possibilités de rendre l\'application accessible par mobile dans le future.',

	'loading': 'Chargement',
	'somethingWentWrong': 'Quelque chose s\'est mal passé',

	'table.lineRequired': 'Ligne requise',
	'table.comments': 'Commentaires',
	'table.commentsValidation': 'Commentaires consultant.es',
	'table.electricityConsumption': 'Renseignez ici la consommation électrique annuelle de cet équipement en kWh (si vous ne le faites pas, nous utiliserons une valeur par défaut).',
	'table.electricityConsumption.unit': 'kWh/an/unité',
	'table.placeholder.comment': 'Commentaire',
	'table.placeholder.commentValidation': 'Commentaire consultant.es',
	'table.metadata': 'Métadonnées',
	'table.noMetatada': 'Ce type d\'équipement ne contient pas de metadonnées.',
	'table.quality.low': 'Basse',
	'table.quality.medium': 'Moyenne',
	'table.quality.high': 'Haute',
	'table.input.input': 'Champs d\'entrée',
	'table.input.priority': 'Champs prioritaire',
	'table.input.editMode': 'Mode modification',
	'table.input.switchToEditMode': 'Passer en mode modification',

	'tableAssign.button': 'Assigner la ligne à l\'utilisateur',
	'tableAssign.none': 'Aucun',
	'tableAssign.modalTitle': 'Assigner la ligne aux utilisateurs',
	'tableAssign.modalButton': 'Assigner',
	'tableAssign.modalButtonClose': 'Fermer',
	'tableAssign.modalLabel': 'Liste des utilisateurs',
	'tableAssign.modal.userFilter.placeholder': 'Filtrer ou créer un utilisateur',
	'tableAssign.modal.userFilter.label': 'Filtrer ou créer un utilisateur',
	'tableAssign.modal.createLambda.button': 'Ajouter un utilisateur invité',
	'tableAssign.modal.userList.user': 'Utilisateur',
	'tableAssign.modal.userList.notification': 'Notification',
	'tableAssign.modal.noUserList': 'Aucun utilisateur n\'a été trouvé',
	'tableAssign.modal.userFilter.lambda.emailNotCorrespondToCompany': 'L\'email renseigné ne correspond pas aux domaines enregistrés pour cette organisation.',
	'tableAssign.modal.noUserAvailable': 'Aucun collaborateur à assigner n\'a été trouvé.',
	'tableAssign.modal.failToCreate': 'Une erreur est survenue pendant la création de l\'utilisateur.',
	'tableAssign.modal.failToSendNotification': 'Une erreur est survenue pendant l\'envoie de notification.',

	'tableAssign.notification.title': 'Vous avez ajouté un utilisateur invité',
	'tableAssign.notification.emailSent': 'Un email a été envoyé à l\'utilisateur.',

	'domains': 'Domaines',
	'settings': 'Paramètres',
	'equipments': 'Équipements',
	'equipment': 'Équipement',

	'equipment.deprecated.tooltip': 'Notre équipe de R&D a identifié une incohérence dans les données affectant le calcul de l\'empreinte environnementale de l\'équipement. Des efforts sont en cours pour mettre rapidement à jour le facteur d\'impact.',

	'datasetOverview': 'Vue détaillée',
	'datasetOverview.title': 'Vue détaillée du dataset',
	'datasetOverview.temporaryAssigned': 'Envoyer le mail de notification aux utilisateurs ?',
	'datasetOverview.table.title.percent': 'Pourcent',
	'datasetOverview.table.title.name': 'Nom',
	'datasetOverview.table.title.quantity': 'Quantité',
	'datasetOverview.table.title.lifetime': 'Durée de vie',
	'datasetOverview.table.title.quality': 'Qualité',
	'datasetOverview.table.title.comment': 'Commentaire',
	'datasetOverview.table.title.help': 'Aide',
	'datasetOverview.table.title.assign': 'Assigner',
	'datasetOverview.table.noData': 'Aucun équipement n\'a été trouvé pour ce dataset.',
	'datasetOverview.notification': 'Notification',
	'datasetOverview.notificationText': 'Envoyer le mail de notification aux utilisateurs assignés dans dataset ?',
	'datasetOverview.notificationPrevent': 'Voulez vous vraiment quitter la page sans envoyer le(s) mail(s) de notification ?',
	'datasetOverview.notify': 'Envoyer',
	'datasetOverview.notify.leaveMessage': 'Vous avez des utilisateurs non notifiés assignés dans dataset. Voulez-vous vraiment quitter la page ?',
	'datasetOverview.defaultLifetime': 'Durée de vie par défaut',

	'studyWorkflow.studiesWorkflow': 'Déroulement des études',
	'studyWorkflow.studies': 'Etudes',
	'studyWorkflow.noStudy': 'Aucune étude pour le moment...',

	'studyKanban.title': 'Étude &studyName()',
	'studyKanban.priority': 'Priorité',
	'studyKanban.global': 'Global',
	'studyKanban.settings': 'Paramètres',
	'studyKanban.maturity': 'Maturité',
	'studyKanban.control': 'contrôle',
	'studyKanban.copy': 'Copie de Dataset',
	'studyKanban.newDatasetName': 'Nom du nouveau périmètre',
	'studyKanban.newDatasetDescription': 'Description du nouveau périmètre',
	'studyKanban.newDatasetError': 'Une erreur est survenue lors de la création du nouveau périmètre. Veuillez réessayer.',
	'studyKanban.datasetName': 'Nom de Dataset',
	'studyKanban.selectdatasetToCopy': 'Sélectionner un Dataset à copier',
	'studyKanban.emptying': 'Vide',
	'studyKanban.copyMaturity': 'Copier les données de maturité',
	'studyKanban.copyValidation': 'Copier les données de validation',
	'studyKanban.noStudy': 'Aucune étude pour le moment...',
	'studyKanban.noDataset': 'Aucun périmètre pour le moment...',
	'studyKanban.studies': 'Etudes',
	'studyKanban.toStart': 'À démarrer',
	'studyKanban.start': 'Commencer',
	'studyKanban.startTask': 'Commencer la tâche',
	'studyKanban.collect': 'En cours de collecte',
	'studyKanban.validationOngoing': 'Validation en progression',
	'studyKanban.validationComplete': 'Validation complète',
	'studyKanban.continue': 'Continuer',
	'studyKanban.results': 'Résultats',
	'studyKanban.resultsAvailable': 'Résultats disponibles',
	'studyKanban.quickWins': 'Impératifs',
	'studyKanban.others': 'Autres',
	'studyKanban.copyDataset': 'Copier un Dataset',
	'studyKanban.datasetEmpty': 'Dataset vide',
	'studyKanban.errorNameDataset': 'Nom du Dataset incorrect',
	'studyKanban.description': 'Décrivez le périmètre de l\'analyse',
	'studyKanban.datasetCreation.title': 'Ajoutez un périmètre',
	'studyKanban.datasetCreation.description': 'Choisissez la méthode de création de votre périmètre',
	'studyKanban.datasetCreation.fromTemplate': 'À partir d\'un périmètre par défaut',
	'studyKanban.datasetCreation.fromscratch': 'Créer un périmètre de zéro',
	'studyKanban.createFromScratch.title': 'Créer un périmètre de zéro',
	'studyKanban.datasetCreation.duplicate': 'Dupliquer un périmètre',
	'studyKanban.datasetCreation.duplicate.title': 'Dupliquer un périmètre',
	'studyKanban.requestCalculation': 'Demander un nouveau calcul',
	'studyKanban.addDatasetFirst': 'Veuillez ajouter un périmètre avant d\'ajouter une CMDB',

	'template.templates': 'Choisissez un template',
	'template.choiceTitle': 'Créer un périmètre à partir d\'un template',
	'template.choiceName': 'nom du périmètre',
	'template.choiceDescription': 'description du périmètre',

	'studyWorkflow.title': 'Déroulement',

	'settings.title': 'Donnée d\'organisation',
	'settings.field.parameter': 'Paramètre',
	'settings.field.unit': 'Unité',
	'settings.field.value': 'Valeur',
	'settings.field.quality': 'Qualité',
	'settings.field.comment': 'Commentaire',
	'settings.info.parameter': 'Paramètre',
	'settings.info.unit': 'Unité',
	'settings.info.value': 'Valeur',
	'settings.info.quality': 'Qualité',
	'settings.info.comment': 'Commentaire',
	'settings.tip': 'Les paramètres sont utilisés pour valider vos données et pour compléter certains paramètres d\'ACV nécessaires au calcul de l\'empreinte.',

	'inventory': 'inventaire',
	'inventory.title': 'Inventaire',
	'inventory.showMore': 'Afficher plus d\'équipements',
	'inventory.subtitle.defaultLifetime': 'sur &count(count) an&plural(plural)',
	'inventory.subtitle.averageLifetime': 'durée de vie moyenne de &count(count) an&plural(plural)',
	'inventory.tooltip.noRefFlux': 'Indiquez une quantité ainsi que le flux de référence pour obtenir le nombre d\'équipements par utilisateur',
	'inventory.field.sortable': 'Tri',
	'inventory.field.type': 'Type',
	'inventory.field.unit': 'Unité',
	'inventory.field.quantity': 'Quantité',
	'inventory.field.internalLifetime': 'Durée de vie interne (années)',
	'inventory.field.reuseFraction': 'Réutilisation (%)',
	'inventory.field.reuseLifetime': 'Réutilisation (années)',
	'inventory.field.lifetime': 'Durée de vie total (années)',
	'inventory.field.dataQuality': 'Qualité de donnée',
	'inventory.field.assigned': 'Assignés',
	'inventory.field.unassigned': 'Autres',
	'inventory.field.comment': 'Commentaires',
	'inventory.field.electricityConsumption': 'Consommation électrique (kWh)',
	'inventory.info.comment': 'Commentaires',
	'inventory.info.matchingScore': 'Vous avez demandé l\'équipement &requestedName(), la référence la plus proche que nous avons trouvée dans notre base de données est &foundName(). Le score de correspondance est &matchingScore(/5).',
	'inventory.info.matchingScore.requestNewReference': 'Si vous pensez que la référence trouvée n\'est pas correcte, veuillez contacter votre consultant pour demander une nouvelle référence.',
	'inventory.info.help': 'Aide',
	'inventory.info.sortable': 'Tri',
	'inventory.info.type': 'Type',
	'inventory.info.unit': 'Unité',
	'inventory.info.quantity': 'Quantité de matériel informatique (Matériel installé + stock non utilisé)',
	'inventory.info.internalLifetime': 'Durée de vie des équipements au sein de l\'organisation (moyenne)',
	'inventory.info.reuseFraction': 'Pourcentage de réutilisation d\'un type d\'équipement (par exemple dans le cas où cet équipement est loué ou remis à neuf\n' +
		'après la fin de la garantie). Indiquer 0 si l\'équipement n\'est pas réutilisé.',
	'inventory.info.reuseLifetime': 'Durée de vie de l\'équipement pendant sa période de réutilisation (externe à l\'organisation). Indiquez 0 si l\'équipement n\'est pas réutilisé.',
	'inventory.info.lifetime': 'Durée de vie interne + fraction de réutilisation*Durée de vie de réutilisation. Il est calculé automatiquement si la Durée de vie interne, le % de réutilisation et la durée de vie de la réutilisation sont donnés.',
	'inventory.info.dataQuality': 'Qualité de donnée',
	'inventory.info.dataQuality.high':'Qualité haute : donnée fiable (ex : extraite d\'un inventaire récent et mis à jour, d\'une facture...)',
	'inventory.info.dataQuality.medium':'Qualité moyenne : donnée issue d\'hypothèses réalistes (ex : on estime que 1/3 des collaborateurs ont un double écran)',
	'inventory.info.dataQuality.low':'Qualité faible : donnée issue d\'hypothèses peu fiables, ou issue d\'une valeur moyenne de ce benchmark ou d\'un autre périmètre',
	'inventory.info.dataQuality.highShort':'Haute qualité',
	'inventory.info.dataQuality.mediumShort':'Moyenne qualité',
	'inventory.info.dataQuality.lowShort':'Faible qualité',
	'inventory.addEquipment.buttonLabel': 'Ajouter un équipement',
	'inventory.addEquipment.cmdb.buttonLabel': 'Importer une CMDB',
	'inventory.addEquipment.modal.title': 'Ajouter un équipement',
	'inventory.addEquipment.modal.title.block': 'Ajouter un équipement dans ce domaine',
	'inventory.addEquipment.modal.header': 'Ajouter un équipement par',
	'inventory.addEquipment.modal.explore': 'Exploration',
	'inventory.addEquipment.modal.reference': 'Référence',
	'inventory.addEquipment.modal.configuration': 'Configuration',
	'inventory.addEquipment.modal.tooltipCategory': 'Pour le moment, les références produits sont disponibles pour: &break() Ordinateurs portables, serveurs, switchs, stockages, écrans d\'ordinateur, télévisions, ordinateurs de bureau, machines virtuelles. &break() &break() En cas de référence manquante, vous pouvez créer une configuration de ces appareils dans l\'onglet Configuration, ou contacter notre support',
	'inventory.addEquipment.modal.import': 'Import',
	'inventory.addEquipment.modal.search': 'Rechercher une référence produit ou un type d\'équipement',
	'inventory.addEquipment.modal.quantity': 'Quantité',
	'inventory.addEquipment.modal.recap': 'Récap. des équipements',
	'inventory.addEquipment.modal.hddSizeDefault': 'Les tailles par défaut des disques HDD sont de 1To',
	'inventory.addEquipment.modal.explore.loadMore': 'Charger plus d\'équipements...',
	'inventory.searchEquipment': 'Chercher un type d\'équipement',
	'inventory.lockedDescription': 'Vos données sont en cours de validation, ou sont utilisées dans les résultats associés à ce périmètre. La collecte de votre inventaire est donc bloquée. Veuillez contacter l\'équipe de support pour modifier vos données. Vous pouvez débloquer la collecte des données depuis la page de &link(vue détaillée)',
	'inventory.archivedDescription': 'Ce périmètre est archivé. Vous ne pouvez plus le modifier. Si vous souhaitez visualiser les données renseignées, merci de vous rendre sur la &link(vue détaillée)',
	'inventory.filter.tooltip': 'Filtrer les équipements',
	'inventory.filter.label': 'Filtrer',

	'inventory.addEquipment.modal.cmdbAssistant.header': 'Assistant CMDB',
	'inventory.addEquipment.modal.cmdbAssistant.columnTitle': 'Aidez-nous à faire correspondre les différentes champs de votre fichier.',
	'inventory.addEquipment.modal.cmdbAssistant.columnMandatory': 'Ces champs sont obligatoires, veuillez sélectionner les champs correspondants dans votre fichier pour continuer',
	'inventory.addEquipment.modal.cmdbAssistant.columnOptional': 'Ces champs sont optionnels, vous pouvez passer cette étape ou sélectionner les champs correspondants dans votre fichier pour nous aider à calculer l\'impact de vos équipements',
	'inventory.addEquipment.modal.cmdbAssistant.unusedColumn': 'Selon votre sélection, ces colonnes ne seront pas utilisées (elles ne sont pas obligatoires)',
	'inventory.addEquipment.modal.cmdbAssistant.column.manufacturer': 'Fabricant',
	'inventory.addEquipment.modal.cmdbAssistant.column.model': 'Nom du modèle',
	'inventory.addEquipment.modal.cmdbAssistant.column.type': 'Type',
	'inventory.addEquipment.modal.cmdbAssistant.column.perimeter': 'Périmètre',
	'inventory.addEquipment.modal.cmdbAssistant.column.block': 'Block',
	'inventory.addEquipment.modal.cmdbAssistant.column.ramCount': 'Nombre de RAM',
	'inventory.addEquipment.modal.cmdbAssistant.column.ramSize': 'Taille totale de la RAM (GB)',
	'inventory.addEquipment.modal.cmdbAssistant.column.cpuCount': 'Nombre de CPU',
	'inventory.addEquipment.modal.cmdbAssistant.column.cpuName': 'Nom du CPU',
	'inventory.addEquipment.modal.cmdbAssistant.column.gpuCount': 'Nombre de GPU',
	'inventory.addEquipment.modal.cmdbAssistant.column.gpuName': 'Nom du GPU',
	'inventory.addEquipment.modal.cmdbAssistant.column.ssdCount': 'Nombre de SSD',
	'inventory.addEquipment.modal.cmdbAssistant.column.ssdName': 'Nom du SSD',
	'inventory.addEquipment.modal.cmdbAssistant.column.ssdSize': 'Taille totale du SSD (GB)',
	'inventory.addEquipment.modal.cmdbAssistant.column.ssdTechno': 'Technologie du SSD',
	'inventory.addEquipment.modal.cmdbAssistant.column.hddCount': 'Nombre de HDD',
	'inventory.addEquipment.modal.cmdbAssistant.column.portCount': 'Nombre de ports',
	'inventory.addEquipment.modal.cmdbAssistant.column.portCountPerModule': 'Nombre de ports par module',
	'inventory.addEquipment.modal.cmdbAssistant.column.rackUnit': 'Unité de rack',
	'inventory.addEquipment.modal.cmdbAssistant.column.screenSize': 'Taille de l\'écran',
	'inventory.addEquipment.modal.cmdbAssistant.column.screenTechno': 'Technologie de l\'écran',
	'inventory.addEquipment.modal.cmdbAssistant.column.usage': 'Usage',
	'inventory.addEquipment.modal.cmdbAssistant.column.country': 'Pays (Utilisation VM)',
	'inventory.addEquipment.modal.cmdbAssistant.column.modemType': 'Type de modem',
	'inventory.addEquipment.modal.cmdbAssistant.tooltip.manufacturer': 'Le fabricant de l\'équipement',
	'inventory.addEquipment.modal.cmdbAssistant.tooltip.model': 'Le nom du modèle de l\'équipement',
	'inventory.addEquipment.modal.cmdbAssistant.tooltip.type': 'Le type d\'équipement (pour le moment seuls les serveurs et les ordinateurs portables sont supportés)',
	'inventory.addEquipment.modal.cmdbAssistant.tooltip.perimeter': 'Le périmètre où l\'équipement est utilisé',
	'inventory.addEquipment.modal.cmdbAssistant.tooltip.block': 'Le domaine où l\'équipement est utilisé',
	'inventory.addEquipment.modal.cmdbAssistant.validateAll': 'Tout valider',
	'inventory.addEquipment.modal.cmdbAssistant.noMatch': 'Pas de champ correspondant',
	'inventory.addEquipment.modal.cmdbAssistant.matchBlockAndCategory': 'Vous devez sélectionner pour chacune de ces catégories d\'équipements le domaine dans lequel les équipements seront ajoutés',
	'inventory.addEquipment.modal.cmdbAssistant.info.noBlock': 'La sélection de domaine sera effectuée dans l\'assistant',

	'inventory.addEquipment.modal.cmdbAssistant.value.onlyNumerical': 'Les colonnes suivantes doivent contenir uniquement des valeurs numériques, veuillez remplacer les valeurs non numériques:',
	'inventory.addEquipment.modal.cmdbAssistant.value.ramSizeMaxWarning': 'Pour la RAM, la taille maximale autorisée est de 100000 GB (768 GB pour les machines virtuelles)', 

	'inventory.addEquipment.modal.cmdbAssistant.value.unMatch.type': 'Nous avons trouvé des valeurs dans votre CMDB qui ne correspondent pas à nos catégories d\'équipement.',
	'inventory.addEquipment.modal.cmdbAssistant.value.isItemMatching.type': 'Ces valeurs correspondent-elles à la catégorie suivante:',

	'inventory.addEquipment.modal.cmdbAssistant.value.unMatch.perimeter': 'Nous avons trouvé des valeurs dans votre CMDB qui ne correspondent pas à nos périmètres. Vous pouvez soit créer de nouveaux périmètres à partir de ces valeurs, soit les lier à des périmètres existants.',
	'inventory.addEquipment.modal.cmdbAssistant.value.isItemMatching.perimeter': 'Ces valeurs correspondent-elles au périmètre suivant:',

	'inventory.addEquipment.modal.cmdbAssistant.value.unMatch.country': 'Nous avons trouvé des valeurs dans votre CMDB qui ne correspondent pas à nos pays d\'utilisation disponibles.',
	'inventory.addEquipment.modal.cmdbAssistant.value.isItemMatching.country': 'Ces valeurs correspondent-elles au pays suivant:',

	'inventory.addEquipment.modal.cmdbAssistant.value.unMatch.ssdTechno': 'Nous avons trouvé des valeurs dans votre CMDB qui ne correspondent pas à nos technologies SSD.',
	'inventory.addEquipment.modal.cmdbAssistant.value.isItemMatching.ssdTechno': 'Ces valeurs correspondent-elles à la technologie SSD suivante:',

	'inventory.addEquipment.modal.cmdbAssistant.value.unMatch.screenTechno': 'Nous avons trouvé des valeurs dans votre CMDB qui ne correspondent pas à nos technologies d\'écran.',
	'inventory.addEquipment.modal.cmdbAssistant.value.isItemMatching.screenTechno': 'Ces valeurs correspondent-elles à la technologie d\'écran suivante:',

	'inventory.addEquipment.modal.cmdbAssistant.value.thanksForMatch': 'Merci d\'avoir nettoyé et fait correspondre les valeurs de votre CMDB',
	'inventory.addEquipment.modal.cmdbAssistant.value.thanksAlreadyMatch': 'Votre CMDB était déjà conforme, merci !',
	'inventory.addEquipment.modal.cmdbAssistant.value.nowAdd': 'Vous pouvez maintenant l\'ajouter à votre inventaire.',
	'inventory.addEquipment.modal.cmdbAssistant.value.thanksForColumn': 'Merci d\'avoir sélectionné les champs que vous souhaitez utiliser.',
	'inventory.addEquipment.modal.cmdbAssistant.value.nowClean': 'Nous allons maintenant nettoyer et faire correspondre les valeurs de votre CMDB.',
	'inventory.addEquipment.modal.cmdbAssistant.value.warningPreviousStep': 'Faites attention, si vous revenez en arrière, vous retournerez à l\'état initial des colonnes de votre import.',
	'inventory.addEquipment.modal.cmdbAssistant.value.deleteItems': 'Toutes les lignes contenant la valeur &item(item) seront supprimées',
	'inventory.addEquipment.modal.cmdbAssistant.value.replaceItems': 'Toutes les valeurs: &item(item) seront remplacées par: &newValue(replacement)',
	'inventory.addEquipment.modal.cmdbAssistant.value.unmatchInfo': 'Si vous avez besoin de prendre en compte ces éléments mais que nos propositions ne correspondent pas, veuillez contacter un consultant.',
	'inventory.addEquipment.modal.cmdbAssistant.value.undo': 'Annuler le changement',
	'inventory.addEquipment.modal.cmdbAssistant.value.selector.placeholder': 'Sélectionner un champ à faire correspondre',
	'inventory.addEquipment.modal.cmdbAssistant.value.createPerimeter': 'Créer un nouveau périmètre',
	'inventory.addEquipment.modal.cmdbAssistant.value.mapToPerimeter': 'Lier à &perimeter(perimeter)',
	'inventory.addEquipment.modal.cmdbAssistant.block.selector.placeholder': 'Sélectionner un domaine',
	'inventory.addEquipment.modal.cmdbAssistant.initialValueTruncated': 'Le nom est trop long, il sera tronqué à 30 caractères',

	'inventory.specification': 'Choisissez une configuration dans la liste ci-dessous',
	'inventory.addEquipment.modal.selectDatabase': 'Sélectionnez une ou plusieurs bases de données',
	'inventory.addEquipment.modal.selectedDatabase': 'Base de donnée(s) sélectionnée(s)',
	'inventory.addEquipment.modal.changeDatabase': 'Changer de base de donnée',
	'inventory.addEquipment.modal.closeButton': 'Fermer',
	'inventory.addEquipment.modal.addButton': 'Ajouter',
	'inventory.addEquipment.modal.name': 'Nom de l\'équipement',
	'inventory.addEquipment.modal.selectAType': 'Ou sélectionner une catégorie d\'équipement',
	'inventory.addEquipment.modal.categories': 'Catégories d\'équipement',
	'inventory.addEquipment.modal.equipments': 'Équipements',
	'inventory.addEquipment.modal.error.addEquipments': 'Une erreur est survenue lors de l\'ajout des équipements',
	'inventory.addEquipment.modal.selectAEquipment': 'Sélectionner un équipement',
	'inventory.addEquipment.modal.searchEquipment': 'Chercher un équipement',
	'inventory.addEquipment.modal.searchEquipmentTitle': 'Chercher un équipement',
	'inventory.addEquipment.modal.selectADomain': 'Sélectionner un domaine',
	'inventory.addEquipment.modal.youAreInADomain': 'Vous êtes dans le domaine &domain()',
	'inventory.addEquipment.modal.selectASpec': 'Sélectionner une configuration',
	'inventory.addEquipment.modal.applyToEntireStudy': 'Appliquer cette modification à toute l\'étude',
	'inventory.addEquipment.modal.recapOfSelectedEquipment': 'Récapitulatif des équipements sélectionnés',
	'inventory.addEquipment.modal.backToSelection': 'Retour à la sélection',
	'inventory.addEquipment.modal.selectedCategory': 'Catégorie sélectionnée',
	'inventory.addEquipment.modal.selectCMDB': 'Sélectionnez ou déposez votre fichier de CMDB',

	'inventory.addEquipment.modal.addNewConf': 'Ajouter une nouvelle configuration:',
	'inventory.addEquipment.modal.unknown': 'Inconnu',
	'inventory.addEquipment.modal.serverType': 'Type de serveur',
	'inventory.addEquipment.modal.cpu': 'CPU',
	'inventory.addEquipment.modal.ram': 'RAM',
	'inventory.addEquipment.modal.port': 'Port',
	'inventory.addEquipment.modal.portCount': 'Nombre de ports',
	'inventory.addEquipment.modal.rackUnit': 'Unité de rack',
	'inventory.addEquipment.modal.modemType': 'Type de modem',
	'inventory.addEquipment.modal.storage_system': 'Stockage',
	'inventory.addEquipment.modal.type': 'Type',
	'inventory.addEquipment.modal.technology': 'Technologie',
	'inventory.addEquipment.modal.casing': 'Boîtier',
	'inventory.addEquipment.modal.gpu': 'GPU',
	'inventory.addEquipment.modal.integrateGpu': 'GPU intégré',
	'inventory.addEquipment.modal.screen': 'Écran',
	'inventory.addEquipment.modal.usage': 'Usage',
	'inventory.addEquipment.modal.geography': 'Géographie',
	'inventory.addEquipment.modal.countryOfUse': 'Pays d\'utilisation',
	'inventory.addEquipment.modal.selectACountry': 'Sélectionner un pays',
	'inventory.addEquipment.modal.selectAModemType': 'Sélectionner un type de modem',
	'inventory.addEquipment.modal.usage.hour': 'Nous considérons la durée d\'utilisation de la VM sur un an. Par défaut, si elle est allumée et utilisée toute l\'année il faut donc compter : 365j*24h soit 8760 heures',
	'inventory.addEquipment.modal.screenType': 'Type d\'écran',
	'inventory.addEquipment.modal.screenSize': 'Taille d\'écran',
	'inventory.addEquipment.modal.deltaTHour': 'Temps d\'utilisation',
	'inventory.addEquipment.modal.size': 'Taille',
	'inventory.addEquipment.modal.addName': 'Nom',
	'inventory.addEquipment.modal.product': 'Produit',
	'inventory.addEquipment.modal.supplier': 'Fournisseur',
	'inventory.addEquipment.modal.model': 'Modèle',
	'inventory.addEquipment.modal.laptop': 'Ordinateur portable',
	'inventory.addEquipment.modal.television': 'Télévision',
	'inventory.addEquipment.modal.computer_monitor': 'Écran d\'ordinateur',
	'inventory.addEquipment.modal.virtual_machine': 'Machine virtuelle',
	'inventory.addEquipment.modal.desktop': 'Ordinateur de bureau',
	'inventory.addEquipment.modal.smartphone': 'Téléphone',
	'inventory.addEquipment.modal.tablet': 'Tablette',
	'inventory.addEquipment.modal.modem': 'Modem',
	'inventory.addEquipment.modal.rack_switch-router': 'Rack Switch/Rack Router',
	'inventory.addEquipment.modal.modular_switch-router': 'Switch/Router modulaire',
	'inventory.addEquipment.modal.server': 'Serveur (rack, lame, tour)',
	'inventory.addEquipment.modal.rack_server': 'Serveur Rack',
	'inventory.addEquipment.modal.blade_server': 'Serveur Lame',
	'inventory.addEquipment.modal.tower_server': 'Serveur Tour',
	'inventory.addEquipment.modal.mix': 'Mixte',
	'inventory.addEquipment.modal.number': 'Nombre',
	'inventory.addEquipment.modal.cpuName': 'Nom du CPU',
	'inventory.addEquipment.modal.searchCpuName': 'Rechercher un nom de CPU ...',

	'inventory.addEquipment.modal.error.noCpu': 'Veuillez ajouter au moins un CPU',
	'inventory.addEquipment.modal.error.noGpu': 'Veuillez ajouter au moins un GPU',
	'inventory.addEquipment.modal.error.noStorage': 'Veuillez ajouter au moins un disque dur',
	'inventory.addEquipment.modal.error.noStorageSize': 'Veuillez spécifier la capacité en GB de tous les disques durs',
	'inventory.addEquipment.modal.error.noStorageType': 'Veuillez spécifier le type de tous les disques durs',
	'inventory.addEquipment.modal.error.noStorageCasing': 'Veuillez spécifier la taille de boîtier de tous les disques durs',
	'inventory.addEquipment.modal.error.noRams': 'Veuillez ajouter au moins une RAM',
	'inventory.addEquipment.modal.error.noRamSize': 'Veuillez spécifier la capacité en GB de toutes les RAMs',
	'inventory.addEquipment.modal.error.noScreen': 'Veuillez ajouter un écran',
	'inventory.addEquipment.modal.error.noScreenSize': 'Veuillez spécifier la taille de l\'écran',
	'inventory.addEquipment.modal.error.noScreenType': 'Veuillez spécifier le type de l\'écran',
	'inventory.addEquipment.modal.error.noBlock': 'Veuillez sélectionner un domaine',
	'inventory.addEquipment.modal.error.noServerType': 'Veuillez sélectionner un type de serveur',
	'inventory.addEquipment.modal.error.noCountry': 'Veuillez sélectionner un pays',
	'inventory.addEquipment.modal.error.noModemType': 'Veuillez sélectionner un type de modem',

	'inventory.addEquipment.modal.explore.noEquipmentFound': 'Équipement introuvable. &break() &link(Contactez-nous) ou utilisez une configuration générique ou manuelle pour votre équipement.',

	'inventory.addEquipment.modal.downloadCmdbTemplate': 'Télécharger le template de fichier CMDB',
	'inventory.addEquipment.modal.cmdb.error.unValidSheets': 'Certaines de vos feuilles ne peuvent pas être importées: &sheets(sheets)',
	'inventory.addEquipment.modal.cmdb.error.onSheet': 'Le fichier doit avoir exactement une seule feuille',
	'inventory.addEquipment.modal.cmdb.error.notFound': 'Feuille introuvable',
	'inventory.addEquipment.modal.cmdb.error.empty': 'Feuille vide, ou non conforme',
	'inventory.addEquipment.modal.cmdb.error.noData': 'Aucune donnée extraite, veuillez vérifier votre fichier ou la ligne fournie.',
	'inventory.addEquipment.modal.cmdb.error.header': 'La feuille doit contenir un header contenant les noms des colonnes',
	'inventory.addEquipment.modal.cmdb.error.missing': 'La feuille doit contenir au moins les colonnes suivantes : Constructeur, Modèle et Type',
	'inventory.addEquipment.modal.cmdb.error.columns': 'La feuille doit au moins contenir 14 colonnes',
	'inventory.addEquipment.modal.cmdb.error.wrongColumns': 'La colonne &return() contient des valeurs vides, veuillez corriger votre fichier.',
	'inventory.addEquipment.modal.cmdb.button': 'Charger votre fichier',
	'inventory.addEquipment.modal.cmdb.error.format': 'Le fichier doit être au format .csv ou .xlsx',
	'inventory.addEquipment.modal.cmdb.error.query': 'Une erreur est survenue lors de la récupération des données de votre fichier. Veuillez contacter notre support.',
	'inventory.addEquipment.modal.cmdb.error.block': 'Veuillez sélectionner un domaine',
	'inventory.addEquipment.modal.cmdb.error.noStudyIdFound': 'Une erreur est survenue lors de la récupération de certaines données de votre étude, si cette erreur persiste veuillez contacter: support@resilio-solutions.com',
	'inventory.addEquipment.modal.cmdb.error.name': 'Veuillez entrer un nom pour la CMDB',
	'inventory.addEquipment.modal.cmdb.error.alreadyExists': 'Cette CMDB existe déjà',
	'inventory.addEquipment.modal.cmdb.error.noCmdbAdded': 'Aucune CMDB n\'a été ajoutée car aucun équipement n\'a été trouvé',
	'inventory.addEquipment.modal.cmdb.error.toStudyAndMultipleDataset': 'Vous devez choisir entre ajouter la CMDB à l\'étude ou à plusieurs périmètres',
	'inventory.addEquipment.modal.cmdb.error.sheetIndexOutOfRange': 'Le numéro de la feuille est en dehors de la plage de feuilles disponibles',
	'inventory.addEquipment.modal.cmdb.error.collectClosed': 'La collecte d\'inventaire du périmètre est fermée',

	'inventory.error.cmdb.modal.delete': 'Une erreur est survenue lors de la suppression de la CMDB',
	'inventory.addEquipment.modal.cmdb.downloadTemplate': 'Télécharger le template de fichier CMDB',
	'inventory.addEquipment.modal.cmdb.cannotReplaceEquipments': 'Vous ne pouvez pas remplacer des équipements qui sont utilisé pour des résultats',
	'inventory.addEquipment.modal.cmdb.replacement': 'Choisissez des équipements à remplacer',
	'inventory.addEquipment.modal.cmdb.chooseType': 'Sélectionnez le type de cmdb que vous souhaitez importer',
	'inventory.addEquipment.modal.cmdb.chooseStart': 'Indiquez le numéro de la ligne header de votre CMDB',
	'inventory.addEquipment.modal.cmdb.multipleDataset.info': 'Votre CMDB doit comporter une colonne \'perimeter\' contenant le nom du périmètre auquel l\'équipement est assigné.',
	'inventory.addEquipment.modal.cmdb.importLoading': 'Importation de votre CMDB ... &break()Cela peut prendre quelques minutes.&break() Vous pouvez continuer à naviguer sur l\'application et vous serez notifié lorsque l\'import sera terminé. &break()Veuillez &bold(ne pas fermer votre navigateur).',
	'inventory.addEquipment.modal.cmdb.canUseApp': 'Vous pouvez continuer à utiliser l\'application, vous serez notifié lorsque l\'import sera terminé.',
	'inventory.addEquipment.modal.cmdb.importSuccess.title': 'Félicitations !&return() Votre CMDB à été importée avec succès !',
	'inventory.addEquipment.modal.cmdb.importSuccess.message': 'Vous pouvez dès maintenant la visualiser dans l\'inventaire.',
	'inventory.addEquipment.modal.cmdb.importError.title': 'Une erreur est survenue lors de l\'import de votre CMDB',
	'inventory.addEquipment.modal.cmdb.importError.message': 'Vos données ont été partiellement importées. Pour une raison inconnue (pour l\'instant), certaines lignes de votre fichier de données n\'ont pas été importées. L\'équipe de support de Resilio a été informée, afin de comprendre le problème et trouver une solution dans les plus brefs délais. Votre consultant ou l\'équipe de support vous répondra dans les 48h. Si vous avez besoin d\'une assistance immédiate, veuillez contacter &support()',

	'inventory.editEquipment.modal.electricityConsumption': 'Renseigner ici la consommation électrique (une valeur par défaut sera prise si vide).',
	'inventory.editEquipment.modal.input.electricityConsumption': 'Entrer la consommation',
	'inventory.editEquipment.modal.allocationFactor': 'Renseigner ici un facteur d\'allocation affectée à la quantité. &break() Attention à la cohérence de vos périmètres !',
	'inventory.editEquipment.modal.input.allocationFactor': 'Allocation (de 0 à 1)',
	'inventory.editEquipment.modal.quantity': 'Quantité',

	'inventory.cmdb.field.name': 'Nom',
	'inventory.cmdb.field.ramCount': 'Nombre de RAM',
	'inventory.cmdb.field.ramSize': 'Taille de RAM (GB)',
	'inventory.cmdb.field.cpuCount': 'Nombre de CPU',
	'inventory.cmdb.field.cpuModel': 'Modèle de CPU',
	'inventory.cmdb.field.gpuCount': 'Nombre de GPU',
	'inventory.cmdb.field.gpuModel': 'Modèle de GPU',
	'inventory.cmdb.field.ssdCount': 'Nombre de SSD',
	'inventory.cmdb.field.ssdSize': 'Taille de SSD (GB)',
	'inventory.cmdb.field.hddCount': 'Nombre de HDD',
	'inventory.cmdb.field.screenSize': 'Taille de l\'écran',
	'inventory.cmdb.field.screenType': 'Type d\'écran',
	'inventory.cmdb.details': 'détails',
	'inventory.cmdb.viewDetails': 'Voir les détails de la CMDB',
	'inventory.cmdb.modal.delete': 'Supprimer cette CMDB',
	'inventory.cmdb.modal.warningDelete': 'Vous êtes sur le point de supprimer cette CMDB. Cette action est irréversible. Voulez-vous continuer ?',
	'inventory.cmdb.modal.allocationFactor': 'Facteur d\'allocation (entre 0 et 1)',
	'inventory.cmdb.modal.allocationFactor.tooltip': 'Le facteur d\'allocation est utilisé pour pondérer l\'impact de vos équipements dans le calcul. Par exemple, si vous avez 10 serveurs dans votre centre de données et que vous estimez que 50% de ces ressources sont utilisés pour votre unité fonctionelle, vous pouvez renseigner un facteur d\'allocation de 0.5. Cela signifie que seul la moitié de l\'impact de vos serveurs au total sera attribué à votre périmètre.',
	'inventory.cmdb.modal.lifeTime': 'Durée de vie',
	'inventory.cmdb.modal.editLifetime': 'Modifier la durée de vie de la cmdb',
	'inventory.cmdb.modal.settings.warning' : 'Attention, en cliquant sur valider, les changements seront appliqués sur tous les équipements de cette CMDB (la modification précédente au niveau d\'un équipement sera effacée pour le paramètre). Voulez-vous poursuivre ?',
	'inventory.cmdb.modal.settings.defaultLifetimeWarning': 'Ces équipments contiennent une durée de vie par défaut. Les changements de durée de vie ne seront pas appliqués sur ces équipements.',
	'inventory.cmdb.modal.settings.error': 'Une erreur est survenue lors de la modification de la durée de vie de la CMDB',
	'inventory.cmdb.modal.label.sheetSelector': 'Sélectionnez la feuille de votre fichier',

	'inventory.cmdb.notification.partiallyImportedRows': 'Les lignes suivantes n\'ont pas pu être importées:',

	'inventory.detailedInformations': 'Informations détaillées de l\'inventaire',
	'inventory.equipmentInfos.modal.title': 'Informations Équipements',

	'inventory.error.data': 'Un problème est survenu avec votre inventaire, essayez de recharger la page ou contactez votre administrateur si le problème persiste.',
	'inventory.error.resilioDbUnavailable': 'Le service Resilio Database est indisponible pour le moment, vous pouvez seulement accéder aux données locales.',

	'data.table.equipmentEdit.propagate': 'Propager dans l\'étude',
	'data.table.equipmentEdit.name': 'Nom',
	'data.table.equipmentEdit.specification': 'Spécification',
	'data.table.equipmentEdit.deleteError': 'Il n\'est pas possible de supprimer cet équipement car il est utilisé dans les résultats de l\'étude.',

	'maturity': 'maturité',
	'maturity.title': 'Maturité',
	'maturity.head.name': 'Libellé de la bonne pratique',
	'maturity.head.grade': 'Niveau de maturité',
	'maturity.head.grade.tooltip': 'Auto-évaluation sur l\'échelle CMMI (note de 1 à 5)',
	'maturity.head.kpi': 'Icp',
	'maturity.head.kpi.tooltip': 'Indicateur Clé de Performance',
	'maturity.head.priority': 'Priorité',
	'maturity.head.priority.tooltip': 'Dépend de l\'impact environnemental qu\'à une bonne pratique. Plus la bonne pratique aura un "bénéfice" environnemental, plus la priorité sera haute',
	'maturity.head.difficulty': 'Difficulté',
	'maturity.head.difficulty.tooltip': 'Dépend de la facilité d\'implémentation d\'une bonne pratique. Plus la bonne pratique sera facile à implémenter, plus la difficulté sera basse',
	'maturity.head.follow': 'Suivi',
	'maturity.head.follow.tooltip': 'Permet de marquer les bonnes pratiques encore à compléter ou que vous souhaitez ré-évaluer',

	'maturity.tip': `&bold(Astuce :) Cliquez sur le processus pour obtenir plus d'informations sur celui-ci !
	
&bold(Initial) : Le point de départ – Le projet est nouveau ou non documenté. Il est mené par un individu, dont le succès dépend de ses compétences propres.
&bold(Reproductible) : La bonne pratique commence à être maîtrisée et a été initiée sur une partie du périmètre. Les méthodes mises en place permettent un déploiement général.
&bold(Défini) :  Le projet est standardisé. Les procédures sont écrites et disponibles. Tous les acteurs en ont une bonne compréhension.
&bold(Maîtrisé) : Un indicateur mesure la performance d'un point de vue quantitatif et/ou qualitatif. Les écarts sont analysés.
&bold(Optimisé) : Le projet fait parti d’une démarche d’amélioration en continue.
&bold(Non applicable) : Ce champ peut être sélectionné si cette bonne pratique ne s'applique pas à votre organisation (exemple : nous n'avons pas de centre de données). Si la bonne pratique n'est pas implémentée actuellement dans votre organisation mais pourrait être implémentée dans le futur, veuillez sélectionner le premier niveau de maturité "Initial".

&flagTip() Cliquer sur ce drapeau afin de suivre certaines bonnes pratiques. Vous pouvez ensuite les filtrer en cliquant sur l'en tête de colonne "Suivi".`,
	'maturity.description': 'Description',
	'maturity.priority.priority': 'Prioritaire',
	'maturity.priority.recommended': 'Conseillé',
	'maturity.priority.notPriority': 'Non prioritaire',
	'maturity.difficulty.easy': 'Facile',
	'maturity.difficulty.standard': 'Standard',
	'maturity.difficulty.hard': 'Difficile',
	'maturity.comment': 'Commentaire',
	'maturity.kpi': 'ICP',
	'maturity.kpiValue': 'Valeur ICP',
	'maturity.errorOccurs': 'Une erreur est survenue, votre donnée n\'a pas été enregistrée.',
	'maturity.error.data': 'Un problème est survenu avec votre questionnaire de maturité, essayez de recharger la page ou contactez votre administrateur si le problème persiste.',
	'maturity.notification.gradeMastered.title': '🎉🎉🎉&return() Félicitations !',
	'maturity.notification.gradeMastered.message': 'Vous avez entré une note égale ou supérieure à 4. Vous maitrisez au moins cette bonne pratique ! Pour valider les résultats, veuillez entrer un ICP.',

	'results.downloadExcel': 'Télécharger (Excel)',
	'results.exportResults': 'Exporter',
	'results.proceedExport': 'Procéder à l\'export',
	'results.downloadAllGraphs': 'Télécharger tous les graphs',
	'result.ExportModalTitle': 'Choisissez ce que vous souhaitez télécharger sous Excel',
	'result.ExportModalChoice': 'Choisissez ce que vous souhaitez télécharger (requis)',
	'result.fr': 'Français',
	'result.en': 'Anglais',
	'results': 'résultats',
	'results.info': 'Les résultats présentés dans cette section représentent l\'empreinte et/ou la maturité du périmètre &perimeter(), sur une année. Les résultats sont obtenus à l\'aide d\'une approche fondée sur les stocks et sont des résultats d\'ACV à mid-point ou axés sur les problèmes.',
	'results.computeInfo': '(Impacts calculés sur 1 an)',
	'results.title': 'Résultats',
	'results.results': 'Résultats',
	'results.result': 'Résultat',
	'results.collectionDatasets': 'Périmètres dans cette collection',
	'results.launcherSelector': 'Collections',
	'results.nothingHere': 'Aucune donnée actuellement',
	'results.noBenchmark': 'Aucun échantillon',
	'results.notReady': 'Les résultats ne sont pas prêts. Veuillez terminer l\'inventaire.',
	'results.archivedResults': 'Attention ! Vous consultez des archives. Ces résultats ne correspondent probablement pas à votre inventaire actuel.',
	'results.error': 'Erreur',
	'results.indicator': 'Indicateur',
	'results.year': 'Année',
	'results.step': 'Etape',
	'results.index': 'Index',
	'results.value': 'Valeur',
	'results.valuePBCI': 'PBCI',
	'results.min': 'Min.',
	'results.mean': 'Moy.',
	'results.max': 'Max.',
	'results.unit': 'Unité',
	'results.block': 'Domaine',
	'results.domain': 'Domaine',
	'results.scope': 'Portée',
	'results.grade': 'Niveau',
	'results.category': 'Catégorie',
	'results.lifetime': 'Durée de vie',
	'results.averageLifetime': 'Durée de vie moyenne',
	'results.quantity': 'Quantité',
	'results.averageQuantity': 'Quantité moyenne',
	'results.quantityByUsers': 'Quantité par utilisateur',
	'results.averageQuantityByUsers': 'Quantité moyenne par utilisateur',
	'results.byUsers': 'Par utilisateur',
	'results.unit.tons': 'tonnes',
	'results.carousel.displayPerUser': 'Afficher le résultat par utilisateur',
	'results.carousel.displayTotalFootprint': 'Afficher le résultat de l\'empreinte totale',
	'results.carousel.lessThanMin': 'Cette valeur est inférieure à la valeur minimale de l\'échantillon',
	'results.carousel.moreThanMax': 'Cette valeur est supérieure à la valeur maximale de l\'échantillon',
	'results.lifeCycleStep.EOL': 'Fin de vie',
	'results.lifeCycleStep.BLD': 'Fabrication',
	'results.lifeCycleStep.USE': 'Utilisation',
	'results.lifeCycleStep.DIS': 'Distribution',
	'results.electricityConsumption.title': 'Consommation électrique (kWh)',
	'results.lifeCycleStep': 'Etape de cycle de vie',
	'results.TotalFootprint': 'Empreinte totale',
	'results.title.NormalizedFootprintPerLCStep': 'Contribution des étapes du cycle de vie à l’empreinte',
	'results.title.NormalizedFootprintPerIndicator': 'Contribution des catégories d\'impact à l\'empreinte',
	'results.title.NormalizedFootprintPerBlock': 'Empreinte par domaine normalisée',
	'results.title.NormalizedFootprintDetailed': 'Empreinte normalisée détaillée',
	'results.title.MaturityComparisonByDomain': 'Comparaison de maturité par domaine',
	'results.title.Maturity': 'Note de maturité par domaine',
	'results.title.InventoryOverview': 'Vue d\'ensemble de l\'inventaire',
	'results.maturity.score': 'Score de maturité',
	'results.maturity.averageScore': 'Score moyen',
	'results.maturity.initial': 'Initial',
	'results.maturity.reproducible': 'Reproductible',
	'results.maturity.defined': 'Défini',
	'results.maturity.mastered': 'Maitrisé',
	'results.maturity.optimized': 'Optimisé',
	'results.maturity.notApplicable': 'Non applicable',
	'results.title.FootprintPerLCStep': 'Impact par étape de cycle de vie',
	'results.title.FootprintPerFunctionalUnit': 'Empreinte par utilisateur',
	'results.title.FootprintPerBlock': 'Impact par domaine',
	'results.title.FootprintDetailed': 'Empreinte détaillée',
	'results.title.FootprintEquivalence': 'Empreinte équivalence',
	'results.title.EnvironmentalBudget': 'Budget environnemental',
	'results.title.ElectricityConsumption': 'Consommation électrique',
	'results.normalizedResults.info': 'Les résultats normalisés ne tiennent pas compte du filtre des indicateurs environnementaux que vous auriez pu appliquer. Les résultats normalisés correspondent toujours au calcul global pondérant tous les indicateurs.',
	'results.equipmentVersions': 'Versions d\'équipement',
	'results.lcaParametersVersion': 'Versions de paramètres d\'ACV',
	'results.domain.graphs': 'Graphiques',
	'results.domain.tables': 'Données',
	'results.title.FluxSummary': 'Résumé des flux',
	'results.fluxSummary.build_impact_raw_materials': 'Impact sur les matières premières',
	'results.fluxSummary.weee': 'DEEE',
	'results.fluxSummary.paper_waste': 'Déchets papier',
	'results.fluxSummary.electricity': 'Électricité',
	'results.fluxSummary.number_of_users': 'Nombre d\'utilisateurs',
	'results.fluxSummary.unit.build_impact_raw_materials': 'MIPS',
	'results.fluxSummary.unit.weee': 'tonnes',
	'results.fluxSummary.unit.paper_waste': 'tonnes',
	'results.fluxSummary.unit.electricity': 'kWh',
	'results.fluxSummary.unit.number_of_users': ' ',
	'results.title.Objectives': 'Objectifs',
	'results.objectives.BusinessAsUsual': 'Tendance actuelle',
	'results.objectives.Ideal': 'Idéal',
	'results.title.LowestQualityData': 'Lignes de données à améliorer',
	'results.title.QualityScore': 'Score de qualité',
	'results.title.QualityOverview': 'Vue d\'ensemble de la qualité',
	'results.qualityScore.evaluated': 'Nombre de lignes évaluées',
	'results.perFunctionalUnit': 'Par unité fonctionnelle',
	'results.total': 'Total',
	'results.qualityWeight': 'Poids de qualité',
	'results.percentage': 'Pourcentage',
	'results.equipment': 'Equipement',
	'results.equipmentShortNameAndSpec': 'Nom de l\'équipement & spécification',
	'results.version': 'Version',
	'results.dataReference': 'Référence de donnée',
	'results.tooltip.dataReference': 'Cet ID vous permets de retrouver les différents paramètres utilisés pour calculer les impacts de cet équipement. Cliquer sur le lien fournit pour les visualiser.',
	'results.redirect.id': 'Visualiser mon équipement sur ResilioDb',
	'results.dateVersion': 'Dernière modification',
	'results.dbSource': 'Base de données',
	'results.qualityScore': 'Score de qualité',
	'results.qualityScore.toImprove': 'À améliorer',
	'results.quality.none': 'Pas renseignée',
	'results.quality.high': 'Haute',
	'results.quality.medium': 'Moyenne',
	'results.quality.low': 'Basse',
	'results.quality': 'Qualité',
	'results.title.MaturityScore': 'Score de maturité',
	'results.resultValue': 'Valeur',
	'results.title.Impacts': 'Impact groupé par catégories d\'équipement',
	'results.title.ImpactScore': 'Score d\'impact',
	'results.title.PlanetaryBoundaries': 'Limites planétaires',
	'results.title.BGES': 'Protocole GHG - Scopes',
	'results.eqEarth': 'éq. Planète',
	'results.setToPrivate': 'Rendre privé',
	'results.setToPublic': 'Rendre public',
	'results.setToDefinitive': 'Rendre définitif',
	'results.setToNotDefinitive': 'Rendre non-définitif',
	'results.deleteAll': 'Tout supprimer',
	'results.setColors': 'Définir les couleurs',
	'results.errorNotReady': 'Désolé, il semble que vos résultats ne soient pas encore prêt. Pensez à revenir plus tard, ou contactez votre consultant si vous pensez qu\'ils devraient être disponibles.',
	'results.errorHappens': 'Désolé, une erreur est survenue lors de la création du graphique. Veuillez essayer de rafraîchir la page ou contacter votre consultant si l\'erreur persiste.',
	'results.errorQuery': 'Désolé, il semble qu\'un problème soit survenu avec ces résultats... Essayez de rafraîchir la page, et contactez l\'équipe de Resilio si le problème persiste.',
	'results.nextCarouselPicture': 'Image suivante',
	'results.previousCarouselPicture': 'Image précédente',
	'results.switchToGraphOrTable': 'Visualiser en graphe ou en tableau',
	'results.compare': 'Comparer',
	'results.noComparison': 'Aucune comparaison',
	'results.compareWithSample': 'Comparer avec un benchmark',
	'results.compareWithCustom': 'Comparer avec un ensemble',
	'results.selectSample': 'Sélectionnez un benchmark',
	'results.compareResults': 'Comparer un résultat',
	'results.removeComparison': 'Retirer la comparaison',
	'results.search': 'Rechercher',
	'results.chooseASample': 'Choisissez un benchmark',
	'results.chooseASampleDesc': 'Pour obtenir des résultats de comparaison, vous devez choisir un benchmark en cliquant sur une icône de balance en haut de la page pour tous les résultats ou sur chaque carte de résultats.',
	'results.comparison_impossible': 'Impossible de comparer',
	'result.comparedTo': 'Comparé à ce benchmark: &compare(nom)',
	'results.warning': 'Nous n\'avons pas pu comparer ce résultat avec le benchmark &compare(name)',
	'results.equivalence.indicator.title': 'Équivalence &indicator()',
	'results.noIndicator': 'Aucun indicateur',
	'results.noIndicatorSelected': 'Aucun indicateur sélectionné, pour obtenir ce résultat, veuillez sélectionner un indicateur dans le filtre.',
	'results.allDomains': 'Tous les domaines',

	'results.alert.equivalence': 'équivalence',
	'results.alert.quality': 'qualité',
	'results.alert.maturity': 'maturité',

	'results.setDefinitive': 'Résultat définitif',
	'results.notification.setDefinitive': '⚠️ Ces résultats deviendront définitifs et vous ne pourrez plus les modifier. Cela s\'appliquera également aux résultats normalisés et de maturité. &return() &return() &red(Vous pouvez également supprimer toutes les collections de résultats précédemment calculées.) En confirmant, vous n\'aurez accès qu\'à ce résultat définitif. Vous pouvez également supprimer certains résultats en naviguant dans vos collections de résultats et en les supprimant individuellement. Vous éviterez ainsi de stocker des données inutilisées ! ',

	'results.notification.setToNotDefinitive': 'Êtes-vous sûr de ne plus vouloir définir cet ensemble de résultats comme définitif ?',
	'results.notification.deleteResult': 'Vous allez supprimer cette version des résultats. Cela concernera les résultats normalisés et de maturités. &return() Voulez-vous continuer ?',
	'results.notification.loadDeleteResults': 'Cela peut prendre plusieurs minutes. S\'il vous plaît, ne fermez pas votre navigateur.',

	'results.error.needComparison': 'Ce résultat nécessite une comparaison pour être affiché.',
	'results.error.cantDeleteDefinitive': 'Vous ne pouvez pas supprimer une version de résultat définitive. Veuillez la rendre non-définitive avant de la supprimer.',
	'results.error.exportFailed': 'Une erreur est survenue lors de l\'export des résultats.',

	'result.all': 'Tous les résultats',
	'result.lcaresults': 'Résultats ACV',
	'result.normalizedresults': 'Résultats Normalisés',
	'result.corporateReporting': 'Rapports d\'entreprise',
	'result.projections': 'Projections',
	'result.data': 'Donnée',
	'result.flux': 'Flux',
	'result.maturity': 'Maturité',
	'result.maturity.score': 'Score',

	'result.scopes': 'Scopes',
	'result.bgesUnitId': 'Unité BEGES',
	'result.ademeCarbonFootprint': 'Catégories BEGES',
	'result.ghgProtocolCategory': 'Catégorie Protocole GHG',
	'result.ghg': 'GWP (kg eq. CO2) - par catégorie',
	'result.bges.total': 'GWP (kg CO2 eq.) - par scope',

	'filterIndicatorModal.title': 'Filtrer les indicateurs',
	'filterIndicatorModal.filter': 'Sélectionner les indicateurs que vous souhaitez afficher',

	'groupby.title': 'Impacts groupés par équipement',
	'groupby.error': 'Une erreur est survenue lors de la récupération des données. Veuillez essayez de rafraîchir la page ou contacter votre consultant.',
	'groupby.normalized': 'Normalisé',
	'groupby.equipement.name': 'Nom de l\'équipement',
	'groupby.equipement.unit': 'Unité',
	'groupby.equipement.category': 'Catégorie de l\'équipement',
	'groupby.equipement.block': 'Domaine de l\'équipement',
	'groupby.select.companies': 'Sélectionnez des organisations :',
	'groupby.select.studies': 'Sélectionnez des études :',
	'groupby.select.datasets': 'Sélectionnez des périmètres :',
	'groupby.request.log': 'Récupérer les données',
	'groupby.grouped.category': 'Groupé par catégorie',
	'groupby.grouped.equipment': 'Groupé par équipement',
	'groupby.grouped.allDomain': 'Tous les domaines',
	'groupby.percentByLifeCycleStep': 'Résultat relatif par étape de cycle de vie',
	'groupby.percentByEquipment': 'Résultat relatif par type d\'équipement',
	'groupby.tooltip.lifeCycleStep': 'Résultat relatif par étape de cycle de vie',
	'groupby.tooltip.equipment': 'Résultat relatif par type ou catégorie d\'équipement',
	'groupby.tipSelectedDatasets': 'Périmètres sélectionnés',
	'groupby.equipment.name': 'Nom & spécification',
	'groupby.equipment.category': 'Catégorie',
	'groupby.equipment.block': 'Domaine',
	'groupby.equipment.unit': 'Unité',
	'groupby.equipment.total': 'Total',

	'info.block.preSample': 'Ces résultats sont comparés à(aux) ce(ces) benchmark(s) : ',
	'info.block.validationPreSample': 'Ces valeurs sont comparés à ce benchmark : ',
	'info.block.validationSampleDown': 'Si la valeur moyenne - 2 * σ ≤ votre valeur < moyenne du benchmark ⇨ ',
	'info.block.validationSampleUp': 'Si moyenne du benchmark ≤ votre valeur < moyenne + 2 * σ ⇨ ',
	'info.block.validationSampleDoubleDown': 'Si votre valeur < moyenne du benchmark - 2 * σ ⇨ ',
	'info.block.validationSampleDoubleUp': 'Si moyenne du benchmark + 2 * σ ≤ votre valeur ⇨ ',
	'info.block.noSample': 'Ces résultats ne sont comparés à aucun benchmark',
	'info.block.indicatorMax': 'Résultat ≥ valeur maximale de ce benchmark',
	'info.block.indicatorUp': 'Résultat > valeur moyenne de ce benchmark',
	'info.block.indicatorDown': 'Résultat < valeur moyenne de ce benchmark',
	'info.block.indicatorMin': 'Résultat ≤ valeur minimale de ce benchmark ',

	'validation.sigma.black': 'Valeur dans les 0,2% des valeurs de l\'échantillon',
	'validation.sigma.green': 'Valeur dans les 68,2% des valeurs de ce benchmark',
	'validation.sigma.orange': 'Valeur dans les 27,2% des valeurs de ce benchmark',
	'validation.sigma.red': 'Valeur dans les 4,4% des valeurs de ce benchmark',
	'validation.title': 'Validation',
	'validation.nothingHere': 'Aucune donnée pour le moment',
	'validation.element': 'Elément',
	'validation.unit': 'Unité',
	'validation.valid': 'Valide',
	'validation.quantity': 'Quantité',
	'validation.stayOnValidation': 'Rester sur la validation',
	'validation.goToResults': 'Aller aux résultats',
	'validation.validationQuantity': 'Validation - Quantité',
	'validation.validationLifetime': 'Validation - Durée de vie (années)',
	'validation.quality': 'Qualité',
	'validation.qualityTooltip': 'Qualité',
	'validation.sampleQuantity': 'Benchmark - Quantité',
	'validation.sampleLifetime': 'Benchmark - Durée de vie (années)',
	'validation.lifetimeTotal': 'Durée de vie totale (années)',
	'validation.internalLifetime': 'Durée de vie interne (années)',
	'validation.reuseFraction': 'Réutilisation (%)',
	'validation.reuseLifetime': 'Réutilisation (années)',
	'validation.noQuantity': 'Pas de quantité',
	'validation.noLifetime': 'Pas de durée de vie',
	'validation.noQuantityAndLifetime': 'Pas de quantité ni de durée de vie',
	'validation.info.element': 'Elément',
	'validation.info.unit': 'Unité',
	'validation.info.valid': 'Valide',
	'validation.info.quantity': 'Quantité',
	'validation.info.validationQuantity': 'Validation - Quantité',
	'validation.info.validationLifetime': 'Validation - Durée de vie (années)',
	'validation.info.sample': 'Benchmark',
	'validation.info.sampleQuantity': 'Échantillon - Quantité - Sigma - (# périmètres inclus) - Qualité moyenne',
	'validation.info.sampleLifetime': 'Échantillon - Durée de vie (années)',
	'validation.info.lifetimeTotal': 'Durée de vie totale (années)',
	'validation.info.internalLifetime': 'Durée de vie interne (années)',
	'validation.info.reuseFraction': 'Réutilisation (%)',
	'validation.info.reuseLifetime': 'Réutilisation (années)',
	'validation.unlockCollect': 'Autoriser la collecte',
	'validation.lockCollect': 'Bloquer la collecte',
	'validation.calculate': 'Calculer',
	'validation.calculationRequest': 'Demander un calcul',
	'validation.calculationRequest.text': 'En cliquant sur "Valider", nos consultant.es seront notifiés et lanceront les calculs dès que possible. Vous pourrez retrouver les résultats dans la section Résultats.',
	'validation.calculationRequest.message': 'Vous pouvez ajouter un message à votre demande de calcul.',
	'validation.calculateAndReset': 'Réinitialiser les résultats et calculer',
	'validation.calculationImpactsInconsistent': 'Les impacts pour le calcul ne sont pas cohérents',
	'validation.calculationResetWarning': 'Attention, cette action va réinitialiser les résultats et impacts actuels et les recalculer. Voulez-vous continuer ?',
	'validation.archive': 'Archiver',
	'validation.archiveSuccess': 'Ce benchmark a été archivé avec succès',
	'validation.filterUnvalidated': 'Cacher les équipements validés',
	'validation.unFilterUnvalidated': 'Montrer tous les équipements',
	'validation.calculationNotPossible': 'Calcul impossible',
	'validation.calculationExplanation': 'Bloquez la collecte et complétez la validation pour pouvoir calculer les résulats.',
	'validation.errorOccurs': 'Une erreur est survenue',
	'validation.calculationCheck': 'Quelques vérifications avant de lancer les calculs',
	'validation.calculationLaunched': 'Calculs lancés',
	'validation.calculationSuccess': 'Calculs terminés avec succès',
	'validation.calculationErrorOnSample': 'Erreur lors du calcul du benchmark. Veuillez contacter votre consultant ou votre administrateur.',
	'validation.calculationAlert': 'Votre périmètre ne contient pas les informations nécessaires pour le calcul de vos résultats de &resultTypes(resultTypes). Les résultats relatifs ont donc été déselectionnés automatiquement',
	'validation.ok': 'Ok',
	'validation.selectResultList': 'Sélectionner une liste de résultats',
	'validation.selectResultTypes': 'Sélectionner les types de résultats',
	'validation.selectStudy': 'Calculer pour toute l\'étude',
	'validation.selectStudyDatasets': 'Pour l\'étude, calculer les périmètres sélectionnés',
	'validation.selectSample': 'Sélectionner un benchmark',
	'validation.removeSample': 'Retirer le benchmark',
	'validation.missingTypes': 'Résultats manquants pour ce benchmark',
	'validation.missingType.explanation': 'Les résultats suivants sont manquants pour ce benchmark. Il seront calculé, mais ne seront pas comparés au benchmark.',
	'validation.missingType.explanationContact': 'Pour obtenir la comparaison avec ce benchmark, merci de contactez votre Consultant.',
	'validation.result.mandatory': 'Résultat nécessitant obligatoirement une comparaison et ne pouvant être calculés',
	'validation.resultType.used': 'Résultat utilisé lors de la comparaison',
	'validation.resultType.useless': 'Résultat n\'étant pas utilisé lors de la comparaison',
	'validation.missingTypes.info1': 'Tous les résultats ne pouvant pas être calculés sans comparaison seront ignorés.',
	'validation.missingTypes.info2': 'Tous les résultats utilisant une comparaison seront calculés sans celle-ci.',
	'validation.missingTypes.process': 'Continuer malgré tout ?',
	'validation.defaultLifetime': 'Durée de vie par défaut',
	'validation.archiveResults': 'Archiver les résultats',
	'validation.inventoryExtraEdit': 'Modifier les paramètres d\'ACV',
	'validation.inventoryExtraView': 'Voir les paramètres d\'ACV',
	'validation.inventoryExtraEditSuccess': 'Paramètre d\'ACV modifiés avec succès',
	'validation.inventoryExtraEdit.error': 'Une erreur est survenue lors de la modification de vos paramètres d\'ACV',
	'validation.inventoryExtraEdit.referenceFluxError': 'Votre flux de référence doit être supérieur a 0',
	'validation.inventoryExtraEdit.referenceFlux': 'Valeur de dimensionnement',
	'validation.inventoryExtraEdit.referenceFlux.desc': 'La valeur de dimensionnement désigne la quantité de produits analysés et/ou de consommables utilisés, nécessaires pour remplir l\'unité fonctionnelle. Pour une ACV de systèmes d\'information, la valeur de dimensionnement correspond au nombre d\'utilisateurs et utilisatrices du SI (en général le nombre d\'employés + prestataires). Pour une ACV de services numériques classique du type "Utiliser pendant 1h un service de visioconférence", il vaut généralement 1 puisque l\'unité fonctionnelle est déjà normalisée, dans notre exemple à 1h. Cependant, pour une UF "utiliser un service de visioconférence pendant une journée", la valeur de dimensionnement pourrait être "2h" si on considère une utilisation de 2h de visioconférence dans une journée.',
	'validation.inventoryExtraEdit.energyMixes': 'Mix électrique',
	'validation.inventoryExtraEdit.indicatorsAndLCS': 'Indicateurs et étape de cycle de vie',
	'validation.inventoryExtraEdit.factorSet': 'Version des facteurs d\'empreinte',
	'validation.inventoryExtraEdit.indicatorAllSelected': 'Par défaut, tous les indicateurs et étapes de cycle de vie sont sélectionnés et utilisés pour le calcul, vous pouvez cliquer sur le verrou pour faire une nouvelle sélection.',
	'validation.inventoryExtraEdit.indicatorSelectionCaution': 'Attention: Si les indicateurs et étapes de cycle de vie ne sont pas tous sélectionnés, la comparaison des résultats normalisés avec les benchmarks pourrait ne pas être possible.',
	'validation.indicators': 'Indicateurs',
	'validation.lifeCycleSteps': 'Etapes de durée de vie',
	'validation.energyMix': 'Mixes électriques',
	'validation.energyMix.lastVersion.alwaysSelected': 'Pour l\'instant, la dernière version disponible des mix électriques de chaque domaine est utilisée pour vos calculs. Cette option sera désactivée automatiquement si vous sélectionnez ultérieurement une version spécifique pour un domaine.',
	'validation.energyMix.specificVersionSelected': 'Attention, pour le moment des versions spécifiques de mix électriques sont sélectionnées manuellement. En activant cette option, la dernière version de mix électrique de chaque domaine sera automatiquement utilisée pour vos calculs.',
	'validation.energyMix.lastVersion.autoSelectLast': 'Mettre à jour automatiquement les mix électriques vers la dernière version disponible',
	'validation.referenceFlux': 'Flux de référence',
	'validation.factorSet': 'Version de facteurs d\'empreinte',
	'validation.factorSet.equipmentType': 'Type d\'équipement',
	'validation.factorSet.factorSet': 'Version de facteur d\'empreinte',
	'validation.factorSet.lastVersion': 'Dernière version',
	'validation.factorSet.lastVersion.option': 'La dernière version de cette équipement sera utilisée pour le calcul, actuellement la dernière version est la version &version(version)',
	'validation.factorSet.specificVersionSelected': 'Attention, pour le moment des versions spécifiques de facteurs d\'impact sont sélectionnées manuellement. En activant cette option, la dernière version des facteurs d\'impact de chaque item d\'inventaire sera automatiquement utilisée pour vos calculs.',
	'validation.factorSet.lastVersion.alwaysSelected': 'Pour l\'instant, la dernière version disponible des facteurs d\'impact de chaque item d\'inventaire est utilisée pour vos calculs. Cette option sera désactivée automatiquement si vous sélectionnez ultérieurement une version spécifique pour un item d\'inventaire.',
	'validation.factorSet.lastVersion.autoSelectLast': 'Mettre à jour automatiquement les facteurs d\'impact vers la dernière version disponible',
	'validation.filterEnergyMix': 'Filtrer par mix électrique',
	'validation.sniffingAuto': 'Auto-détection des indicateurs et des étapes de durée de vie disponibles',
	'validation.archiveResultsDescription': 'Les résultats de ce périmètre seront archivés et ne pourront plus être visible. Voulez-vous continuez ?',
	'validation.archiveResultsDone': 'Les résultats ont été archivés',
	'validation.archiveResultsError': 'Une erreur est survenue lors de l\'archivage des résultats',
	'validation.archiveResultsNoInfo': 'Aucune information supplémentaire',
	'validation.unlockValidation': 'Déverrouiller la validation',
	'validation.lockValidation': 'Vérrouillez la validation',
	'validation.cancelButtonModalValidation': 'Annuler',
	'validation.goToRefFlux': 'Modifier la valeur de dimensionnement',
	'validation.sample': 'Échantillon',
	'validation.compare': 'Comparer',
	'validation.unlockButtonModalValidation': 'Déverrouillez',
	'validation.warningUnlockValidation': 'Êtes-vous sûr de vouloir débloquer la validation des données ?\n\nSi une modification est apportée à la quantité ou à la durée de vie totale validée,\n\nles résultats calculés précédemment seront automatiquement archivés.',
	'validation.unlockTitle': 'Déverouillez la validation',
	'validation.average.sigma': 'Ecart à la moyenne = ',
	'validation.noPermToLockValidation': 'Vous n\'avez pas la permission de modifier le verrouillage de la validation',
	'validation.warning': 'Attention',
	'validation.warningNoReferenceFlux': 'Confirmez-vous que la valeur de dimensionnement associée à votre périmètre est de 1 ? &break() Si ce n\'est pas le cas, vous pouvez modifier cette valeur dans "Modifier les paramètres de l\'ACV" > "Valeur de dimensionnement".',
	'validation.energyMix.block': 'Domaine',
	'validation.energyMix.energyMix': 'Mix électrique',
	'validation.energyMix.select': 'Sélectionner un mix électrique',
	'validation.energyMix.selectFactorSet': 'Sélectionner une version de facteur de mix électrique',
	'validation.energyMix.lastFactorSet': 'Dernière version',
	'validation.error.data': 'Une erreur est survenue lors de la récupération des données. Veuillez essayez de rafraîchir la page ou contacter votre consultant.',
	'validation.empty': 'Il n\'y a rien à afficher par ici !',
	'validation.addSampleToCompare': 'Ajouter un benchmark pour faire la comparaison',
	'validation.usedInComparison': 'Ce résultat sera comparé à ce benchmark si ce dernier a été sélectionné',
	'validation.exportInventory': 'Exporter l\'inventaire',
	'validation.notification.definitiveResult.collect': 'Vous ne pouvez plus procéder à la collecte de votre inventaire car votre périmètre contient une version définitive de résultat. Vous pouvez définir ce résultat comme non-définitif depuis la page Résultat. Souhaitez-vous vous y rendre ?',
	'validation.notification.definitiveResult.validation': 'Vous ne pouvez plus procéder à la validation de votre inventaire car votre périmètre contient une version définitive de résultat. Vous pouvez définir ce résultat comme non-définitif depuis la page Résultat. Souhaitez-vous vous y rendre ?',
	'validation.notification.definitiveResult.compute': 'Vous ne pouvez plus procéder au calcul car votre périmètre contient une version définitive de résultat. Vous pouvez définir ce résultat comme non-définitif depuis la page Résultat. Souhaitez-vous vous y rendre ?',

	'checkError.datasetNotReady': 'Calcul impossible pour &dataset(dataset)',
	'checkError.dataset.noEquipments': '"&dataset(dataset)" n\'a pas d\'équipements',
	'checkError.dataset.equipment.quantity': 'La quantité de l\'équipement &equipment(equipment) est nulle',
	'checkError.dataset.equipment.noValidatedLifetime': 'La durée de vie de l\'équipement &equipment(equipment) n\'est pas validée',
	'checkError.dataset.equipment.validationLifetimeNotPositive': 'La durée de vie de l\'équipement &equipment(equipment) est négative',
	'checkError.dataset.noInventoryExtra': '"&dataset(dataset)" n\'a pas d\'inventaire extra',
	'checkError.dataset.noBlocks': '"&dataset(dataset)" n\'a pas de blocs',
	'checkError.dataset.noEnergyMixes': '"&dataset(dataset)" n\'a pas de mix énergétique',
	'checkError.dataset.noReferenceFlux': '"&dataset(dataset)" n\'a pas de flux de référence',
	'checkError.dataset.referenceFluxNotPositive': 'Le flux de référence de &dataset(dataset) est négatif',
	'checkError.dataset.noLifeCycleSteps': '"&dataset(dataset)" n\'a pas d\'étapes de cycle de vie',
	'checkError.dataset.noIndicators': '"&dataset(dataset)" n\'a pas d\'indicateurs',
	'checkError.dataset.noUseLifeCycleStep': '"&dataset(dataset)" n\'a pas d\'étape de cycle de vie d\'utilisation',
	'checkError.impactFactor.null': 'Le facteur d\'impact v&version(version) pour &equipment(equipment) avec &indicator(indicator) et &life_cycle_step(life_cycle_step) est nul',
	'checkError.impactFactor.notFound': 'Le facteur d\'impact v&version(version) pour &equipment(equipment) avec &indicator(indicator) et &life_cycle_step(life_cycle_step) non trouvé',
	'checkError.impactFactor.noFactorSet': 'L\'équipement &equipment(equipment) n\'a aucun jeu de facteurs d\'impact',
	'checkError.impactFactorElecUse.null': 'Le facteur de consommation électrique v&version(version) pour &equipment(equipment) est nul',
	'checkError.impactFactorElecUse.notExist': 'Le facteur de consommation électrique v&version(version) pour &equipment(equipment) n\'existe pas',
	'checkError.impactFactorEnergyMix.null': 'Le facteur d\'impact de mix énergétique &energyMix(energyMix) pour &indicator(indicator) est nul',
	'checkError.energyMix.noFactorSet': 'Le mix énergétique &energyMix(energyMix) n\'a aucun set de facteur d\'impact',
	'checkError.impactFactorEnergyMix.notExist': 'Le facteur d\'impact de &energyMix(energyMix) pour &indicator(indicator) n\'existe pas',
	'checkError.sampleMissingResults': 'Certains résultats de ce benchmark &sample(sampleName) sont manquants.',
	'checkError.sampleMissingResultsContactAdmin': 'Certains résultats pour le Benchmarking sont manquants. Contactez votre consultant ou votre administrateur pour résoudre ce problème.',
	'checkError.block.noTranslation': 'Un bloc n\'a pas de traduction, contactez votre administrateur',
	'checkError.equipment.noTranslation': 'L\'équipement #&equipment(equipmentID) n\'a pas de traduction, contactez votre administrateur',
	'checkError.dataset.noMaturity': 'Le périmètre &dataset(dataset) n\'a pas de maturité',
	'checkError.dataset.noMaturityGrade': 'La maturité n\'est pas totalement remplie dans le périmètre &dataset(dataset)',
	'checkError.indicatorAndLifeCycleStepNotConsistent': 'Les indicateurs et les étapes de cycle de vie ne sont pas cohérents entre vos périmètres',
	'checkError.factorSetNotConsistent': 'Les versions de facteurs d\'empreinte ne sont pas cohérentes entre vos périmètres',

	'codePage.card.assignedAccess': 'Accès assigné',
	'codePage.card.button.sendMeCode': 'Envoyer un code',
	'codePage.card.codeInformation': 'Le code sera envoyé à votre adresse e-mail. Il ne peut être utilisé qu\'une fois et expire après 15 minutes.',
	'codePage.card.code.label': 'Code',
	'codePage.card.code.input.placeholder': 'Entrez votre code',
	'codePage.card.button.submit': 'Valider',
	'codePage.card.codeSent': 'Code envoyé',
	'codePage.card.codeNotSent': 'Code non envoyé, une erreur est survenue, veuillez réessayer plus tard.',
	'codePage.card.error.noToken': 'Aucun token fourni. Essayez de recharger la page.',
	'codePage.card.codeVerified': 'Code vérifié',
	'codePage.card.codeNotVerified': 'Code invalide. Essayez à nouveau. Notez que les tentatives de connexion sont limitées',

	'errorImage.header': 'Une erreur est survenue',

	'lambdaAssignedPage.forbidden.title': 'Accès interdit',
	'lambdaAssignedPage.forbidden.description': 'Vous n\'avez pas accès à cette page',

	'forbidden.title': 'Accès interdit',
	'forbidden.description': 'Vous n\'avez pas accès à cette page',

	'export.errorNoButtonClicked': 'Veuillez sélectionner un bouton avant de valider',
	'export.notReady': 'Cette fonctionnalité n\'est pas encore prête ! Nous la développons... Soyez patient !',

	'export.results': 'Résultats',
	'export.dataCollected': 'Données collectées',

	'export.results.rawResults': 'Résultats (excel)',
	'export.results.graphicalResults': 'Résultats graphiques (png)',
	'export.results.executiveSummary': 'Résumé (pdf)',
	'export.results.certificate': 'Certificat',
	'export.results.exportImpacts': 'Impacts (excel)',
	'export.results.warnDefinitiveResult': 'Vous êtes sur le point d\'exporter vos résultats. Si cet export est destiné à un client, nous vous recommandons de définir cette version des résultats comme définitive. Cet état vous empêchera de modifier l\'inventaire, de calculer de nouveaux résultats ou de supprimer ces résultats actuels. Vous pouvez toujours désactiver cet état à partir de la page des résultats. Voulez-vous définir cette version des résultats comme définitive ?',
	'export.errorExport': 'Une erreur est survenue lors de l\'export. Veuillez essayez de rafraîchir la page ou contacter votre consultant',

	'export.dataCollected.inventory': 'Inventaire (excel)',
	'export.dataCollected.maturity': 'Maturité (excel)',
	'export.dataCollected.settings': 'Paramètres (excel)',
	'export.dataCollected.warning': 'Attention: L\'export de l\'inventaire, de la maturité ou des paramètres correspond à la dernière version des données complétées.',

	'export.inventoryTooltip': 'Fichier Excel avec vos données Inventaire',
	'export.maturityTooltip': 'Fichier Excel avec vos données Maturité',
	'export.settingsTooltip': 'Fichier Excel avec vos données Paramètres',
	'export.rawResultsTooltip': 'Fichier Excel avec vos données Résultats',
	'export.graphicalResultsTooltip': 'Collection de graphiques',
	'export.executiveSummaryTooltip': 'Export LaTeX',
	'export.certificateTooltip': 'Certificat de 2 pages PDF',
	'export.exportImpactsTooltip': 'Fichier Excel avec vos données Impacts',
	'export.results.exportEnvironmentalAssessment': 'Evaluation environnementale (pdf)',
	'export.exportEnvironmentalAssessmentTooltip': 'PDF avec vos données Evaluation environnementale',

	'message.contactConsultant': 'Nous avons prévenu l\'équipe de Resilio. Vous pouvez aussi contacter votre consultant.',

	'dashboard.title': 'Tableau de bord',
	'dashboard.notImplemented': 'Cette fonctionnalité arrive bientôt !',
	'dashboard.overview.title': 'Vue d\'ensemble',
	'dashboard.comparison.title': 'Comparaison',
	'dashboard.simulation.title': 'Simulation',
	'dashboard.evolution.title': 'Evolution',
	'dashboard.create.table.button.title': 'Créer un tableau',
	'dashboard.create.table.button.desc': 'Votre tableau personnalisé pour suivre vos résultats',
	'dashboard.acv.button.title': 'ACV',
	'dashboard.acv.button.desc': 'Tous vos résultats de votre étude d\'impact',
	'dashboard.evolution.button.title': 'Evolution',
	'dashboard.evolution.button.desc': 'Visualisez l\'évolution de votre impact',
	'dashboard.comparison.button.title': 'Comparaison',
	'dashboard.comparison.button.desc': 'Comparez votre impact sur différents périmètres',
	'dashboard.simulation.button.title': 'Simulation',
	'dashboard.simulation.button.desc': 'Simulez le gains d\'impact de votre plan d\'actions',
	'dashboard.savedOn': 'Sauvegardé au &date(date)',
	'dashboard.maturity.notCorrectlyFilled': 'L\'inventaire de maturité n\'est pas complet. Le résultat de maturité ne sera donc pas affiché.',

	'dashboard.results.title': 'Résultats existants',
	'dashboard.feature.to.be.implemented': 'Cette fonctionnalité sera bientôt disponible !',

	'dashboard.modal.acv.choose.a.company': 'Choisissez une organisation',
	'dashboard.modal.acv.choose.a.study': 'Choisissez une étude',
	'dashboard.modal.acv.choose.a.dataset': 'Choisissez un périmètre',

	'dashboard.modal.choose.a.study.and.a.dataset': 'Choisissez une étude et un périmètre',
	'dashboard.modal.searchbar.navigation.info': 'Rechercher',
	'dashboard.modal.choose.functionalUnit': 'Sélectionner une unité fonctionnelle',
	'dashboard.evolution.alert.benchmark.not.usable': 'Le benchmark sélectionné ne peut pas être utilisé pour ce dashboard d\'évolution',
	'dashboard.evolution.benchmark.not.usable': 'Le benchmark sélectionné ne peut pas être utilisé pour ce dashboard d\'évolution car les années ne correspondent pas aux périmètres sélectionnés',
	'dashboard.evolution.modal.dataset': 'Périmètre',
	'dashboard.evolution.modal.study': 'Etude',
	'dashboard.evolution.modal.company': 'Organisation',
	'dashboard.evolution.modal.years': 'Années',
	'dashboard.evolution.modal.use': 'Nous utilisons le nom des études et des périmètres pour calculer l\'évolution de vos périmètres et études dans le temps.',
	'dashboard.evolution.modal.datasets.multiple': 'Suite à votre sélection, le graphique affichera :',
	'dashboard.evolution.modal.datasets.unique': 'Suite à votre sélection, nous n\'avons pas détecté au moins un flux de données qui puisse être comparé dans le temps. Veuillez vous assurer que le nom de l\'étude et des périmètres sont les mêmes pour que nous puissions détecter l\'évolution automatique de votre périmètre. Voici les périmètres sélectionnés :',
	'dashboard.evolution.modal.datasets.unique.support': 'Si vous ne savez pas comment grouper vos périmètres, contactez votre consultant.e ou notre équipe de support à &support(support@resilio.tech).',
	'dashboard.evolution.modal.incorrect': 'Si le regroupement est incorrect, veuillez vérifier que les noms des études et des périmètres correspondent ou contactez notre équipe de support à &support(support@resilio-solutions.com).',

	'dashboard.title.globalComparison': 'Comparaison globale',
	'dashboard.title.globalSimulation': 'Simulation globale des gains',
	'dashboard.title.domainsComparison': 'Comparaison par domaines',
	'dashboard.title.domainsSimulation': 'Simulation des gains par domaines',
	'dashboard.title.maturityDomainsComparison': 'Comparaison par domaines de maturité',
	'dashboard.title.maturityDomainsEvolution': 'Evolution par domaines de maturité',
	'dashboard.title.indicatorsComparison': 'Comparaison par indicateurs',
	'dashboard.title.indicatorsSimulation': 'Simulation par indicateurs',
	'dashboard.title.equipmentsComparison': 'Comparaison par équipements',
	'dashboard.title.equipmentsEvolution': 'Evolution par équipements',
	'dashboard.title.equipmentsSimulation': 'Simulation par équipements',
	'dashboard.title.perimetersEvolution': 'Evolution des périmètres',
	'dashboard.title.domainsEvolution': 'Evolution des domaines d\'un périmètre',

	'dashboard.select.globalFootprint': 'Empreinte globale',
	'dashboard.select.footprintPerUser': 'Empreinte par utilisateur',
	'dashboard.select.indicator.normalized': 'Empreinte normalisée',
	'dashboard.select.allIndicators': 'Tous les indicateurs',
	'dashboard.select.allIndicatorsGrouped': 'Tous les indicateurs normalisés - PBCI',
	'dashboard.select.allDomains': 'Tous les domaines',
	'dashboard.select.allDomainsAverage': 'Moyenne de tous les domaines',
	'dashboard.select.allScopes': 'Tous les scopes',
	'dashboard.select.allDatasets': 'Tous les périmètres',
	'dashboard.select.allLifeCycleSteps': 'Toutes les étapes de cycle de vie',
	'dashboard.maturity.grade': 'Note de maturité',
	'dashboard.select.cumulativeNone': 'Non cumulé',
	'dashboard.select.cumulativeToBefore': 'Cumulé aux scénarios précédents',
	'dashboard.select.cumulativeToBefore.warning': 'Vos simulations peuvent être cumulées s\'il n\'y a pas de corrélation entre les différents scénarios, ou en d\'autres termes si vous n\'avez pas modifié les mêmes lignes d\'équipement dans les différents scénarios. Si tel est le cas, vous pouvez additionner les gains ou pertes liés à vos différents scénarios, et ainsi en déduire les gains ou pertes totaux suite à l\'implémentation de toutes vos simulations.',
	'dashboard.select.cumulativeToBefore.description': 'En activant l\'option "cumulé", vos simulations seront additionnées les unes aux autres. Veuillez sélectionner cette option uniquement s\'il n\'y a pas de corrélation entre vos différentes simulations.',
	'dashboard.select.cumulativeToBefore.table.description': 'Les pourcentages affichés correspondent au gain ou perte cumulés sur chacun des scénarios. L\'impact total est calculé par rapport à la référence.',

	'dashboard.action.benchmark.add': 'Ajouter un benchmark',
	'dashboard.action.benchmark.remove': 'Retirer le benchmark',
	'dashboard.action.objective.add': 'Ajouter un objectif',
	'dashboard.action.objective.remove': 'Retirer l\'objectif',
	'dashboard.action.graph': 'Graphique',
	'dashboard.action.table': 'Tableau',
	'dashboard.action.histogram.show': 'Afficher histogramme',
	'dashboard.action.histogram.hide': 'Cacher histogramme',
	'dashboard.action.pin': 'Épingler',

	'dashboard.objective': 'Objectif',
	'dashboard.modal.objective': 'Objectif',
	'dashboard.modal.objective.header': 'Ajouter un objectif',
	'dashboard.objective.percent': 'Pourcentage',
	'dashboard.objective.year': 'Année',

	'dashboard.modal.benchmark.header': 'Ajouter un benchmark',
	'dashboard.benchmark': 'Benchmark',
	'dashboard.benchmark.select': 'Sélectionnez un benchmark',
	'dashboard.benchmark.calculate': 'Patientez pendant que nous calculons les résultats du benchmark',

	'dashboard.select.noDataset': 'Aucune référence sélectionnée',
	'dashboard.select.for.ref': 'pour réf.',
	'dashboard.select.category': 'Par Catégories d\'équipement',
	'dashboard.select.equipmentType': 'Par Équipement',
	'dashboard.select.quantity': 'Quantité',
	'dashboard.select.lifetime': 'Durée de vie',
	'dashboard.select.noRelative': 'Global',
	'dashboard.select.userRelative': 'Par utilisateur',
	'dashboard.select.custom.indicators': 'Sélectionnez des indicateurs',

	'dashboard.alert.usedIndicatorsAndLifeCycleSteps': 'Ne seront retenus pour les calculs de comparaison que les indicateurs environnementaux et étapes de cycle de vie en commun entre tous les périmètres sélectionnés.',
	'dashboard.alert.functionalUnit': 'Seules les études avec la même unité fonctionnelle seront comparées.',
	'dashboard.comparison.saveButton': 'Sauvegarder cette vue de résultats',
	'dashboard.comparison.deleteButton': 'Supprimer cette vue de résultats',
	'dashboard.comparison.deleteModal': 'Supprimer la vue de résultats',
	'dashboard.comparison.new': 'Nouvelle comparaison',
	'dashboard.simulation.new': 'Nouvelle simulation',
	'dashboard.evolution.new': 'Nouvelle évolution',
	'dashboard.globalSimulation.objective': 'Objectif',
	'dashboard.modal.dataset.tooltip.noAllImpacts': 'Ce périmètre ne contient pas tous les impacts pour la comparaison',
	'dashboard.modal.dataset.tooltip.noResults': 'Ce périmètre ne contient pas de résultats pour la comparaison',
	'dashboard.equipment.select.a.reference': 'Sélectionnez une référence',
	'dashboard.noDataset': 'Aucun périmètre sélectionné.',
	'dashboard.indicators': 'Indicateurs',
	'dashboard.lifeCycleSteps': 'Etapes de cycle de vie',
	'dashboard.modal.benchmark.wait': 'Veuillez patienter pendant que nous récupérons les données du benchmark.',
	'dashboard.equipments.alert': 'Les données marquées d\'un \'NA\' n\'apparaissaient pas dans l\'inventaire du périmètre. Les données marquées d\'un 0 indiquent une quantité de 0 inscrite dans l\'inventaire',
	'dashboard.equipments.alert.category': 'Les équipements sont affichés par catégorie. Les catégories sont les mêmes que les en-têtes résumant la quantité et la durée de vie de vos équipements dans l\'inventaire.',

	'dashboard.tableCreationModal.header': 'Créer un tableau',

	'dashboard.myDashboardList.title': 'Mes tableaux de bords',
	'dashboard.sharedDashboardList.title': 'Tableaux partagés avec moi',
	'dashboard.overview.table.edit': 'Editer',
	'dashboard.overview.table.share': 'Partager avec un collaborateur',
	'dashboard.overview.table.add': 'Ajouter',
	'dashboard.overview.table.addCard.text': 'Ajouter une carte de texte',
	'dashboard.overview.table.addCard.text.desc': 'Ajouter une carte de texte à votre tableau',

	'dashboard.overview.table.editModal.header': 'Modifier le tableau',
	'dashboard.overview.table.deleteModal.header': 'Supprimer le tableau',
	'dashboard.overview.table.card.deleteModal.header': 'Supprimer la carte',
	'dashboard.overview.table.card.text.markdownExplanation': 'Vous pouvez modifier le texte de la carte en utilisant le markdown. Pour plus d\'informations, veuillez consulter la documentation de markdown.',

	'dashboardPinModal.header': 'Épingler un tableau',
	'dashboardPinModal.name': 'Nom de la carte',
	'dashboardPinModal.success': 'Le tableau a été épinglé avec succès',
	'dashboardPinModal.success.deleted': 'Le tableau a été supprimé avec succès',
	'dashboardPinModal.newTable': 'Nouveau tableau',

	'dashboard.card.move.up': 'Monter',
	'dashboard.card.move.down': 'Descendre',

	'dashboard.card.modify': 'Modifier une carte',
	'dashboard.card.selected.dataset': 'Périmètre sélectionné',
	'dashboard.card.selected.domain': 'Domaine sélectionné',
	'dashboard.card.selected.scope': 'Scope sélectionné',
	'dashboard.card.selected.cumulative': 'Cumulé',
	'dashboard.card.selected.footprintType': 'Type d\'empreinte sélectionné',
	'dashboard.card.selected.indicator': 'Indicateur sélectionné',
	'dashboard.card.selected.lifeCycleStep': 'Etape de cycle de vie sélectionnée',
	'dashboard.card.selected.equipment.valueType': 'Type de valeur sélectionné',
	'dashboard.card.selected.equipment.showAs': 'Afficher',
	'dashboard.card.selected.equipment.relativity': 'Relativité',

	'dashboard.name.of.view': 'Nom de la vue',
	'dashboard.dashboard.saved.title': 'Tableau sauvegardé',
	'dashboard.comparison': 'Comparaison',
	'dashboard.evolution': 'Evolution',
	'dashboard.simulation': 'Simulation',

	'dashboard.modal.back': 'Retour',
	'dashboard.modal.choose.a.reference.dataset': 'Choisissez un périmètre de référence',
	'dashboard.modal.reference.dataset': 'Périmètre de référence',
	'dashboard.modal.simulation.add.a.perimeter': 'Ajouter un ou plusieurs périmètres comme simulation',

	'dashboard.tooltip.saved': 'Ces résultats correspondent à votre inventaire à la date suivante. Toute modification apportée aux inventaires sélectionnés depuis cette date n\'a pas été prise en compte. Veuillez sauvegarder un nouveau tableau si vous souhaitez les résultats mis à jour.',

	'modal.selector.defaultLabel': 'Sélectionner une option',

	'modal.suggestion.success': 'Merci ! Votre demande à bien été envoyée à nos équipes de support. Nous la traiterons dès que possible !',
	'modal.suggestion.error': 'Une erreur est survenue lors de l\'envoi de votre suggestion.',
	'modal.equipmentReferenceSuggestion.title': 'Demande d\'ajout d\'un produit de référence',
	'modal.equipmentReferenceSuggestion.selector.defaultLabel': 'Sélectionner une catégorie d\'équipement',
	'modal.equipmentReferenceSuggestion.input.label': 'Renseignez la référence du produit que vous souhaitez ajouter',
	'modal.domainSuggestion.title': 'Suggérer un nouveau domaine',
	'modal.domainSuggestion.content': 'Renseignez le domaine que vous souhaiteriez ajouter. Notez que ce modèle sera public et deviendra donc visible par les utilisateur.ices de Resilio Tech.',
	'modal.domainSuggestion.addDomain': 'Ou suggérer un nouveau domaine',

	/************************************************************************************************
	 *                                       API ERRORS KEYS                                        *
	 * ******************************************************************************************** */
	'api.error': 'Erreur Serveur Interne: &error()',
	'api.error.uncaught': 'Erreur Serveur Interne. Notre équipe à été informé. Si vous avez besoin d\'une assistance immédiate, contactez &support()',
	'api.error.wrongFormat': 'Erreur Serveur Interne. Notre équipe a été notifiée de ce problème. Veuillez réessayer plus tard.',
	'api.error.authentication': 'Vous devez être connecté pour effectuer cette action',
	'api.error.failedToFetch': 'Impossible de récupérer les données',
	'api.error.permission.denied': 'Vous n\'êtes pas autorisé à faire cette action',
	'api.error.access.denied': 'Vous n\'êtes pas autorisé à accéder à cette ressource',

	// Not Found
	'api.error.notFound': '&obj() introuvable',
	'api.error.company.notFound': 'Entreprise introuvable',
	'api.error.user.notFound': 'Utilisateur introuvable',
	'api.error.lambda.notFound': 'Lambda user introuvable',
	'api.error.study.notFound': 'Étude introuvable',
	'api.error.dataset.notFound': 'Périmètre introuvable',
	'api.error.resultLauncher.notFound': 'Résultat introuvable',
	'api.error.resultList.notFound': 'Liste de résultat introuvable',
	'api.error.equipment.notFound': 'Équipement introuvable',
	'api.error.equipmentType.notFound': 'Équipement type introuvable',
	'api.error.equipmentCategory.notFound': 'Catégorie d\'équipement introuvable',
	'api.error.factorSet.notFound': 'Facteur introuvable',
	'api.error.block.notFound': 'Domaine introuvable',
	'api.error.email.notFound': 'Email introuvable',
	'api.error.provider.notFound': 'Provider introuvable',
	'api.error.dashboard.notFound': 'Dashboard introuvable',
	'api.error.userDashboard.notFound': 'Utilisateur dashboard introuvable',
	'api.error.functionalUnit.notFound': 'Functional unit introuvable',
	'api.error.lcaTemplate.notFound': 'Template ACV introuvable',
	'api.error.lcaInventory.notFound': 'Inventaire ACV introuvable',
	'api.error.lcaMaturity.notFound': 'Maturité ACV introuvable',
	'api.error.cmdb.notFound': 'Cmdb &name() introuvable',
	'api.error.setting.notFound': 'Paramètre introuvable',
	'api.error.gpInstance.notFound': 'Bonne pratique introuvable',
	'api.error.sample.notFound': 'Échantillon introuvable',

	'api.error.user.notActive': 'User not active',

	// Login
	'api.error.login.wrongCredentials': 'Erreur d\'authentification: email ou mot de passe incorrect. Veuillez réessayer',
	'api.error.login.passwordsNotMatch': 'Les mots de passe ne correspondent pas',
	'api.error.login.wrongPassword': 'Mot de passe incorrect',
	'api.error.login.passwordSameAsOld': 'Le nouveau mot de passe est identique à l\'ancien',
	'api.error.login.passwordInvalid': 'Échec de la validation du mot de passe: &error()',
	'api.error.login.passwordResetTokenExpired': 'Le token de réinitialisation de mot de passe a expiré',
	'api.error.tooManyAttempts.15min': 'Trop de tentatives. Veuillez réessayer dans 15 minutes',
	'api.error.tooManyAttempts.1hour': 'Trop de tentatives. Veuillez réessayer dans 1 heure',
	'api.error.lambdaUser.linkExpired': 'L\'utilisateur n\'est lié à aucune IP',

	// Token
	'api.error.token': 'Pas de token',
	'api.error.token.expired': 'Token expiré',
	'api.error.token.invalid': 'Token invalide',
	'api.error.token.notAccepted': 'Token non accepté',
	'api.error.email.invalid': 'Email invalide',

	// Lambda
	'api.error.link.expired': 'Lien expiré',
	'api.error.link.mismatch': 'Lien IP invalide',
	'api.error.lambda.tooManyAttempts': 'Trop de tentatives',
	'api.error.lambda.codeInvalid': 'Code invalide',
	'api.error.lambda.codeExpired': 'Code expiré',

	// Manage
	'api.error.manage.user.groups.empty': 'L\'utilisateur doit appartenir à au moins un groupe',
	'api.error.lambdaUser.noDomain': 'Les compagnies doivent avoir au moins un domaine',
	'api.error.lambdaUser.invalidDomain': 'Domaine email invalide',
	'api.error.resultList.types.notProvided': 'Des types de résultats doivent être fournis lors de la création ou la modification de liste',
	'api.error.resultList.exists': 'Cette liste de résultats existe déjà',

	// CMDB
	'api.error.cmdb.studyAndMultipleDatasets': 'Vous devez choisir entre ajouter la CMDB à l\'étude ou à plusieurs périmètres',
	'api.error.cmdb.datasetIdMissing': 'Vous devez fournir un id de périmètre',
	'api.error.cmdb.createInstancesFailed': 'Échec de la création des instances de CMDB: &error()',
	'api.error.cmdb.category.notFound': 'Cette catégorie n\'existe pas: &category()',
	'api.error.cmdb.model.notFound': 'Modèle introuvable avec cet identifiant: &identifier()',
	'api.error.cmdb.addEquipmentFailed': 'Échec de l\'ajout d\'équipement. Nous avons déjà informé l\'équipe de support de Resilio. Votre consultant et notre support vous répondront dans les 48h. Si vous avez besoin d\'une assistance immédiate, contactez &support()',
	'api.error.cmdb.addEquipmentTypeFailed': 'Échec de l\'ajout d\'un nouveau type d\'équipement. Nous avons déjà informé l\'équipe de support de Resilio. Votre consultant et notre support vous répondront dans les 48h. Si vous avez besoin d\'une assistance immédiate, contactez &support()',
	'api.error.cmdb.noCmdbAdded': 'Aucune CMDB n\'a été ajoutée.Nous avons déjà informé l\'équipe de support de Resilio. Votre consultant et notre support vous répondront dans les 48h. Si vous avez besoin d\'une assistance immédiate, contactez &support()',
	'api.error.cmdb.emptyData': 'Données de CMDB vides',
	'api.error.cmdb.invalidColumns': 'Données de CMDB invalides. Colonnes invalides: &columns()',
	'api.error.cmdb.missingColumns': 'Données de CMDB invalides. Colonnes manquantes: &columns()',
	'api.error.cmdb.invalidDataColumns': 'Données de CMDB invalides. Colonnes invalides: &columns()',
	'api.error.cmdb.invalidDataBlock': 'Données de CMDB invalides dans la colonne block. Mauvaises valeurs: &values()',
	'api.error.cmdb.dataParsingFailed': 'Échec du traitement de vos données de CMDB: &error()',
	'api.error.cmdb.relatedToResults': 'Vous ne pouvez pas supprimer une CMDB qui est utilisé dans un résultat',

	// Company
	'api.error.company.date.format': 'Format de date invalide. Utilisez le format YYYY-MM-DD',
	'api.error.company.email.empty': 'Les domaines d\'email ne peuvent pas être vides',

	// Compute
	'api.error.compute.noResultList': 'La liste de résultat à calculer est vide',
	'api.error.compute.noResultToCompute': 'La liste de résultat à calculer est vide après contrôle',
	'api.error.compute.definitiveResults': 'Le périmètre contient des résultats définitifs',
	'api.error.compute.inconsistentImpacts': 'Les impacts ne sont pas cohérents. Il manque des impacts pour les équipements: &break() &error()',

	// Dataset
	'api.error.dataset.delete.sample': 'Vous ne pouvez pas supprimer un périmètre qui est utilisé dans un benchmark',
	'api.error.dataset.delete.definitive': 'Vous ne pouvez pas supprimer un périmètre qui contient des résultats définitifs',
	'api.error.dataset.template.studyFunctionalUnitMismatch': 'L\'unité fonctionnelle de l\'étude ne correspond pas à celle associée au template',
	'api.error.dataset.template.creationFailed': 'Échec de la création du périmètre depuis le template: &error()',
	'api.error.dataset.hasDefinitiveResults': 'Vous ne pouvez pas modifier un périmètre qui contient des résultats définitifs',
	'api.error.dataset.archived': 'Le périmètre est archivé et ne peut être modifié',
	'api.error.dataset.nameExists': 'Un périmètre avec ce nom existe déjà',
	'api.error.dataset.companyMismatch': 'La compagnie de la nouvelle étude ne correspond pas à la compagnie du périmètre',
	'api.error.dataset.collectClosed': 'Vous ne pouvez pas modifier ce périmètre car la collecte est fermée. Ouvrez-la / Demandez à votre consultant de l\'ouvrir si vous devez importer des données. Si nécessaire, contactez &support()',
	'api.error.datasets.collectClosed': 'La collection d\'inventaire est fermée pour ces périmètres: &datasets(). Ouvrez-la / Demandez à votre consultant de l\'ouvrir si vous devez importer des données. Si nécessaire, contactez &support()',
	'api.error.dataset.validationClosed': 'Vous ne pouvez pas modifier ce périmètre car la validation est fermée',
	'api.error.dataset.id.required': 'Impossible de récupérer les données, aucun ID de périmètre ou d\'étude fourni',

	// Dashboard
	'api.error.dashboard.datasetIds': 'Impossible de récupérer les données de périmètre depuis le dashboard: &error()',
	'api.error.dashboard.invalidUsers': 'Un ou plusieurs utilisateurs sélectionnés n\'ont pas accès aux périmètres associés au dashboard',
	'api.error.userDashboard.invalidJson': 'Données JSON invalides pour la carte de dashboard',

	// Equipment
	'api.error.equipment.relateToResult': 'Vous ne pouvez pas supprimer un équipement qui est utilisé dans un résultat',

	// Equipment Type
	'api.error.equipmentType.defaultLifetimeRequired': 'Un temps de vie par défaut est requis pour les services',
	'api.error.equipmentType.noImpactFactors': 'Aucun facteur d\'impact n\'a été trouvé pour cet équipement',
	'api.error.equipmentType.deletion.failed': 'Échec de la suppression de l\'équipement type',
	'api.error.equipmentType.update.failed': 'Échec de la modification de l\'équipement type',

	// Export
	'api.error.export.latex.tooManyStudies': 'Vous ne pouvez pas exporter en LaTex en combinant plus d\'une étude.',
	'api.error.export.latex.noData': 'Échec de la collection de données pour le résultat &result_type() en &lang(): &error()',
	'api.error.export.impacts.noInventoryExtraData': 'Échec de la récupération des données de paramètres d\'ACV',

	// LCA Template
	'api.error.lcaTemplate.functionalUnitMismatch': 'L\'unité fonctionnelle ne correspond pas à celle associée au template',
	'api.error.lcaTemplate.functionalUnitMissing': 'L\'unité fonctionnelle est manquante dans le template',

	// Maturity
	'api.error.maturity.noAnalysis': 'Ce périmètre n\'a pas d\'analyse de maturité',
	'api.error.maturity.alreadyExists': 'Ce périmètre contient déjà des bonnes pratiques',
	'api.error.maturity.noTemplate': 'L\'étude n\'a pas de template de maturité',
	'api.error.maturity.templateNoMaturity': 'Le template associé à l\'étude n\'a pas de maturité',

	// Resilio Database
	'api.error.rdb.callFailed': 'Échec de l\'appel à ResilioDatabase: Status &status()',
	'api.error.rdb.category.notFound': 'Catégorie invalide: &category()',
	'api.error.rdb.missingField': 'Donnée invalide pour le champ: &field()',
	'api.error.rdb.configuration.notFound': 'Aucune configuration trouvée dans la réponse',

	// Result Launcher
	'api.error.resultLauncher.delete.definitive': 'Vous ne pouvez pas supprimer un périmètre qui contient une version définitive de résultat',
	'api.error.resultLauncher.edit.definitive': 'Vous ne pouvez pas modifier un périmètre qui contient une version définitive de résultat',
	'api.error.resultLauncher.invalidInventoryExtraSnapshot': 'Données JSON invalides pour le snapshot de paramètres d\'ACV',

	// Result
	'api.error.queryResults.wrongParams': 'Vous devez fournir soit un rl_id ou results_ids comme paramètre',

	// Sample
	'api.error.sample.delete': 'Vous ne pouvez pas supprimer un benchmark qui est utilisé dans une validation ou un résultat',

	// Study
	'api.error.study.archived': 'L\'étude est archivée et ne peut être modifiée',
	'api.error.study.consultant.notAllowed': 'Cet utilisateur &email() ne peut pas être ajouté à l\'étude',

	// Mutation Failed
	'api.error.socialAuth.failed': 'Erreur Authentification SSO: &error()',
	'api.error.passwordReset.failed': 'Échec de la réinitialisation de mot de passe: &error()',
	'api.error.passwordChange.failed': 'Échec du changement de mot de passe: &error()',
	'api.error.acceptCgu.failed': 'Échec de l\'acceptation des CGU: &error()',
	'api.error.sendNotification.failed': 'Échec de l\'envoi de notification: &error()',
	'api.error.companyToManage.failed': 'Échec de la gestion de la compagnie: &error()',
	'api.error.email.failed': 'Échec de l\'envoi de l\'email',
	'api.error.requestCalculation.failed': 'Échec de la demande de calcul: &error()',
	'api.error.resultsComparison.failed': 'Échec de la comparaison des résultats: &error()',
	'api.error.export.impacts.failed': 'Échec de l\'export d\'impacts: &error()',
	'api.error.inventoryExtraEdit.failed': 'Échec de la modification de l\'inventory extra: &error()',
	'api.error.colorManagement.failed': 'Échec de la modification des couleurs: &error()',
	'api.error.addEquipmentResilioDb.failed': 'Échec de l\'ajout d\'équipement via ResilioDatabase: &error()',
	'api.error.addEquipmentCmdbFailed.failed': 'Échec de l\'ajout d\'équipement via CMDB: &error()',
	'api.error.equipmentTypeManagement.failed': 'Échec de la gestion de l\'équipement type: &error()',
	'api.error.sample.unifyDatasetsLCAParams.failed': 'Échec de l\'unification des paramètres ACV',

	// Query Failed
	'api.error.lambdaVerifyLink.failed': 'Échec de la vérification du lien lambda: &error()',
	'api.error.lambdaVerifyCode.failed': 'Échec de la vérification du code lambda: &error()',
	'api.error.queryUser.failed': 'Échec de la récupération des données utilisateur: &error()',
	'api.error.queryMyCompany.failed': 'Échec de la récupération des données de la compagnie: &error()',

};
