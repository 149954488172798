import React, { useEffect } from 'react';
import Template from '../../../viewComponents/template/template/template';
import { useSelector } from 'react-redux';
import { State } from '../../../store';
import { translate } from '../../../infrastructure/translations/translate';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../viewComponents/utils/Spinner/Spinner';
import { DashboardComparisonData, DashboardComparisonMaturity } from '../../../domain/interfaces/DashboardComparisonData';
import { SideBarContentItemList } from '../../../viewComponents/template/sidebar/sidebar';
import { Alert } from '../../../viewComponents/Alerts';
import { DashboardSaveDataButton, DeleteDashboardButton } from '../DashboardSaveData';
import { DashboardShareButton } from '../DashboardShare';
import { useSharedDashboardOwning } from '../SharedDashboardOwning';
import { useDashboards } from '../../../viewComponents/hooks/useDashboards';
import { useIndicators } from '../../../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../../../viewComponents/hooks/useLifeCycleSteps';
import DashboardPerimetersEvolution from './Graphs/DashboardPerimetersEvolution';
import { useStudies } from '../../../viewComponents/hooks/useStudies';
import { changeDashboard } from '../../../store/dispatchers';
import { useModal } from '../../../viewComponents/modal/useModal';
import { createDashboardModalEvolution } from './CreateDashboardModalEvolution';
import DashboardDomainsEvolution from './Graphs/DashboardDomainsEvolution';
import DashboardIndicatorsEvolution from './Graphs/DashboardIndicatorsEvolution';
import { useTooltip } from '../../../viewComponents/tip/useTooltip';
import DashboardMaturityDomainEvolution from './Graphs/DashboardMaturityDomainEvolution';
import DashboardEquipmentEvolution from './Graphs/DashboardEquipmentEvolution';
import { DashboardCardAlert } from '../DashboardCardAlert';
import { maturityCorrectlyFilled } from '../maturityCorrectlyFilled';

export const compareEvolutionValueToRefValue = (refValue: number | undefined, value: number | undefined): JSX.Element => {
	if (!value || !refValue) return <></>;

	const diff = value - refValue;
	const percentage = diff / refValue * 100;
	const text = `${diff < 0 ? '' : '+'}${percentage.toFixed(0)}%`;
	return <span style={{ color: diff > 0 ? '#f00' : '#00ad00' }}>{text}</span>;
};

const DashboardEvolutionButton = (): JSX.Element => {
	const navigate = useNavigate();
	const { studies } = useStudies();
	const [openEvolutionModal, setOpenEvolutionModal] = React.useState<boolean>(false);

	const setEvolutionData = (datasetsIds: string[]) => {
		changeDashboard({ type: 'evolution',  data: datasetsIds });
		navigate('/dashboard/evolution/new');
	};
	useModal(openEvolutionModal, createDashboardModalEvolution(
		openEvolutionModal,
		setOpenEvolutionModal,
		setEvolutionData,
		studies
	));

	return <button
		className={'button button_primary'}
		onClick={() => setOpenEvolutionModal(true)}
		title={translate('dashboard.evolution.new') as string}
	>
		<i className={'fa-solid fa-plus'}/>
	</button>;
};

const DashboardEvolution = (): JSX.Element => {
	const { dashboardId } = useParams<{ dashboardId: string }>();
	const { entity, dashboards } = useDashboards();
	const evolutionDashboards = dashboards?.filter(d => d.type === 'evolution');
	const datasetsIds = useSelector((state: State) => state.dashboard.evolution);
	const [newData, setNewData] = React.useState<{ data: DashboardComparisonData, maturity: DashboardComparisonMaturity }>();
	const [isAlertOpen, setIsAlertOpen] = React.useState<boolean>(true);
	const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
	const [error, setError] = React.useState<boolean>(false);

	useTooltip(showTooltip, translate('dashboard.tooltip.saved'));

	useEffect(() => {
		if (!dashboardId) return;
		if (dashboardId == 'new') {
			if (datasetsIds.length) entity?.queryDashboardComparisonData({ datasetsIds }).then(res => {
				setNewData(res);
			}).catch(() => setError(true));
		} else {
			const dashboard = evolutionDashboards?.find(d => d.id === dashboardId);
			if (!dashboard) return;
			if (!dashboard.data) entity?.queryDashboard(dashboardId);
		}
	}, [dashboardId, JSON.stringify(datasetsIds), JSON.stringify(evolutionDashboards)]);

	const dashboard = evolutionDashboards?.find(d => d.id == dashboardId);
	const owning = useSharedDashboardOwning(dashboard);
	const isSaved = dashboard && dashboard.data && dashboardId !== 'new';
	const data = !isSaved ? newData : JSON.parse(dashboard.data);
	const { selectedIndicators: selectedIndicators } = useIndicators(data?.data.indicators ?? []) ?? [];
	const { lcs: selectedLifeCycleSteps } = useLifeCycleSteps(data?.data.lifeCycleSteps ?? []) ?? [];

	const side: SideBarContentItemList = {
		content: {
			list: [
				{
					id: '',
					name: translate('dashboard.evolution.new') as string,
					current: dashboardId === 'new',
					link: '/dashboard/evolution/new',
				},
			]
		}
	};

	if (evolutionDashboards?.length) {
		evolutionDashboards.forEach(d => {
			side.content.list.push({
				id: d.id,
				name: d.name,
				current: d.id === dashboardId,
				link: `/dashboard/evolution/${d.id}`,
			});
		});
	}

	if (dashboardId == 'new' && !datasetsIds.length) return <Template core={<section>
		<h1>{translate('dashboard.evolution')}</h1>
		<p>{translate('dashboard.noDataset')}</p>
		<DashboardEvolutionButton />
	</section>} side={side} />;

	if (error) return <Template core={<></>} />;

	if (!data) return <Template core={<section>
		<h1>{translate('dashboard.evolution')}</h1>
		<Spinner />
	</section>} />;

	const core = <section>
		<h1>{translate('dashboard.evolution.title')}</h1>
		{isSaved && dashboard.date && <div className={'dashboard_date'}>
			{translate('dashboard.savedOn', { date: new Date(dashboard.date).toLocaleString() })}
			<button
				className={'button_blank'}
				type={'button'}
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
			>
				<i className="fa-regular fa-circle-exclamation"></i>
			</button>
		</div>}
		{(owning || dashboardId == 'new') && <div className="dashboard_save_button">
			<DashboardEvolutionButton />
			{!isSaved && <DashboardSaveDataButton type={'evolution'} data={data} />}
			{isSaved && <DeleteDashboardButton dashboardId={dashboardId} />}
			{isSaved && <DashboardShareButton sharedUsers={dashboard.sharedUsers} dashboard={dashboard} />}
		</div>}
		{isAlertOpen && (selectedIndicators.length > 0 || selectedLifeCycleSteps.length > 0) && <div className={'dashboard_card dashboard_card_info dashboard_card_color_yellow'}>
			<button className={'close_button'} onClick={() => setIsAlertOpen(false)}>X</button>
			<Alert variant={'info'}>{translate('dashboard.alert.usedIndicatorsAndLifeCycleSteps')}</Alert>
			{selectedIndicators.length > 0 && <Alert variant={'info'}>
				<div>
					{translate('dashboard.indicators')}:
					<ul>
						{selectedIndicators.map(i => <li key={i.id}>
							{i.name} - {i.shortName}
						</li>)}
					</ul>
				</div>
			</Alert>}
			{selectedLifeCycleSteps.length > 0 && <Alert variant={'info'}>
				<div>
					{translate('dashboard.lifeCycleSteps')}:
					<ul>
						{selectedLifeCycleSteps.map(i => <li key={i.id}>
							{i.name}
						</li>)}
					</ul>
				</div>
			</Alert>}
		</div>}
		<DashboardPerimetersEvolution data={data.data} />
		<DashboardDomainsEvolution data={data.data} />
		<DashboardIndicatorsEvolution data={data.data} />
		{data.maturity && !maturityCorrectlyFilled(data.maturity) && <DashboardCardAlert
			variant={'warning'}
			className={'dashboard_card_color_yellow'}
		>
			{translate('dashboard.maturity.notCorrectlyFilled')}
		</DashboardCardAlert>}
		{maturityCorrectlyFilled(data.maturity) && <DashboardMaturityDomainEvolution data={data.maturity} />}
		<DashboardEquipmentEvolution data={data.data} />
	</section>;
	return <Template core={core} side={side} /> ;
};

export default DashboardEvolution;