import { gql } from '@apollo/client';

export const MUTATE_EXPORT_INVENTORY = gql`
    mutation MutateExportInventory(
        $datasetId: String!
    ){
        mutateExportInventory(
            datasetId: $datasetId,
        ) {
            ok
            base64
        }
    }
`;
