import { gql } from '@apollo/client';

export const MUTATE_SHARE_DASHBOARD = gql`
    mutation MutateShareDashboard(
        $id: String!
        $userIds: [String!]!
    ){
        mutateShareDashboard(
            id: $id
            userIds: $userIds
        ) {
            dashboard {
                id
                name
                type
                data
                userId
                sharedUsers {
                    id
                    email
                }
            }
        }
    }
`;
