import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../store';
import './GlobalModal.scss';
import ButtonLoading from '../button/ButtonLoading/ButtonLoading';
import { AlertError } from '../Alerts';
import { translate } from '../../infrastructure/translations/translate';
import { useModal } from './useModal';


export const closeGlobalModal = () => {
	useModal(false, {
		visible: false,
		body: <></>,
		header: '',
		error: false,
		errorMessage: '',
	});
};

/**
 * @returns GlobalModal JSX.Element
 * @description GlobalModal component, this component is used to show a modal.
 * the modal is not called directly, it is called by using the setGlobalModal function.
 * @see setGlobalModal is used to store an IModal instance in the store
 * @see IModal is the interface of the modal
 * @see useCreateStudyModal is an example on how to implement the modal
 * 
 * @author Yacine Bentayeb
 */
export const GlobalModal = (): JSX.Element => {
	let lastElement: null | HTMLElement = null;
	const ref = useRef<HTMLDivElement>(null);
	const { loading, error, data } = useSelector((state: State) => state.dataModalStatus);

	const closeModal = () => {
		if(data?.blockClose?.current) return;
		if (data?.onClose) {
			data.onClose();
		}
		closeGlobalModal();
	};
	let downOutside = false;
	let tabForward = false;
	const isElemIn = (e: HTMLElement) => e === ref.current || ref.current?.contains(e);

	const focus = (last?: boolean) => {
		const elems = ref.current?.querySelectorAll<HTMLInputElement | HTMLButtonElement>('input, button, a, textarea, select, [tabindex]');
		if (!elems) return;
		const elem = elems[last ? elems.length - 1 : 0];
		if (elem) elem.focus();
	};

	const handleClickDown = (e: MouseEvent) => {
		const elem = e.target as HTMLElement;
		if (elem === lastElement || lastElement?.contains(elem)) return;
		downOutside = !isElemIn(elem);
	};

	const handleClickUp = (e: MouseEvent) => {
		const elem = e.target as HTMLElement;
		if (elem === lastElement || lastElement?.contains(elem)) return;
		if (downOutside && !isElemIn(elem)) {
			closeModal();
		}
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'Escape') closeModal();
		if (e.key === 'Tab') tabForward = !e.shiftKey;
	};

	const handleFocusIn = (e: FocusEvent) => {
		const elem = e.target as HTMLElement;
		if (!isElemIn(elem)) focus(!tabForward);
	};

	useEffect(() => {
		if (!data?.visible) return;
		lastElement = document.activeElement as HTMLElement;
		document.addEventListener('keydown', handleKeyDown);
		document.addEventListener('mousedown', handleClickDown);
		if (!data?.blockClickOutside)
			document.addEventListener('mouseup', handleClickUp);
		document.addEventListener('focusin', handleFocusIn);
		focus();
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('mousedown', handleClickDown);
			if (!data?.blockClickOutside)
				document.removeEventListener('mouseup', handleClickUp);
			document.removeEventListener('focusin', handleFocusIn);
			lastElement?.focus();
		};
	}, [data?.visible, data?.blockClickOutside]);
	if (error || loading) return <></>;
	return (
		<div className={`global_modal ${data?.visible ? 'active' : ''}`} role={'dialog'} aria-modal={true}>
			<div
				className={'global_modal_content'}
				ref={ref} role={'dialog'}
				aria-modal={true}
			>
				{data?.header !== undefined && <div className={'modal_header'}>
					{ typeof data?.header === 'string' ? <h2>{data?.header}</h2> : data?.header }
				</div>}
				<div className={'modal_body'}>
					{data?.body}
				</div>
				{data?.footer ? data?.footer :
					<div className={'modal_footer'}>
						{data?.additionalFooterContent && !data?.error && <div className={'modal_footer_additional_content'}>
							{data?.additionalFooterContent}
						</div>}
						{data?.error && <AlertError status={data?.errorMessage} onClick={data.setError} />}
						<button className={'modal_button'} onClick={() =>{
							if (data?.onCancel) data.onCancel();
							else closeModal();
						}}>
							{data?.cancelText ? translate(data?.cancelText) : translate('cancel')}
						</button>
						<ButtonLoading 
							classname={`modal_button ${!data?.isButtonDisabled ? 'validate_modal_button' : ''}`}
							title={data?.actionText ?? 'save'}
							onClick={data?.action}
							loading={data?.load ?? false}
							disable={data?.isButtonDisabled ?? false}
						/>
					</div>
				}
				<button
					className={'modal_close_button'}
					aria-label={translate('close') as string}
					onClick={closeModal}
				>
					<i className="fa-solid fa-x"/>
				</button>
			</div>
		</div>
	);
};