import React, { useEffect } from 'react';
import { closeGlobalModal } from '../GlobalModal';
import { translate } from '../../../infrastructure/translations/translate';
import { useTooltip } from '../../tip/useTooltip';
import '../../../views/ResultsView/ResultsView.scss';
import './EquipmentsVersionsModal.scss';
import { useManageEquipment } from '../../hooks/useManageEquipment';
import { useResultLaunchers } from '../../hooks/useResultLaunchers';
import { IEquipmentsVersions } from '../../../domain/interfaces/IQueryLauncherEquipmentsVersions';

export const useEquipmentsVersionsModal = (
	open: boolean,
	setOpen: (open: boolean) => void,
	datasetId: string | null,
	resultLauncherId: string | null,
) => {
	const { entity: equipmentEntity } = useManageEquipment();
	const { entity: rlEntity } = useResultLaunchers();

	const [equipmentVersions, setEquipmentVersions] = React.useState<IEquipmentsVersions[]>([]);
	const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
	useTooltip(showTooltip, translate('results.tooltip.dataReference'));

	const onClose = () => {
		setOpen(false);
		closeGlobalModal();
	};

	// If modal is called with resultLauncherId, query the equipment versions for the result launcher
	useEffect(() => {
		if (!open || !resultLauncherId) return;
		rlEntity?.queryLauncherEquipmentsVersions({ resultLauncherId: resultLauncherId })
			.then((result) => {
				setEquipmentVersions(result.launcherEquipmentsVersions);
			})
			.catch(() => onClose());
	}, [open]);

	// If modal is called with datasetId, query the equipment versions for the dataset
	useEffect(() => {
		if (!open || !datasetId) return;
		equipmentEntity?.queryModalEquipmentsInfos({ datasetId }).then((infos) => {
			setEquipmentVersions(infos);
		}).catch(() => {
			onClose();
		});
	}, [open, datasetId]);

	if (!open || !equipmentVersions.length) return;

	const formatDate = (date: string): string => {
		const dateObj = new Date(date);
		return dateObj.toLocaleDateString();
	};

	const body = <div className={'version_table_wrapper'}>
		<table className={'result_table version_table'}>
			<thead>
				<tr>
					<th>{translate('results.equipment')}</th>
					<th>{translate('results.equipmentShortNameAndSpec')}</th>
					<th>{translate('results.version')}</th>
					<th>
						{translate('results.dataReference')}
						<i className="fa-regular fa-info-circle" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}/>
					</th>
					<th>{translate('results.dateVersion')}</th>
					<th>{translate('results.dbSource')}</th>
				</tr>
			</thead>
			<tbody>
				{equipmentVersions.map((equipmentInfo, idx) => {
					return (<tr key={idx}>
						<td>{equipmentInfo.name}</td>
						<td>{equipmentInfo.factorSet.equipmentTypeShortNameAndSpecification}</td>
						<td>{equipmentInfo.factorSet.version}</td>
						<td className={'data_reference'}>
							{equipmentInfo.factorSet.dataReference.reference.length > 0 && equipmentInfo.factorSet.dataReference.isHash &&
							<a
								href={`https://db.resilio.tech/audit/${equipmentInfo.factorSet.dataReference.reference}`}
								target="_blank" 
								rel="noopener noreferrer" 
								aria-label={translate('results.redirect.id') as string}
							>
								{equipmentInfo.factorSet.dataReference.reference}
							</a>}
							{equipmentInfo.factorSet.dataReference.reference.length > 0 && !equipmentInfo.factorSet.dataReference.isHash &&
								<span>{equipmentInfo.factorSet.dataReference.reference}</span>}
							{equipmentInfo.factorSet.dataReference.reference.length <= 0 && <span>-</span>}
						</td>
						<td>{formatDate(equipmentInfo.factorSet.updatedAt)}</td>
						<td>{equipmentInfo.factorSet.equipmentTypeSource}</td>
					</tr>);
				})}
			</tbody>
		</table>
	</div>;

	return {
		visible: true,
		header: translate('inventory.equipmentInfos.modal.title') as string,
		body: body,
		onClose: onClose,
		footer: <></>
	};
};