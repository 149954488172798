import React from 'react';
import { translate } from '../../infrastructure/translations/translate';
import GroupByResult from '../../viewComponents/results/GroupBy/GroupBy';
import { ResultContainer } from '../ResultsView/ResultsView';
import { useUser } from '../../viewComponents/hooks/useUser';
import Template from '../../viewComponents/template/template/template';
import { SideBarContentItemList } from '../../viewComponents/template/sidebar/sidebar';
import { changeDocumentTitle } from '../../utils/changeDocumentTitle';

const ExploreView = () => {
	const { data: user } = useUser();
	const canGroupBy = user?.permissions.includes('login.can_view_equipment_groupby');
	const core = <div className={'results_grid group_by'}>
		{canGroupBy && <ResultContainer id={'group by'} groupBy={true}>
			<GroupByResult/>
		</ResultContainer>}
	</div>;

	const side : SideBarContentItemList = {
		content: {
			list: [
				{
					id: 'group_by',
					name: translate('groupby.title') as string,
					current: true,
					link: '/explore',
				}
			]
		},
	};

	changeDocumentTitle(translate('sidebar.explore') as string);

	return <Template core={core} side={side} />;
};

export default ExploreView;