import React, { useEffect } from 'react';
import { closeGlobalModal } from '../GlobalModal';
import { translate } from '../../../infrastructure/translations/translate';
import '../EquipmentTypesVersionsModal/EquipmentsVersionsModal.scss';
import '../../../views/ResultsView/ResultsView.scss';
import './LcaParametersVersionModal.scss';
import { useResultLaunchers } from '../../hooks/useResultLaunchers';
import { IEnergyMixFactorSet } from '../../../domain/interfaces/IQueryLifeCycleAssessmentParameters';

export const useLcaParametersVersionsModal = (
	open: boolean,
	setOpen: (open: boolean) => void,
	resultLauncherId: string | null,
) => {
	const { entity } = useResultLaunchers();

	const [referenceFlux, setReferenceFlux] = React.useState<number | null>(null);
	const [blockEnergyMixes, setBlockEnergyMixes] = React.useState<{ block: { id: string, name: string }, mix: { id: string, name: string } }[] >([]);
	const [energyMixFactorSets, setEnergyMixFactorSets] = React.useState<IEnergyMixFactorSet[]>([]);

	const onClose = () => {
		setOpen(false);
		closeGlobalModal();
	};

	// If modal is called with datasetId, query the equipment versions for the dataset
	useEffect(() => {
		if (!open || !resultLauncherId) return;

		entity?.queryLifeCycleAssessmentParametersVersions({ resultLauncherId })
			.then((data) => {
				let parsedData;
				try {
					parsedData = JSON.parse(data.launcherLcaParametersVersions);
				} catch (error) {
					setOpen(false);
					return;
				}

				if (Array.isArray(parsedData) && parsedData.length > 0) {
					const firstDataset = parsedData[0];
					setReferenceFlux(firstDataset.referenceFlux);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					setBlockEnergyMixes(firstDataset.blockEnergyMix.map((bem: any) => ({ block: { id: bem.block.id, name: bem.block.name }, mix: { id: bem.mix.id, name: bem.mix.name } })));
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					setEnergyMixFactorSets(firstDataset.energyMixFactorSets.map((emfs: any) => ({
						id: emfs.id,
						energyMixId: emfs.energy_mix.id,
						energyMixName: emfs.energy_mix.name,
						version: emfs.version,
						updatedAt: emfs.updated_at
					})));
				}
			})
			.catch(() => onClose());
	}, [open, resultLauncherId]);

	if (!open || !resultLauncherId) return;

	const body = <div className={'lca_parameters_versions_wrapper'}>
		<div>
			<h3>{translate('validation.referenceFlux')} <span className={'reference_flux'}>{referenceFlux}</span> </h3>
		</div>
		<div className={'version_table_wrapper'}>
			<h3>{translate('energyMix')}</h3>
			<table className={'result_table version_table'}>
				<thead>
					<tr>
						<th>{translate('domain')}</th>
						<th>{translate('energyMix')}</th>
						<th>{translate('results.version')}</th>
					</tr>
				</thead>
				<tbody>
					{blockEnergyMixes?.map((bem) => {
						const emfs = energyMixFactorSets?.find(efms => efms.energyMixId === bem.mix.id);
						return <tr key={bem.block.id}>
							<td>{bem?.block.name}</td>
							<td>{emfs?.energyMixName}</td>
							<td>{emfs?.version}</td>
						</tr>;
					})}
				</tbody>
			</table>
		</div>
	</div>;

	return {
		visible: true,
		header: translate('lcaParameters') as string,
		body: body,
		onClose: onClose,
		footer: <></>
	};
};