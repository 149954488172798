import { gql } from '@apollo/client';


export const MUTATE_TOKENAUTH = gql`
    mutation
        TokenAuthMutation(
            $email: String!
            $password: String!
        ) {
        mutateTokenAuth(email: $email, password: $password) {
        token,
        user {
          id, 
          username,
        }
      }
    }
`;
