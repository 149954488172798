
import { gql } from '@apollo/client';

export const MUTATE_SOCIAL_AUTHENTICATION = gql`
	mutation SocialAuthMutation($provider: String!, $accessToken: String!) {
	  mutateSocialAuth(
	  	provider: $provider, 
	  	accessToken: $accessToken
	  ) {
	    token
	  }
	}
`;
