import { gql } from '@apollo/client';

export const MUTATE_BLOCK_ORDER_MANAGEMENT = gql`
    mutation MutateBlockOrderManagement(
        $blockList: [String]!
    ) {
        mutateBlockOrderManagement(
            blockList: $blockList
        ) {
            blocks {
                id,
                name,
                private,
                order,
                studyIds,
            }  
        }
    }
`;