import { gql } from '@apollo/client';

export const MUTATE_RESULT_LIST_MANAGEMENT = gql`
	mutation MutateResultListManagement(
	    $id: String!,
	    $name: String!,
	    $desc: String!,
	    $types: [Int!]!,
	    $remove: Boolean!
	) {
        mutateResultListManagement (
            id: $id,
            name: $name,
            desc: $desc,
            types: $types,
            remove: $remove
        ) {
            resultLists {
                id,
                name,
                desc,
                resultTypes {
                    identifier
                }
            }
		}
	}
`;
