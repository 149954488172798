import {
	RelatedDatasetEntity,
	Entity,
	injectInputSetter,
	InputProperty,
	ValueEntity,
	DataMutate
} from '../core/Entity';
import { numNullToString } from '../../utils/numNullToString';
import { Core } from '../core/Core';
import { Setting } from '../data/entries/Setting';
import { ResponseDataSettings } from '../data/ResponseDataSettings';
import { ResponseMutateLineSettingsSave } from '../data/ResponseDatas';
import {
	ApolloClientMutateSettingsLine,
	ApolloClientQueryDataSettings,
	ApolloClientQuerySettingTypes
} from '../../infrastructure/ApolloClass/SettingsClass';
import { QUERY_DATA_SETTINGS } from '../../infrastructure/ApolloClient/requests/QUERY_DATA_SETTINGS';
import { QUERY_SETTING_TYPES } from '../../infrastructure/ApolloClient/requests/QUERY_SETTING_TYPES';
import { MUTATE_SETTINGS_LINE } from '../../infrastructure/ApolloClient/requests/MUTATE_SETTINGS_LINE';
import { ISettingType } from '../interfaces/IQuerySettingTypes';
import { store } from '../../store';
import { ApiError } from '../core/ApiError';

export class SettingsDataEntity extends Entity {
	public data: undefined | SettingsDatasetEntity[];
	private _isQuerying = false;

	initialization() {
		this.app.adapter.queryDataSettings ??= this.app.installer(ApolloClientQueryDataSettings, QUERY_DATA_SETTINGS);
		this.app.adapter.querySettingTypes ??= this.app.installer(ApolloClientQuerySettingTypes, QUERY_SETTING_TYPES);
		this.app.adapter.mutateDataSettings ??= this.app.installer(ApolloClientMutateSettingsLine, MUTATE_SETTINGS_LINE);
		this.app.adapter.storeDataSettings?.({ loading: false, error: null, data: this });
	}

	get(): SettingsDatasetEntity[] | undefined {
		if (!this.data) {
			if (this._isQuerying) return;
			this.queryDataSettings();
		}
		return this.data;
	}

	set (obj: ResponseDataSettings['datasetSettings'] | undefined): void {
		this.data = obj?.map(i => new SettingsDatasetEntity(this.app, this, i));
		this.change();
	}

	change (): Promise<void> | undefined {
		return this.app.adapter.storeDataSettings?.({ loading: false, error: null, data: this });
	}

	update(line: ResponseMutateLineSettingsSave['mutateSaveSettingLine']['setting']) {
		const setting = this.data?.reduce<SettingEntity | undefined>(
			(vp, vc) => vp ?? vc.data.settings.reduce<SettingEntity | undefined>(
				(ep, ec) => ep ?? (ec.data.id === line.id ? ec : undefined),
				undefined),
			undefined);
		if (!setting) return;
		setting.define(line);
		this.change();
	}

	private _storeError(error: Error | ApiError): void {
		this.app.adapter.storeDataSettings?.({ loading: false, error, data: this });
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryDataSettings() {
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.queryDataSettings, { datasetId: store.getState().datasetID || '' }).catch((err) => {
			this._isQuerying = false;
			if (!err) return undefined;
			this._storeError(err);
			return undefined;
		});
		if (!data) return;
		this.set(data.datasetSettings);
	}

	async querySettingTypes(): Promise< ISettingType[]> {
		const data = await this.callApi(this.app.adapter.querySettingTypes);
		return data.settingTypes;
	}

	async mutateDataSettingsLine(input: DataMutate<Setting>) {
		const data = await this.callApi(this.app.adapter.mutateDataSettings, input).catch((err) => {
			if (!err) return undefined;
			this._storeError(err);
			return undefined;
		});
		if (!data) return false;
		this.update(data.mutateSaveSettingLine.setting);
		return true;
	}
}

export class SettingsDatasetEntity extends RelatedDatasetEntity {
	public data: { id: string, name: string, settings: SettingEntity[] };
	constructor(app: Core, parent: SettingsDataEntity, obj: ResponseDataSettings['datasetSettings'][number]) {
		super(app, parent);
		this.data = {
			...obj,
			settings: obj.settings.map(e => new SettingEntity(app, this, e))
		};
	}

	/*paste(setting: SettingEntity, name: keyof Setting, value: string): void {
		let idx = this.data.settings.indexOf(setting) - 1;
		const propIdx = this.data.settings[idx].tablePasteOrder.indexOf(name);
		const block = value.trim();
		let n;
		for (const line of block.split('\r\n')) {
			idx++;
			n = propIdx - 1;
			if (!this.data.settings[idx]) continue;
			for (const entry of line.split('\t')) {
				n++;
				const prop = this.data.settings[idx].tablePasteOrder[n];
				if (!prop) continue;
				const input = this.data.settings[idx].data[prop];
				if (typeof input === 'object') input.set(entry);
			}
		}
	}*/
}

export class SettingEntity extends ValueEntity {
	public data: InputProperty<Setting>;
	public readonly tablePasteOrder: (keyof InputProperty<Setting>)[] = ['value', 'quality', 'comment'];

	constructor(app: Core, parent: SettingsDatasetEntity, obj: Setting) {
		super(app, parent);
		this.data = this.define(obj);
	}
	define(obj: Setting): InputProperty<Setting> {
		const update = {
			...obj,
			value: injectInputSetter('value', numNullToString(obj.value), this),
			quality: injectInputSetter('quality', numNullToString(obj.quality), this),
			comment: injectInputSetter('comment', obj.comment ?? '', this),
		};
		if (this.isChanged.includes('value')) update.value.set(this.data.value.get());
		if (this.isChanged.includes('quality')) update.quality.set(this.data.quality.get());
		if (this.isChanged.includes('comment')) update.comment.set(this.data.comment.get());
		this.data = update;
		return this.data;
	}

	save (): void {
		this.app.entities.settings.mutateDataSettingsLine({
			id: this.data.id,
			values: {
				value: this.data.value.get().length ? parseFloat(this.data.value.get()) : null,
				quality: this.data.quality.get().length ? parseFloat(this.data.quality.get()) : null,
				comment: this.data.comment.get(),
			}
		}).then((success) => {
			this.event.emit('change', success);
		});
	}
}
