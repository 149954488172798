import React from 'react';
import { IUserDashboardCard } from '../../../domain/interfaces/IUserDashboard';
import { IModal } from '../../../domain/interfaces/IModal';
import { translate } from '../../../infrastructure/translations/translate';
import Spinner from '../../../viewComponents/utils/Spinner/Spinner';
import { useUserDashboardOwning } from '../UserDashboardOwning';
import { useUsersDashboard } from '../../../viewComponents/hooks/useUsersDashboard';
import { useModal } from '../../../viewComponents/modal/useModal';

export const createDashboardCardModifyModal = (open: boolean, setOpen: (value: boolean) => void, card: IUserDashboardCard): IModal | undefined => {
	const { entity: userDashboardEntity } = useUsersDashboard();
	const [name, setName] = React.useState<string>(card.name);
	const [loading, setLoading] = React.useState<boolean>(false);

	const clear = () => {
		setLoading(false);
		setOpen(false);
		useModal(false, { body: undefined, header: undefined, visible: false });
	};

	const handleModify = () => {
		setLoading(true);
		userDashboardEntity?.mutateEditUserDashboardCard({
			id: card.id,
			name,
			dashboardId: card.dashboard.id,
			type: card.type,
			data: card.data,
			delete: false
		}).then(() => {
			clear();
		}).catch((err) => {
			if (!err) return;
		});
	};

	if (!open) return;

	const body = (<div className="dashboard_pin_modal_list">
		{!loading && <div className="dashboard_pin_modal_name">
			<input
				type="text"
				placeholder={translate('dashboardPinModal.name') as string}
				value={name}
				onChange={(e) => setName(e.target.value)}
			/>
		</div>}
		{loading && <Spinner/>}
	</div>);

	return {
		action: handleModify,
		actionText: 'save',
		body,
		header: translate('dashboard.card.modify'),
		onClose(): void {
			clear();
		},
		visible: true
	};
};

export const DashboardCardModifyButton = ({ card }: { card: IUserDashboardCard }) => {
	const owning = useUserDashboardOwning(card.dashboard.id);
	const [openModalModify, setOpenModalModify] = React.useState<boolean>(false);

	useModal(openModalModify, createDashboardCardModifyModal(openModalModify, setOpenModalModify, card));
	
	return (owning ? <button
		type={'button'}
		className={'button_blank'}
		onClick={() => setOpenModalModify(true)}
	>
		<div className="dashboard_icon"><i className="fa-solid fa-pen"/></div>
		<div className="text">{translate('edit')}</div>
	</button> : null);
};