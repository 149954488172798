import React from 'react';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import { GroupByBlock } from './GroupBy/GroupBy';

export type DataImpactsType = {
	block: string;
	category: string;
	equipment_type: string;
	index: number;
	indicator: string;
	indicator_pb: number;
	life_cycle_step?: string;
	unit: string;
	value: number;
};
export type ResultImpactsType = { schema: { fields: { name: string, type: string }[], primaryKey: string[], pandas_version: string }, data: DataImpactsType[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultImpacts = (o: any): o is ResultImpactsType => {
	if (o && typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDataImpacts = (o: any): o is DataImpactsType[] => {
	if (o && Array.isArray(o)) return o.length > 0
		&& 'block' in o[0]
		&& 'category' in o[0]
		&& 'equipment_type' in o[0]
		&& 'index' in o[0]
		&& 'indicator' in o[0]
		&& 'indicator_pb' in o[0]
		&& 'unit' in o[0]
		&& 'value' in o[0];
	return false;
};

export const ResultImpacts = ({ data }: { data: string }): JSX.Element => {
	if (!isResultImpacts(data)) return (<ResultCardError/>);

	// transform the data from id to name
	const transformedData = useTransformedResultTableData(data.data);

	if (transformedData instanceof Error) return <ResultCardError/>;
	return <GroupByBlock
		transformedData={transformedData}
	/>;
};