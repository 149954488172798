import { Entity } from '../core/Entity';
import { IBlock } from '../interfaces/IBlock';
import { ApolloClientMutateBlockOrderManagement, ApolloClientQueryBlocks } from '../../infrastructure/ApolloClass/BlockClass';
import { QUERY_BLOCKS } from '../../infrastructure/ApolloClient/requests/QUERY_BLOCKS';
import { MUTATE_BLOCK_ORDER_MANAGEMENT } from '../../infrastructure/ApolloClient/requests/MUTATE_BLOCK_ORDER_MANAGEMENT';
import { IMutateBlockOrderManagementInput } from '../interfaces/IMutateBlockOrderManagement';
import { ApiError } from '../core/ApiError';

export class BlockEntity extends Entity {
	public data: IBlock[] | undefined;
	public isQuerying = false;

	initialization() {
		this.app.adapter.queryBlocks ??= this.app.installer(ApolloClientQueryBlocks, QUERY_BLOCKS);
		this.app.adapter.mutateBlockOrderManagement ??= this.app.installer(ApolloClientMutateBlockOrderManagement, MUTATE_BLOCK_ORDER_MANAGEMENT);
		this.app.adapter.storeDataBlocks?.({ loading: false, data: this, error: null });
	}

	get(): IBlock[] | undefined {
		if (!this.data) {
			if (this.isQuerying) return;
			this.queryBlocks();
		}
		return this.data;
	}

	change(): Promise<void> | undefined {
		if (!this.data) return Promise.resolve();
		this.data.sort((a, b) => {
			return a.order - b.order;
		});
		return this.app.adapter.storeDataBlocks?.({ loading: false, data: this, error: null });
	}

	set(obj: IBlock[] | undefined): void {
		this.data = obj;
		this.change();
	}

	update(data: IBlock[]): void {
		if (!data) return;
		data.forEach((item) => {
			if (this.data === undefined) this.data = [];
			const idx = this.data.findIndex((b) => b.id === item.id);
			if (idx === undefined || idx === -1) {
				this.data = [...this.data, item];
			} else {
				this.data[idx] = { ...item };
			}
		});
		this.change();
	}

	addColors(data: {itemId: string, color:string}[]) {
		if (!this.data) return;
		data.forEach((item) => {
			const block = this.data?.find((b) => b.id === item.itemId);
			if (block) block.color = item.color;
		});
		this.change();
	}

	clearColors() {
		if (!this.data) return;
		this.data.forEach((item) => {
			item.color = undefined;
		});
		this.change();
	}

	private _storeError(error: Error | ApiError) {
		this.app.adapter.storeDataBlocks?.({ loading: false, data: this, error });
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryBlocks() {
		this.app.adapter.storeDataBlocks?.({ loading: true, data: this, error: null });
		this.isQuerying = true;
		const data = await this.callApi(this.app.adapter.queryBlocks).catch((error) => {
			this.isQuerying = false;
			if (!error) return undefined;
			this._storeError(error);
			return undefined;
		});
		this.isQuerying = false;
		if (!data) return;
		this.set(data.blocks);
	}

	async mutateBlockOrderManagement(input: IMutateBlockOrderManagementInput): Promise<void> {
		const data = await this.callApi(this.app.adapter.mutateBlockOrderManagement, input);
		this.update(data.mutateBlockOrderManagement.blocks);
	}
}
