import { adapter } from '../index';
import React, { Fragment } from 'react';
import { en } from './languages/en';
import { fr } from './languages/fr';

export type Language = { [key: string]: string };
export type TranslateChange = { [key: string]: string | ((sequence: string) => string | React.ReactElement) }
const devMode = process.env.NODE_ENV === 'development';

/**
 * Translate component
 *
 * Used to translate strings in the application.
 * can be used as a component or a function.
 * with change parameter, you can change format with ReactElement.
 *
 * @param index
 * @param change
 * @param lang
 *
 * @author Maximilien Valenzano
 */
export function translate(index: string, change?: TranslateChange | string, lang?: string): string | React.ReactElement {
	// Get language, if change is a string, it's a language, or use default language
	lang = typeof change === 'string' ? change : lang || adapter.getLang?.() || 'en';
	// Get translation if exist or return empty object
	const translateChange = (typeof change === 'string' ? {} : change) || {};
	// Verify if language exist or set default language
	let language;
	switch (lang) {
		case 'fr':
			language = fr;
			break;
		default:
			language = en;
	}
	if (language) {
		let translate = language[index];
		if (!translate) {
			// If translate not exist, return index and log error
			if (devMode) console.error(`Translate for '${index}' in '${lang}' missing`);
			translate = en[index];
			if (!translate && devMode) console.error(`Translate for '${index}' in 'en' missing`);
		} else if (change) {
			// If change object exist, search for pattern and split
			// pattern is &non-space(non-parenthesis) so &test(text to replace) will be caught
			// for better explanation, see https://regex101.com/ with pattern
			const sequences: string[] = translate.split(/(&\S+\([^()]*\))/g);
			const list: (string | React.ReactElement)[] = [];
			for (const seq of sequences) {
				let changed = false;
				// If sequence is a pattern, search for it in change object
				for (const itr in translateChange) {
					const match = seq.match(new RegExp(`&${itr}[(]([^()]*)[)]`));
					// If pattern exist, replace it with the value of the change object and add it to the list
					if (match) {
						changed = true;
						const c = translateChange[itr];
						const val = typeof c === 'string' ? c : c(match[1]);

						// Replace new lines \n with <br> in text elements
						if (React.isValidElement(val)) {
							const element = val as React.ReactElement;
							if (typeof element.props.children === 'string') {
								const textParts = element.props.children.split('\n');
								const newChildren = textParts.reduce((acc: React.ReactNode[], part: string, i: number) => {
									if (i !== 0) acc.push(<br key={`br-${i}`} />);
									acc.push(part);
									return acc;
								}, []);

								list.push(React.cloneElement(element, { ...element.props, children: newChildren }));
							} else {
								list.push(element);
							}
						} else {
							list.push(val);
						}
					}
				}
				// If sequence is not a pattern, add it to the list
				if (!changed) {
					const val = seq.replace(/&\S+\((.*)\)/, '$1');
					list.push(val);
				}
			}
			// Return a fragment with the list
			return (<>{list.map((l, idx) => <Fragment key={idx}>{l}</Fragment>)}</>);
		}
		// if change object not exist, return translate or index if not exist
		return translate?.replace(/&\S+\(([^()]*)\)/g, '$1') ?? index;
	} else if (devMode) {
		console.error(`Language '${lang}' not found for '${index}'`);
	}
	return index;
}
