import { gql } from '@apollo/client';

export const MUTATE_USER_MANAGEMENT = gql`
    mutation MUTATE_USER_MANAGEMENT(
        $id: String!,
        $username: String!,
        $firstName: String,
        $lastName: String,
        $comment: String,
        $email: String!,
        $ssoEmail: String,
        $phoneNumber: String,
        $company: String!,
        $groups: [String]!,
        $datasets: [String!]!,
        $remove: Boolean!,
        $isActive: Boolean!,
        $mustAcceptCgu: Boolean!,
        $groupByAccess: Boolean!
    ){
        mutateUserManagement(
            id: $id,
            username: $username,
            firstName: $firstName,
            lastName: $lastName,
            comment: $comment,
            email: $email,
            ssoEmail: $ssoEmail,
            phoneNumber: $phoneNumber,
            company: $company,
            groups: $groups,
            datasets: $datasets,
            remove: $remove,
            isActive: $isActive,
            mustAcceptCgu: $mustAcceptCgu,
            groupByAccess: $groupByAccess
        ){
            ok,
            user {
                id,
                username,
                firstName,
                lastName,
                comment,
                email,
                phoneNumber,
                mustAcceptCgu,
                hasSeenUpdate,
                groups {
                    id,
                    name
                    translate
                    permissions
                    grade
                    privileged
                    manageSameGrade
                }
                permissions
                myCompany {
                    id,
                    name,
                    emailDomains
                },
                isConsultant,
                isSuperuser,
                isStaff,
                datasets {
                    id
                },
                blockModify,
                isActive,
                companiesToManage {
                    id,
                    name,
                    emailDomains
                },
                groupByAccess,
            }
        }
    }
`;

