import { store } from './index';
import { IUserDTO } from '../domain/interfaces/DTO/IUserDTO';
import { ResponseData } from '../domain/data/ResponseDatas';
import { SettingsDataEntity } from '../domain/entities/SettingsEntity';
import { MaturityDataEntity } from '../domain/entities/MaturityEntity';
import { InventoryDataEntity } from '../domain/entities/InventoryEntity';
import { ValidationDataEntity } from '../domain/entities/ValidationEntity';
import { StudiesEntity } from '../domain/entities/StudiesEntity';
import { StaticPagesEntity } from '../domain/entities/StaticPagesEntity';
import { ICompanyDTO } from '../domain/interfaces/DTO/ICompanyDTO';
import { UserAssignmentsEntity } from '../domain/entities/UserAssignmentsEntity';
import { LambdaInventoryEntity } from '../domain/entities/LambdaInventoryEntity';
import { INotification } from '../domain/interfaces/INotification';
import { IModal } from '../domain/interfaces/IModal';
import { DashboardPayload, TooltipStateReact } from './reducers/currentDatas';
import { IUserNotification } from '../domain/interfaces/IUserNotification';
import { IndicatorEntity } from '../domain/entities/IndicatorEntity';
import { LifeCycleStepEntity } from '../domain/entities/LifeCycleStepEntity';
import { SampleEntity } from '../domain/entities/SampleEntity';
import { UserDashboardEntity } from '../domain/entities/UserDashboardEntity';
import { DashboardEntity } from '../domain/entities/DashboardEntity';
import { BlockEntity } from '../domain/entities/BlockEntity';
import { DatasetsEntity } from '../domain/entities/DatasetsEntity';
import { LifeCycleAssessmentEntity } from '../domain/entities/LifeCycleAssessmentEntity';
import { ResultsEntity } from '../domain/entities/ResultsEntity';
import { ResultLaunchersEntity } from '../domain/entities/ResultLaunchersEntity';
import { ManageEquipmentEntity } from '../domain/entities/ManageEquipmentEntity';
import { CmdbEntity } from '../domain/entities/CmdbEntity';
import { ApiErrorJson } from '../domain/interfaces/ApiErrorJson';

export type ChangeCallBackFunctionArgumentsType<T extends ResponseData> = { loading: boolean, error: Error | null, data: T | undefined }
// User identification
export const changeLoad = (load: boolean): void => {
	store.dispatch({ type: 'setLoad', payload: load });
};
export const changeAppVersion = (version: string): void => {
	store.dispatch({ type: 'setAppVersion', payload: version });
};
export const changeLogged = (user: IUserDTO): void => {
	store.dispatch({ type: 'setLogged', payload: user });
};
export const changeCompany = (company: ICompanyDTO): void => {
	store.dispatch({ type: 'setCompany', payload: company });
};
export const changeUserAssignments = (userAssignments: UserAssignmentsEntity | null): void => {
	store.dispatch({ type: 'setUserAssignments', payload: userAssignments });
};

// Page situation
export const changeStudyID = (id: string | null | undefined): void => {
	store.dispatch({ type: 'setStudyID', payload: id });
};
export const changeDatasetID = (id: string | null | undefined): void => {
	store.dispatch({ type: 'setDatasetID', payload: id });
};
export const changePageParams = (studyID: string | null, datasetID: string | null): void => {
	changeStudyID(studyID);
	changeDatasetID(datasetID);
};

// Data import to redux
export const changeStudiesStatusData = (status: ChangeCallBackFunctionArgumentsType<StudiesEntity>): void => {
	store.dispatch({ type: 'studies', payload: status });
};
export const changeDatasetsStatusData = (status: ChangeCallBackFunctionArgumentsType<DatasetsEntity>): void => {
	store.dispatch({ type: 'datasets', payload: status });
};
export const changeSettingsStatusData = (status: ChangeCallBackFunctionArgumentsType<SettingsDataEntity>): void => {
	store.dispatch({ type: 'settings', payload: status });
};
export const changeMaturityStatusData = (status: ChangeCallBackFunctionArgumentsType<MaturityDataEntity>): void => {
	store.dispatch({ type: 'maturity', payload: status });
};
export const changeInventoryStatusData = (status: ChangeCallBackFunctionArgumentsType<InventoryDataEntity>): void => {
	store.dispatch({ type: 'inventory', payload: status });
};
export const changeManageEquipmentStatusData = (status: ChangeCallBackFunctionArgumentsType<ManageEquipmentEntity>): void => {
	store.dispatch({ type: 'equipment', payload: status });
};
export const changeResultsStatusData = (status: ChangeCallBackFunctionArgumentsType<ResultsEntity>): void => {
	store.dispatch({ type: 'results', payload: status });
};
export const changeResultLaunchersStatusData = (status: ChangeCallBackFunctionArgumentsType<ResultLaunchersEntity>): void => {
	store.dispatch({ type: 'resultLaunchers', payload: status });
};
export const changeIndicatorsStatusData = (status: ChangeCallBackFunctionArgumentsType<IndicatorEntity>): void => {
	store.dispatch({ type: 'indicators', payload: status });
};
export const changeLifeCycleStepsStatusData = (status: ChangeCallBackFunctionArgumentsType<LifeCycleStepEntity>): void => {
	store.dispatch({ type: 'lifeCycleSteps', payload: status });
};
export const changeLcaStatusData = (status: ChangeCallBackFunctionArgumentsType<LifeCycleAssessmentEntity>): void => {
	store.dispatch({ type: 'lca', payload: status });
};
export const changeModalStatusData = (status: ChangeCallBackFunctionArgumentsType<IModal>): void => {
	store.dispatch({ type: 'modal', payload: status });
};
export const changeBlocksStatusData = (status: ChangeCallBackFunctionArgumentsType<BlockEntity>): void => {
	store.dispatch({ type: 'blocks', payload: status });
};
export const changeValidationStatusData = (status: ChangeCallBackFunctionArgumentsType<ValidationDataEntity>): void => {
	store.dispatch({ type: 'validation', payload: status });
};
export const changeLambdaInventoryStatusData = (status: ChangeCallBackFunctionArgumentsType<LambdaInventoryEntity>): void => {
	store.dispatch({ type: 'lambdaInventory', payload: status });
};
export const changeSampleListStatusData = (status: ChangeCallBackFunctionArgumentsType<SampleEntity>): void => {
	store.dispatch({ type: 'sampleList', payload: status });
};

export const changeStaticPageStatusData = (status: ChangeCallBackFunctionArgumentsType<StaticPagesEntity>): void => {
	store.dispatch({ type: 'staticPage', payload: status });
};

export const changeUserDashboardStatusData = (status: ChangeCallBackFunctionArgumentsType<UserDashboardEntity>): void => {
	store.dispatch({ type: 'userDashboards', payload: status });
};

export const changeDashboardStatusData = (status: ChangeCallBackFunctionArgumentsType<DashboardEntity>): void => {
	store.dispatch({ type: 'dashboards', payload: status });
};

export const changeCmdbDataStatusData = (status: ChangeCallBackFunctionArgumentsType<CmdbEntity>): void => {
	store.dispatch({ type: 'cmdb', payload: status });
};

// set data to redux
export const setTemporaryAssignedUsersEmail = (list: string[]) => {
	store.dispatch({ type: 'setTemporaryAssignedUsersEmail', payload: list });
};

// Notification - Can handle ApiError
export const changeNotification = (notification: ApiErrorJson | INotification | undefined): void => {
	if (!notification) {
		store.dispatch({ type: 'setNotification', payload: [] });
		return;
	}

	const notifications = store.getState().notifications;
	const alreadyExists = notifications?.find((n) => {
		if ('key' in notification && 'key' in n) {
			return n.key === notification.key || n.id === notification.id;
		}
		return n.id === notification.id;
	});
	if (alreadyExists) {
		return;
	}

	let newNotifications: (ApiErrorJson | INotification)[];
	if (notifications) {
		newNotifications = [...notifications, notification];
	} else {
		newNotifications = [notification];
	}
	store.dispatch({ type: 'setNotification', payload: newNotifications });
};

export const removeNotification = (notification: ApiErrorJson | INotification): void => {
	const notifications = store.getState().notifications;
	let newNotifications: (ApiErrorJson | INotification)[];
	if (notifications) {
		newNotifications = notifications.filter((n) => n.id !== notification.id);
	} else {
		newNotifications = [];
	}
	store.dispatch({ type: 'setNotification', payload: newNotifications });
};

export const changeUserNotification = (notification: IUserNotification): void => {
	const notifications = store.getState().userNotifications;
	let newNotifications: IUserNotification[];
	if (notifications) {
		newNotifications = [...notifications, notification];
	} else {
		newNotifications = [notification];
	}
	store.dispatch({ type: 'setUserNotification', payload: newNotifications });
};

export const removeUserNotification = (notification: IUserNotification): void => {
	const notifications = store.getState().userNotifications;
	let newNotifications: IUserNotification[];
	if (notifications) {
		newNotifications = notifications.filter((n) => n.title !== notification.title);
	} else {
		newNotifications = [];
	}
	store.dispatch({ type: 'setUserNotification', payload: newNotifications });
};

export const changeError = (error: Error | null): void => {
	store.dispatch({ type: 'setError', payload: error });
};

export const changeDashboard = (payload: DashboardPayload): void => {
	store.dispatch({ type: 'setDashboard', payload });
};

export const setTooltipState = (tooltipState: TooltipStateReact): void => {
	store.dispatch({ type: 'setTooltipState', payload: tooltipState });
};

// Result page Indicator selection
export const changeSelectedIndicatorsForAll = (selectedIndicatorsForAll: string[]): void => {
	store.dispatch({ type: 'setSelectedIndicatorsForAll', payload: selectedIndicatorsForAll });
};
