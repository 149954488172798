import { gql } from '@apollo/client';

export const QUERY_COMPANY_USERS = gql`
    query QUERY_COMPANY_USERS {
        myCompany {
            userList {
                id,
                username,
                firstName,
                lastName,
                comment,
                email,
                ssoEmail,
                groups {
                    id,
                    name,
                    grade
                }
                myCompany {
                    id,
                    name,
                    emailDomains,
                    isCguAccepted
                },
                isConsultant,
                isSuperuser,
                isStaff,
                datasets {
                    id
                },
                blockModify,
                isActive,
                companiesToManage {
                    id
                }
                mustAcceptCgu
                groupByAccess
            },
            lambdaList {
                id,
                email
            }
        }
    }
`;
