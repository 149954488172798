import { ApiErrorDisplayType, ApiErrorJson, ApiErrorVariable } from '../interfaces/ApiErrorJson';
import { v4 as uuid } from 'uuid';

export class ApiError extends Error {
	public json: ApiErrorJson;

	constructor(public apiError: Partial<ApiErrorJson>) {
		super(JSON.stringify(apiError));
		this.name = 'ApiError';
		this.json = {
			id: apiError.id ?? uuid(),
			key: apiError.key ?? 'api.error',
			displayType: apiError.displayType ?? 'notification',
			type: apiError.type ?? 'error',
			variables: apiError.variables ?? [],
			href: apiError.href
		};

		// Useful for instanceof
		Object.setPrototypeOf(this, ApiError.prototype);
	}

	getKey(): string {
		return this.json.key;
	}

	getVariables(): ApiErrorVariable[] {
		return this.json.variables ?? [];
	}

	getDisplayType(): ApiErrorDisplayType {
		return this.json.displayType ?? 'notification';
	}
}