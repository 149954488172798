import { gql } from '@apollo/client';

export const MUTATE_ADD_EQUIPMENT_FROM_CMDB = gql`
    mutation MutateAddEquipmentFromCmdb(
      $data: String!
	  $studyId: String!
      $toStudy: Boolean!
      $cmdbName: String!
      $datasetId: String
      $cmdbCategory: String
	  $multipleDatasets: Boolean
    ) {
    	mutateAddEquipmentFromCmdb (
    		data: $data
			studyId: $studyId
    		toStudy: $toStudy
    		cmdbName: $cmdbName
    		datasetId: $datasetId
    		cmdbCategory: $cmdbCategory
			multipleDatasets: $multipleDatasets
		) {
			rejectedRows,
        	data {        
            	id,
            	name,
            	shortName,
            	equipments {
            	    id,
            	    name,
            	    help,
            	    unit,
					unitShortName,
            	    priority,
            	    specification,
                	allocationFactor,
            	    quantity,
                	weightedQuantity,
            	    quality,
            	    unknown
            	    lifetime,
            	    internalLifetime,
            	    reusePart,
            	    reuseLifetime,
					customElectricityConsumption,
            	    comment,
            	    assigned,
            	    assignedCount,
            	    forbidden,
            	    equipmentTypeId,
            	    flag,
            	    category,
            	    categoryIdentifier
            	    cmdb,
            	    defaultLifetime,
            	    isRelatedToResults,
            	    matchingName,
                	matchingScore
					deprecated,
            	},
            	cmdb {
            		id,
            		name,
            		category,
					categoryId,
            		comment,
					equipmentsInfo {
						category,
						categoryIdentifier,
						quantity,
                		weightedQuantity,
						totalLifetime, 
						equipmentLineCount,
						lifetime,
						allocationFactor,
						quality,
						internalLifetime,
						reusePart,
						reuseLifetime,
						defaultLifetime
					}
				},
            	metadata {
            	    equipmentRate
            	},
            	priority
            },
		}
    }
`;
