import { gql } from '@apollo/client';

export const QUERY_LAMBDA_VERIFY_LINK = gql`
	query lambdaVerifyLink($token: String!) {
		lambdaVerifyLink(token: $token) {
			ok,
            lines {
                id,
                blockId,
                blockName,
                studyId,
                studyName,
                datasetId,
                datasetName,
                collectOpen,
                datasetArchived,
                name,
                specification,
                help,
                unit,
                priority,
                quantity,
                unknown,
                quality,
                lifetime,
                internalLifetime,
                reusePart,
                reuseLifetime,
                comment,
            }
        }
    }
`;
