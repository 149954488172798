import { gql } from '@apollo/client';

export const MUTATE_PASSWORD_CHANGE = gql`
    mutation PasswordChange(
        $oldPassword: String!,
        $newPassword: String!,
        $newPasswordVerify: String!
    ) {
        mutatePasswordChange(
            oldPassword: $oldPassword,
            newPassword: $newPassword,
            newPasswordVerify: $newPasswordVerify
        ) {
            token
        }
    }
`;
