import { gql } from '@apollo/client';

export const MUTATE_PASSWORD_RESET = gql`
    mutation PasswordReset(
        $token: String!,
        $newPassword: String!,
        $newPasswordVerify: String!
    ) {
        mutatePasswordReset(
            token: $token,
            newPassword: $newPassword,
            newPasswordVerify: $newPasswordVerify
        ) {
            ok
        }
    }
`;
