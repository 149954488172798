import { gql } from '@apollo/client';

export const QUERY_LAMBDA_VERIFY_CODE = gql`
	query lambdaVerifyCode(
		$token: String!
		$code: String!
	) {
		lambdaVerifyCode(
			token: $token
			code: $code
		) {
			ok
		}
	}
`;
