import { gql } from '@apollo/client';

export const MUTATE_SETTINGS_LINE = gql`
    mutation SaveSettingLine(
      $id: String!,
      $values: SettingInput!
    ) {
      mutateSaveSettingLine(
          id: $id,
          values: $values
      ) {
          setting {
              id,
              name,
              help,
              priority,
              unit,
              value,
              quality,
              comment
          }
      }
    }
`;
