import React from 'react';
import { IModal } from '../../../domain/interfaces/IModal';
import { translate } from '../../../infrastructure/translations/translate';
import imageTableCreation from '../../../media/dashboard/table_creation.svg';
import './DashboardTableCreationModal.scss';
import { useUsersDashboard } from '../../../viewComponents/hooks/useUsersDashboard';
import { useModal } from '../../../viewComponents/modal/useModal';

export const createDashboardTableCreationModal = (open: boolean, setOpen: (value: boolean) => void, returnTable?: (value: string) => void): IModal | undefined => {
	const { entity: userDashboardEntity } = useUsersDashboard();
	const [name, setName] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	const clear = () => {
		setName('');
		setDescription('');
		useModal(false, { visible: false, body: undefined, header: '' });
	};

	if (!open) return;

	const handleCreate = () => {
		if (!name || !description) return;
		setLoading(true);
		userDashboardEntity?.mutateEditUserDashboard({
			id: 'new',
			name,
			description,
			delete: false,
		}).then((dashboard) => {
			returnTable?.(dashboard.id);
			setLoading(false);
			clear();
			setOpen(false);
		}).catch((err) => {
			if (!err) {
				clear();
				return;
			}
		});
	};

	const header = translate('dashboard.tableCreationModal.header');
	const body = <div className={'dashboard_modal_table_creation'}>
		<div className="dashboard_modal_table_creation_image">
			<img src={imageTableCreation} alt="table_creation" />
		</div>
		<div className="dashboard_modal_table_creation_form">
			<div className="dashboard_modal_table_creation_form_input">
				<label htmlFor="name">{translate('name')} ({translate('required')})</label>
				<input type="text" id="name" maxLength={40} value={name} onChange={e => setName(e.target.value)} />
			</div>
			<div className="dashboard_modal_table_creation_form_input">
				<label htmlFor="description">{translate('description')} ({translate('required')})</label>
				<input type="text" id="description" maxLength={100} value={description} onChange={e => setDescription(e.target.value)} />
			</div>
		</div>
	</div>;

	return {
		action: handleCreate,
		actionText: 'validate',
		body,
		header,
		onClose(): void {
			clear();
			setOpen(false);
		},
		load: loading,
		visible: true
	};
};