import {
	RelatedDatasetEntity,
	Entity,
	injectInputSetter,
	InputProperty,
	ValueEntity,
	DataMutate
} from '../core/Entity';
import { numNullToString } from '../../utils/numNullToString';
import { Core } from '../core/Core';
import { ResponseDataMaturity } from '../data/ResponseDataMaturity';
import { GoodPractice } from '../data/entries/GoodPractice';
import { ResponseMutateLineMaturitySave } from '../data/ResponseDatas';
import {
	ApolloClientMutateLineMaturity,
	ApolloClientMutateMaturityDomainsOrder, ApolloClientMutateSetMaturityGoodPractices,
	ApolloClientQueryDataMaturity,
	ApolloClientQueryGoodPractices,
	ApolloClientQueryMaturityDomains
} from '../../infrastructure/ApolloClass/MaturityClass';
import { QUERY_DATA_MATURITY } from '../../infrastructure/ApolloClient/requests/QUERY_DATA_MATURITY';
import { MUTATE_MATURITY_LINE } from '../../infrastructure/ApolloClient/requests/MUTATE_MATURITY_LINE';
import { MUTATE_MATURITY_DOMAINS_ORDER } from '../../infrastructure/ApolloClient/requests/MUTATE_MATURITY_DOMAINS_ORDER';
import { QUERY_GOOD_PRACTICES } from '../../infrastructure/ApolloClient/requests/QUERY_GOOD_PRACTICES';
import { QUERY_MATURITY_DOMAINS } from '../../infrastructure/ApolloClient/requests/QUERY_MATURITY_DOMAINS';
import { IMutateMaturityDomainsOrderInput } from '../interfaces/IMutateMaturityDomainsOrder';
import { IMaturityDomain } from '../interfaces/IMaturityDomain';
import { IGoodPractice } from '../interfaces/IGoodPractice';
import { store } from '../../store';
import { ApiError } from '../core/ApiError';
import { MUTATE_SET_MATURITY_GOOD_PRACTICES } from '../../infrastructure/ApolloClient/requests/MUTATE_SET_MATURITY_GOOD_PRACTICES';

export class MaturityDataEntity extends Entity {
	public data: undefined | MaturityDatasetEntity[];
	public domains: undefined | IMaturityDomain[];
	private _isQuerying = false;

	initialization() {
		this.app.adapter.fetchDataMaturity ??= this.app.installer(ApolloClientQueryDataMaturity, QUERY_DATA_MATURITY);
		this.app.adapter.mutateDataMaturity ??= this.app.installer(ApolloClientMutateLineMaturity, MUTATE_MATURITY_LINE);
		this.app.adapter.mutateMaturityDomains ??= this.app.installer(ApolloClientMutateMaturityDomainsOrder, MUTATE_MATURITY_DOMAINS_ORDER);
		this.app.adapter.mutateSetMaturityGoodPractices ??= this.app.installer(ApolloClientMutateSetMaturityGoodPractices, MUTATE_SET_MATURITY_GOOD_PRACTICES);
		this.app.adapter.queryGoodPractices ??= this.app.installer(ApolloClientQueryGoodPractices, QUERY_GOOD_PRACTICES);
		this.app.adapter.queryMaturityDomains ??= this.app.installer(ApolloClientQueryMaturityDomains, QUERY_MATURITY_DOMAINS);
		this.app.adapter.storeDataMaturity?.({ loading: false, error: null, data: this });
	}

	get(): MaturityDatasetEntity[] | undefined {
		if (!this.data) {
			if (this._isQuerying) return;
			this.queryDataMaturity();
		}
		return this.data;
	}

	set(obj: ResponseDataMaturity['datasetMaturity'] | undefined): void {
		this.data = obj?.map(i => new MaturityDatasetEntity(this.app, this, i));
	}

	change(): Promise<void> | undefined {
		if (this.domains) {
			this.domains.sort((a, b) => {
				return a.order - b.order;
			});
		}
		return this.app.adapter.storeDataMaturity?.({ loading: false, error: null, data: this });
	}

	update(line: ResponseMutateLineMaturitySave['mutateSaveGoodPracticeLine']['goodPractice']) {
		const goodPractice = this.data?.reduce<MaturityEntity | undefined>(
			(vp, vc) => vp ?? vc.data.goodPractices.reduce<MaturityEntity | undefined>(
				(ep, ec) => ep ?? (ec.data.id === line.id ? ec : undefined),
				undefined),
			undefined);
		if (!goodPractice) return;
		goodPractice.define(line);
	}

	private _storeError(error: Error | ApiError) {
		this.app.adapter.storeDataMaturity?.({ loading: false, error, data: this });
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryDataMaturity(): Promise<MaturityDatasetEntity[]> {
		this.app.adapter.storeDataMaturity?.({ loading: true, error: null, data: this });
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.fetchDataMaturity, { datasetId: store.getState().datasetID || '' })
			.catch(err => {
				this.set([]);
				if (!err) this.app.adapter.storeDataMaturity?.({ loading: false, error: null, data: this });
				else this._storeError(err);
				return undefined;
			});
		this._isQuerying = false;
		if (!data) return [];

		this.set(data.datasetMaturity);
		this.change();
		return this.data ?? [];
	}

	async mutateDataMaturityLine(input: DataMutate<GoodPractice>): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutateDataMaturity, input).catch(err => {
			if (!err) return undefined;
			this._storeError(err);
			return undefined;
		});
		if (!data) return false;
		this.update(data.mutateSaveGoodPracticeLine.goodPractice);
		this.app.entities.datasets.updateMaturityStatus(data.mutateSaveGoodPracticeLine.dataset.id, data.mutateSaveGoodPracticeLine.dataset.status.maturity);
		return true;
	}

	async queryGoodPractices(): Promise<IGoodPractice[]> {
		const data = await this.callApi(this.app.adapter.queryGoodPractices);
		return data.goodPractices;
	}

	async mutateMaturityDomainsOrder(input: IMutateMaturityDomainsOrderInput): Promise<IMaturityDomain[]> {
		if (!input.maturityIds.every(id => this.domains?.find(d => d.id === id))) {
			await this.queryMaturityDomains();
		}
		const data = await this.callApi(this.app.adapter.mutateMaturityDomains, input);
		this.domains = data.mutateMaturityDomainsOrder.domains;
		this.change();
		return this.domains;
	}

	async queryMaturityDomains(ids?: string[]): Promise<IMaturityDomain[]> {
		if (ids && ids?.length > 0 && this.domains && ids.every(id => this.domains?.find(d => d.id === id))) {
			return this.domains.filter(d => ids.includes(d.id));
		}
		const data = await this.callApi(this.app.adapter.queryMaturityDomains);
		this.domains = data.maturityDomains;
		this.change();
		return this.domains;
	}

	async mutateSetMaturityGoodPractices(datasetId: string) {
		const data = await this.callApi(this.app.adapter.mutateSetMaturityGoodPractices, { datasetId });
		this.app.entities.datasets.updateMaturityStatus(data.mutateSetMaturityGoodPractices.dataset.id, data.mutateSetMaturityGoodPractices.dataset.status.maturity);
	}
}

export class MaturityDatasetEntity extends RelatedDatasetEntity {
	public data: { id: string, name: string, goodPractices: MaturityEntity[], priority: boolean };

	constructor(app: Core, parent: MaturityDataEntity, obj: ResponseDataMaturity['datasetMaturity'][number]) {
		super(app, parent);
		this.data = {
			...obj,
			goodPractices: obj.goodPractices.map(e => new MaturityEntity(app, this, e))
		};
	}
}

export class MaturityEntity extends ValueEntity {
	public data: InputProperty<GoodPractice>;
	public readonly tablePasteOrder: (keyof InputProperty<GoodPractice>)[] = ['kpiValue', 'grade', 'comment'];

	constructor(app: Core, parent: MaturityDatasetEntity, obj: GoodPractice) {
		super(app, parent);
		this.data = this.define(obj);
	}

	define(obj: GoodPractice): InputProperty<GoodPractice> {
		const update = {
			...obj,
			kpiValue: injectInputSetter('kpiValue', numNullToString(obj.kpiValue), this),
			grade: injectInputSetter('grade', numNullToString(obj.grade), this),
			comment: injectInputSetter('comment', obj.comment ?? '', this),
			flag: injectInputSetter('flag', obj.flag ? 'true' : 'false', this),
		};
		if (this.isChanged.includes('kpiValue')) update.kpiValue.set(this.data.kpiValue.get());
		if (this.isChanged.includes('grade')) update.grade.set(this.data.grade.get());
		if (this.isChanged.includes('comment')) update.comment.set(this.data.comment.get());
		if (this.isChanged.includes('flag')) update.flag.set(this.data.flag.get());
		this.data = update;
		return this.data;
	}

	save(): void {
		const gradeUpdated = this.isChanged.includes('grade');
		this.app.entities.maturity.mutateDataMaturityLine({
			id: this.data.id,
			values: {
				kpiValue: this.data.kpiValue.get().length ? parseFloat(this.data.kpiValue.get()) : null,
				grade: this.data.grade.get().length ? parseFloat(this.data.grade.get()) : 0,
				comment: this.data.comment.get(),
				flag: this.data.flag.get() === 'true',
			}
		})
			.then((success) => {
				if (!success) {
					this.event.emit('change', false);
					return;
				}
				// If the grade for the GP is at least mastered
				if (gradeUpdated && ['4', '5'].includes(this.data.grade.get()) && this.data.kpiValue.get().length === 0) {
					this.app.adapter.storeUserNotification?.({
						title: 'maturity.notification.gradeMastered.title',
						message: 'maturity.notification.gradeMastered.message',
						footer: true
					});
				}
			})
			.catch(() => {
				this.event.emit('change', false);
			});
	}
}
