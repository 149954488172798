import { gql } from '@apollo/client';

export const MUTATE_EDIT_USER_DASHBOARD_CARD = gql`
    mutation MutateEditUserDashboardCard(
        $id: String!
        $name: String!
        $dashboardId: String!
        $type: String!
        $data: JSONString!
        $order: Int
        $delete: Boolean
    ){
        mutateEditUserDashboardCard (
            id: $id
            name: $name
            dashboardId: $dashboardId
            type: $type
            data: $data
            order: $order
            delete: $delete
        ) {
            userDashboardCard {
                id
                name
                dashboard {
                    id
                }
                type
                data
                order
            }
        }
    }
`;
