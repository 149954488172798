import { gql } from '@apollo/client';

export const MUTATE_LAUNCH_COMPUTE = gql`
    mutation LaunchCompute(
        $datasetsIds: [String!]!
        $computedDatasetsIds: [String!]!
        $name: String
        $resultList: String
        $resultTypesIdentifiers: [Int]
        $computeDatasetsIndividually: Boolean,
        $resetImpacts: Boolean
    ) {
        mutateLaunchCompute(
            datasetsIds: $datasetsIds,
            computedDatasetsIds: $computedDatasetsIds,
            name: $name,
            resultList: $resultList,
            resultTypesIdentifiers: $resultTypesIdentifiers,
            computeDatasetsIndividually: $computeDatasetsIndividually,
            resetImpacts: $resetImpacts
        ) {
        	ok
            resultLauncher {
                id,
                name,
                computedDatasets {
                    id
                    name
                }
                computeLogs,
                timestamp,
            }
            computedIndicators
            datasets {
                id,
                name,
                desc,
                archived,
                studyId,
                status {
                    maturity,
                    inventory,
                    validated,
                    results,
                    impactsComplete
                },
                metadata {
                    prioEquipRate,
                    equipmentRate,
                    prioSettingRate,
                    settingRate,
                    maturityRate
                },
                permissions {
                    collectOpen,
                    validationOpen,
                    validate,
                    results
                },
                selectedIndicators,
                validationSampleId,
                hasDefinitiveResults
                hasSettings
            }
        }
    }
`;
