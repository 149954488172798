import React, { Fragment, useEffect } from 'react';
import './DashboardTable.scss';
import Template from '../../../viewComponents/template/template/template';
import { SideBarContentItemList } from '../../../viewComponents/template/sidebar/sidebar';
import { translate } from '../../../infrastructure/translations/translate';
import { IUserDashboard, IUserDashboardCard } from '../../../domain/interfaces/IUserDashboard';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../viewComponents/utils/Spinner/Spinner';
import { IModal } from '../../../domain/interfaces/IModal';
import { DashboardCardMaturityDomainComparison } from './Graphs/Comparison/DashboardCardMaturityDomainComparison';
import { DashboardCardText } from './Graphs/DashboardCardText';
import {
	DashboardCardIndicatorsComparisonGraph,
	DashboardCardIndicatorsGroupedComparisonGraph
} from './Graphs/Comparison/DashboardCardIndicatorsComparisonGraph';
import { DashboardCardGlobalComparisonGraph } from './Graphs/Comparison/DashboardCardGlobalComparisonGraph';
import { DashboardCardDomainComparison } from './Graphs/Comparison/DashboardCardDomainComparison';
import { DashboardCardEquipmentComparisonGraph } from './Graphs/Comparison/DashboardCardEquipmentComparisonGraph';
import { DashboardShareButton } from '../DashboardShare';
import { useUserDashboardOwning } from '../UserDashboardOwning';
import { DashboardCardGlobalSimulationGraph } from './Graphs/Simulation/DashboardCardGlobalSimulationGraph';
import { DashboardCardDomainSimulation } from './Graphs/Simulation/DashboardCardDomainSimulation';
import {
	DashboardCardIndicatorsGroupedSimulationGraph,
	DashboardCardIndicatorsSimulationGraph
} from './Graphs/Simulation/DashboardCardIndicatorsSimulationGraph';
import { DashboardCardEquipmentSimulationGraph } from './Graphs/Simulation/DashboardCardEquipmentSimulationGraph';
import { DashboardCardACVResult } from './Graphs/ACV/DashboardCardACVResult';
import { useUsersDashboard } from '../../../viewComponents/hooks/useUsersDashboard';
import { useModal } from '../../../viewComponents/modal/useModal';
import { DashboardCardPerimetersEvolutionGraph } from './Graphs/Evolution/DashboardCardPerimetersEvolutionGraph';
import { DashboardCardDomainsEvolutionGraph } from './Graphs/Evolution/DashboardCardDomainsEvolutionGraph';
import { DashboardCardIndicatorsEvolutionGraph } from './Graphs/Evolution/DashboardCardIndicatorsEvolutionGraph';
import { DashboardCardMaturityDomainEvolutionGraph } from './Graphs/Evolution/DashboardCardMaturityDomainEvolutionGraph';
import { DashboardCardEquipmentEvolutionGraph } from './Graphs/Evolution/DashboardCardEquipmentEvolutionGraph';

export const DashboardCardType = {
	text: (card: IUserDashboardCard) => <DashboardCardText card={card} />,
	DashboardGlobalComparison: (card: IUserDashboardCard) => <DashboardCardGlobalComparisonGraph card={card} />,
	DashboardDomainComparison: (card: IUserDashboardCard) => <DashboardCardDomainComparison card={card} />,
	DashboardIndicatorsComparison: (card: IUserDashboardCard) => <DashboardCardIndicatorsComparisonGraph card={card} />,
	DashboardIndicatorsGroupedComparison: (card: IUserDashboardCard) => <DashboardCardIndicatorsGroupedComparisonGraph card={card} />,
	DashboardMaturityDomainComparison: (card: IUserDashboardCard) => <DashboardCardMaturityDomainComparison card={card} />,
	DashboardEquipmentComparison: (card: IUserDashboardCard) => <DashboardCardEquipmentComparisonGraph card={card} />,
	DashboardGlobalSimulation: (card: IUserDashboardCard) => <DashboardCardGlobalSimulationGraph card={card} />,
	DashboardDomainSimulation: (card: IUserDashboardCard) => <DashboardCardDomainSimulation card={card} />,
	DashboardIndicatorsSimulation: (card: IUserDashboardCard) => <DashboardCardIndicatorsSimulationGraph card={card} />,
	DashboardIndicatorsGroupedSimulation: (card: IUserDashboardCard) => <DashboardCardIndicatorsGroupedSimulationGraph card={card} />,
	DashboardEquipmentSimulation: (card: IUserDashboardCard) => <DashboardCardEquipmentSimulationGraph card={card} />,
	DashboardPerimetersEvolution: (card: IUserDashboardCard) => <DashboardCardPerimetersEvolutionGraph card={card} />,
	DashboardDomainsEvolution: (card: IUserDashboardCard) => <DashboardCardDomainsEvolutionGraph card={card} />,
	DashboardIndicatorsEvolution: (card: IUserDashboardCard) => <DashboardCardIndicatorsEvolutionGraph card={card} />,
	DashboardMaturityDomainEvolution: (card: IUserDashboardCard) => <DashboardCardMaturityDomainEvolutionGraph card={card} />,
	DashboardEquipmentEvolution: (card: IUserDashboardCard) => <DashboardCardEquipmentEvolutionGraph card={card} />,
	ACVResult: (card: IUserDashboardCard) => <DashboardCardACVResult card={card} />,
};

const createModalEditDashboard = (
	open: boolean,
	setOpen: (open: boolean) => void,
	dashboard: IUserDashboard | undefined,
	deleteDashboard = false,
	setDeleteDashboard: (deleteModal: boolean) => void,
): IModal | undefined => {
	const { entity: userDashboardEntity } = useUsersDashboard();
	const navigate = useNavigate();
	const [name, setName] = React.useState<string>();
	const [description, setDescription] = React.useState<string>();

	if (!open || !dashboard) return;

	const clear = () => {
		setName(undefined);
		setDescription(undefined);
		setDeleteDashboard(false);
		useModal(false, { body: undefined, header: undefined, visible: false });
		setOpen(false);
	};

	const sendDashboard = () => {
		if (!dashboard) return;
		userDashboardEntity?.mutateEditUserDashboard({
			id: dashboard.id,
			name: name ?? dashboard.name,
			description: description ?? dashboard.description,
			delete: deleteDashboard,
		}).then(() => {
			if (deleteDashboard) navigate('/dashboard/overview');
			clear();
		}).catch((err) => {
			if (!err) {
				clear();
				return;
			}
		});
	};

	const body = (<div className={'modal_edit_dashboard'}>
		<div className="dashboard_modal_table_creation_form_input">
			<label htmlFor="name">{translate('name')} ({translate('required')})</label>
			<input type="text" maxLength={40} id="name" value={name ?? dashboard.name} onChange={e => setName(e.target.value)} />
		</div>
		<div className="dashboard_modal_table_creation_form_input">
			<label htmlFor="description">{translate('description')} ({translate('required')})</label>
			<input type="text" id="description" maxLength={100} value={description ?? dashboard.description} onChange={e => setDescription(e.target.value)} />
		</div>
	</div>);

	const bodyDelete = (<div className={'modal_edit_dashboard'}></div>);

	return {
		body: deleteDashboard ? bodyDelete : body,
		header: deleteDashboard ? translate('dashboard.overview.table.deleteModal.header') : translate('dashboard.overview.table.editModal.header'),
		visible: true,
		footer: deleteDashboard ? (<div className={'modal_edit_dashboard_footer'}>
			<button
				type={'button'}
				className={'button_danger'}
				onClick={sendDashboard}
			>
				{translate('delete')}
			</button>
		</div>) : (<div className={'modal_edit_dashboard_footer'}>
			<button
				type={'button'}
				onClick={() => clear()}
			>
				{translate('cancel')}
			</button>
			<button
				type={'button'}
				className={'button_primary'}
				onClick={sendDashboard}
			>
				{translate('save')}
			</button>
		</div>),
		onClose: () => clear(),
	};
};

export const DashboardTable = (): JSX.Element => {
	const { dashboardId } = useParams<{ dashboardId: string }>();
	const { entity: userDashboardEntity } = useUsersDashboard();
	const dashboard = userDashboardEntity?.data?.find(d => d.id === dashboardId);
	const [openModalEdit, setOpenModalEdit] = React.useState<boolean>(false);
	const owning = useUserDashboardOwning(dashboard?.id ?? '');
	const [deleteDashboard, setDeleteDashboard] = React.useState<boolean>(false);

	useModal(openModalEdit, createModalEditDashboard(openModalEdit, setOpenModalEdit, dashboard, deleteDashboard, setDeleteDashboard));

	useEffect(() => {
		if (dashboardId) userDashboardEntity?.queryUserDashboard({ id: dashboardId });
	}, []);

	const addTextCard = () => {
		if (!dashboard) return;
		const data = JSON.stringify({ text: 'Text can be formatted using the Markdown language. Here are some tips :\n\nTitre Grand :\n# Title 1\nTitre Moyen :\n## Title 2\nTitre Petit :\n### Title 3\n\nitalic : *italic*\nbold : **bold**\nFor more informations, consult the Markdown documentation' });
		userDashboardEntity?.mutateEditUserDashboardCard({
			id: 'new',
			name: '',
			type: 'text',
			dashboardId: dashboard.id,
			data,
			order: 0,
			delete: false,
		}).catch((err) => {
			if (!err) return;
		});
	};

	const side: SideBarContentItemList = {
		content: {
			list: [
				{
					id: 'overview',
					name: translate('dashboard.overview.title') as string,
					current: false,
					link: '/dashboard/overview',
				}
			]
		}
	};
	if (dashboard) {
		side.content.list.push({
			id: dashboard.id,
			name: dashboard.name,
			current: true,
			link: `/dashboard/overview/${dashboard.id}`,
		});
	}
	if (!dashboard) return (<Template core={<section><Spinner /></section>} side={side} />);
	const core = (<section className={'dashboard_overview_table'}>
		<div className="titles">
			<p className={'description'}>{dashboard.description}</p>
			{owning && <div className={'buttons'}>
				<button
					type={'button'}
					className={'button_blank'}
					onClick={addTextCard}
				>
					<div className="dashboard_icon"><i className="fa-solid fa-layer-plus" /></div>
					<div className="text">{translate('dashboard.overview.table.addCard.text')}</div>
				</button>
				<button
					type={'button'}
					className={'button_blank'}
					onClick={() => setOpenModalEdit(true)}
				>
					<div className="dashboard_icon"><i className="fa-solid fa-pen" /></div>
					<div className="text">{translate('dashboard.overview.table.edit')}</div>
				</button>
				<button
					type={'button'}
					className={'button_blank'}
					onClick={() => {
						setDeleteDashboard(true);
						setOpenModalEdit(true);
					}}
				>
					<div className="dashboard_icon"><i className="fa-regular fa-trash" /></div>
					<div className="text">{translate('delete')}</div>
				</button>
				<DashboardShareButton userDashboard={dashboard} sharedUsers={dashboard.sharedUsers}/>
			</div>}
		</div>
		<div className="cards">
			{[...dashboard.cards]
				.sort((a, b) => a.order - b.order)
				.map((card) => <Fragment key={card.id}>
					{DashboardCardType[card.type as keyof typeof DashboardCardType]?.(card)}
				</Fragment>)
			}
		</div>
	</section>);
	return (<Template core={core} side={side} />);
};
