import { gql } from '@apollo/client';

export const MUTATE_LAMBDA_USER_CREATION = gql`
	mutation mutateLambdaUserCreation(
		$email: String!,
		$lines: [String]!
	) {
		mutateLambdaUserCreation (email: $email, linesId: $lines) {
			equipments {
                id,
                name,
                help,
                unit,
                priority,
                allocationFactor,
                quantity,
                quality,
                weightedQuantity,
                lifetime,
                internalLifetime,
                reusePart,
                reuseLifetime,
				customElectricityConsumption,
                comment,
                assigned,
                assignedCount,
                forbidden,
                equipmentTypeId,
                category,
                cmdb,
                isRelatedToResults,
				deprecated,
			}
			user {
				id
				email
			}
		}
	}
`;
