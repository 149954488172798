import { IUserDashboardCard } from '../../../../domain/interfaces/IUserDashboard';
import React from 'react';
import { useTooltip } from '../../../../viewComponents/tip/useTooltip';
import { translate } from '../../../../infrastructure/translations/translate';
import ReactMarkdown from 'react-markdown';
import { DashboardCardDeleteButton } from '../DashboardCardDeleteButton';
import { DashboardCardMoveButton } from '../DashboardCardMoveButton';
import { useUsersDashboard } from '../../../../viewComponents/hooks/useUsersDashboard';

export const DashboardCardText = ({ card }: { card: IUserDashboardCard }) => {
	const { entity: userDashboardEntity } = useUsersDashboard();
	const { text } = JSON.parse(card.data) as { text: string };
	const [textInput, setTextInput] = React.useState<string>(text);
	const [edit, setEdit] = React.useState<boolean>(false);
	const [tooltip, setTooltip] = React.useState<boolean>(false);

	useTooltip(tooltip, translate('dashboard.overview.table.card.text.markdownExplanation'));

	const save = () => {
		userDashboardEntity?.mutateEditUserDashboardCard({
			id: card.id,
			name: card.name,
			type: card.type,
			dashboardId: card.dashboard.id,
			data: JSON.stringify({ text: textInput }),
			delete: false,
		}).then(() => {
			setEdit(false);
		}).catch((err) => {
			if (!err) return;
		});
	};
	if (edit) return (<div className={'dashboard_card dashboard_card_color_grey dashboard_card_text'}>
		<div className="explanation">
			<button
				className={'button_blank'}
				type={'button'}
				onMouseEnter={() => setTooltip(true)}
				onMouseLeave={() => setTooltip(false)}
			>
				<i className="fa-regular fa-circle-exclamation"></i>
			</button>
		</div>
		<textarea
			defaultValue={textInput}
			onChange={(e) => setTextInput(e.target.value)}
			rows={5}
		/>
		<button
			type={'button'}
			className={'button_primary'}
			onClick={save}
		>
			{translate('save')}
		</button>
	</div>);
	return (<div className={'dashboard_card dashboard_card_color_grey dashboard_card_text'}>
		<div className={'buttons'}>
			<button
				type={'button'}
				className={'button_blank'}
				onClick={() => setEdit(true)}
			>
				<div className="dashboard_icon"><i className="fa-solid fa-pen"/></div>
				<div className="text">{translate('edit')}</div>
			</button>
			<DashboardCardMoveButton card={card} />
			<DashboardCardDeleteButton card={card} />
		</div>
		<p>
			<ReactMarkdown>
				{text}
			</ReactMarkdown>
		</p>
	</div>);
};