import { gql } from '@apollo/client';

export const MUTATE_SAMPLE_UNIFY_DATASETS_PROCEED = gql`
    mutation MutateSampleUnifyDatasetsProceed(
        $sampleId: String!,
    ) {
        mutateSampleUnifyDatasetsProceed(
            sampleId: $sampleId
        ) {
            elements {
                id
                message
                link
                change
            }
        }
    }
`;
