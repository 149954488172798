import { ApiErrorJson, ApiErrorVariable, ApiErrorVariableStyle } from '../domain/interfaces/ApiErrorJson';
import { v4 as uuid } from 'uuid';

const isValidPath = (path: string): boolean => {
	return path.startsWith('/') && path.length > 1;
};

const validateVariable = (variable: unknown): ApiErrorVariable => {
	if (!variable || typeof variable !== 'object') {
		throw new Error('Variable must be an object');
	}

	const v = variable as Partial<ApiErrorVariable>;

	if (!v.key || typeof v.key !== 'string') {
		throw new Error('Variable must have a \'key\' property of type string');
	}
	if (!v.value || typeof v.value !== 'string') {
		throw new Error('Variable must have a \'value\' property of type string');
	}

	const validVariable: ApiErrorVariable = {
		key: v.key,
		value: v.value
	};

	if (v.style !== undefined) {
		const validStyles: ApiErrorVariableStyle[] = ['bold', 'underline', 'italic', 'link'];
		if (!validStyles.includes(v.style as ApiErrorVariableStyle)) {
			throw new Error(`Invalid style '${v.style}'. Valid styles are: ${validStyles.join(', ')}`);
		}
		validVariable.style = v.style as ApiErrorVariableStyle;

		if (v.style === 'link') {
			if (!v.href) {
				throw new Error('Style \'link\' requires an \'href\' property');
			}
			if (!isValidPath(v.href)) {
				throw new Error(`Invalid href '${v.href}'. Path must start with '/'`);
			}
			validVariable.href = v.href;
		}
	}

	return validVariable;
};

/**
 * Validates an API error JSON structure
 *
 * @param json - The JSON object to validate
 * @throws Error if validation fails
 * @returns The validated ApiError object
 */
export function parseApiError(json: string): ApiErrorJson {
	if (!json || typeof json !== 'string') {
		throw new Error('Invalid JSON: must be a JSON string');
	}

	const obj = JSON.parse(json);
	const error = obj as Partial<ApiErrorJson>;

	if (!error.key || typeof error.key !== 'string') {
		throw new Error('Missing or invalid \'key\' property');
	}

	const validError: ApiErrorJson = {
		id: error.id ?? uuid(),
		key: error.key,
		displayType: error.displayType ?? 'notification',
		type: error.type ?? 'error',
		variables: []
	};

	if (error.href !== undefined) {
		if (typeof error.href !== 'string') {
			throw new Error('Property \'href\' must be a string');
		}
		if (!isValidPath(error.href)) {
			throw new Error('Property \'href\' must start with \'/\'');
		}
		validError.href = error.href;
	}

	if (validError.displayType === 'component' && validError.href) {
		delete validError.href;
	}

	const variables = error.variables ?? [];
	if (!Array.isArray(variables)) {
		throw new Error('Property \'variables\' must be an array');
	}

	validError.variables = variables.map(validateVariable);

	return validError;
}