import React, { useRef, useState } from 'react';
import { useResultLaunchers } from '../../hooks/useResultLaunchers';
import { closeGlobalModal } from '../GlobalModal';
import { translate } from '../../../infrastructure/translations/translate';
import ButtonLoading from '../../button/ButtonLoading/ButtonLoading';

type Step = 'definitive' | 'notDefinitive' | 'loadingDelete';

export const createResultDefinitiveModal = (
	open: boolean,
	setOpen: (open: boolean) => void,
	rlId: string,
) => {
	const { entity: rlEntity, resultLaunchers } = useResultLaunchers();

	const currentRl = resultLaunchers.find(rl => rl.id === rlId);
	const multipleRl = resultLaunchers.length > 1;

	const [loading, setLoading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const step: Step = isDeleting
		? 'loadingDelete'
		: currentRl?.definitive
			? 'notDefinitive'
			: 'definitive';
	const blockCloseRef = useRef(step === 'loadingDelete');

	if (!open || !currentRl || !step) return;

	const onClose = () => {
		setOpen(false);
		setIsDeleting(false);
		closeGlobalModal();
	};

	const handleMutateResultLauncher = (deleteAll: boolean) => {
		if (deleteAll && currentRl.definitive) return;
		setLoading(true);
		rlEntity?.mutateManageResultLauncher({ id: rlId, definitive: !currentRl.definitive, deleteAll: deleteAll }).finally(() => {
			setLoading(false);
			onClose();
		});
	};

	const changes = {
		break: () => <br/>,
		return: () => <br/>,
		bold: (text: string) => <b >{text}</b>,
		red: (text: string) => <span className={'red'}>{text}</span>,
	};

	const bodySetDefinitive = <>{translate('results.notification.setDefinitive',changes)}</>;
	const bodySetNotDefinitive = <>{translate('results.notification.setToNotDefinitive',changes)}</>;
	const bodyLoadingDelete = <>{translate('results.notification.loadDeleteResults',changes)}</>;

	const body = {
		definitive: bodySetDefinitive,
		notDefinitive: bodySetNotDefinitive,
		loadingDelete: bodyLoadingDelete,
	}[step];

	const footer = <div className='modal_footer'>
		<button onClick={onClose} className='btn btn-secondary'>{translate('cancel')}</button>
		<ButtonLoading
			title={currentRl.definitive ? 'results.setToNotDefinitive' : 'results.setToDefinitive'}
			classname={'button_primary validate_modal_button'}
			loading={loading}
			onClick={() => handleMutateResultLauncher(false)}
		/>
		{!currentRl.definitive && multipleRl && <ButtonLoading
			title={'results.deleteAll'}
			classname={'button_danger'}
			loading={loading}
			onClick={() => {
				setIsDeleting(true);
				handleMutateResultLauncher(true);
			}}
		/>}
	</div>;

	return {
		visible: true,
		body: body,
		header: translate('results.setDefinitive'),
		load: loading,
		onClose: onClose,
		footer: step === 'loadingDelete' ? <></> : footer,
		blockClose: blockCloseRef,
	};
};
